<template>
  <span :class="'mc-icon' + (sized ? ' sized' : '')" v-bind="$attrs">
    <font-awesome-layers v-if="icon === 'signal-sleep'" fixed-width>
      <font-awesome-icon :icon="['fad', 'signal-alt']" style="opacity: 0.5" />
      <font-awesome-icon :icon="['fad', 'snooze']" transform="shrink-6 up-7 left-4" />
    </font-awesome-layers>

    <font-awesome-layers v-else-if="icon === 'folder-font'" fixed-width>
      <font-awesome-icon :icon="['fad', 'folder']" fixed-width transform="grow-4" />
      <font-awesome-icon :icon="['fad', 'font']" fixed-width transform="shrink-5 down-2" />
    </font-awesome-layers>

    <font-awesome-layers v-else-if="icon === 'font-weight'" fixed-width>
      <font-awesome-icon :icon="['fat', 'bold']" fixed-width transform="shrink-3 left-6" />
      <font-awesome-icon :icon="['far', 'bold']" fixed-width transform="shrink-3 right-6" />
    </font-awesome-layers>

    <font-awesome-layers v-else-if="icon === 'font-weight-bold'" fixed-width>
      <font-awesome-icon :icon="['far', 'bold']" fixed-width transform="shrink-3 left-6" style="opacity: 0.7;" />
      <font-awesome-icon :icon="['fas', 'bold']" fixed-width transform="shrink-3 right-6" />
    </font-awesome-layers>

    <font-awesome-layers v-else-if="icon === 'clone'" fixed-width>
      <font-awesome-icon :icon="['fas', 'sheep']" transform="shrink-3 up-3 left-3" style="opacity: 0.5" />
      <font-awesome-icon :icon="['fas', 'sheep']" transform="shrink-3 down-3 right-1" />
    </font-awesome-layers>

    <font-awesome-layers v-else-if="icon === 'signal-unknown'" fixed-width>
      <font-awesome-icon :icon="['fad', 'signal-alt']" style="opacity: 0.5" />
      <font-awesome-icon icon="question" transform="shrink-6 up-7 left-7" />
    </font-awesome-layers>

    <font-awesome-layers v-else-if="icon === 'battery-unknown'" fixed-width>
      <font-awesome-icon :icon="['fad', 'battery-empty']" transform="down-6" />
      <font-awesome-icon icon="question" transform="shrink-6 up-7" />
    </font-awesome-layers>

    <font-awesome-icon v-else :icon="icon" fixed-width />
  </span>
</template>

<script>
export default {
  props: {
    icon: [String, Array],
    sized: Boolean,
  },
}
</script>

<style scoped>
.mc-icon.sized {
  padding: 0.25rem 0.4rem;
  width: 2.2rem;
  text-align: center;
  margin: 0 0.25rem 0 0;
  border-radius: 0.2rem;
}
</style>
