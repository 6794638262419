<template>
  <McLayoutDefault :heading="$t('headings.GiftCards')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.hasMerchantsWithPermission('manageCertificates')">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col md="4" cols="12">
        <b-card class="mb-2">
          <p>{{ $t('giftcards.PageDescription') }}</p>
          <MerchantSelector @merchantSelected="m => (merchant = m)" permission="manageCertificates" v-model="merchantId" />
          <div v-if="merchantDomains && merchantDomains.length > 0">
            <p>
              {{ $tc('general.RememberToCheckWebshop', merchantDomains.length) }}
              <br />
              <small>
                {{ $tc('general.LinkWillOpenInNewTab', merchantDomains.length) }}
              </small>
            </p>
            <ul>
              <li v-for="(domain, key) in merchantDomains" v-bind:key="key">
                <a :href="'https://' + domain" target="_blank">{{ domain }}</a>
              </li>
            </ul>
          </div>
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <!-- pre>{{ cards }}</pre -->
        <GiftCardList data-parent-card-id="0" :cards="cards" :merchant="merchant" @edit-design="editDesign" @edit-giftcard="editGiftcard" @refresh-cards="refreshCards" @sort-end="cardsSorted" :merchantId="merchantId" />

        <div class="text-center my-3">
          <!-- eslint-disable-next-line -->
          <McButton
            @click="editGiftcard({ type: 'giftcard' })"
            variant="primary"
            icon="plus"
            :text="$t('giftcards.CreateNewGiftcard')"
            class="mr-2"
          />

          <!-- eslint-disable-next-line -->
          <McButton
            @click="editGiftcard({ type: 'category' })"
            variant="outline-primary"
            icon="plus"
            :text="$t('giftcards.CreateNewCategory')"
          />
        </div>
      </b-col>
    </b-row>
    <GiftcardForm id="giftcardform" :merchantId="merchantId" isModal size="lg" :value="editingGiftcard" :title="$t('giftcards.CreateNewGiftcard')" @completed="refreshCards()" />
    <fullscreen-editor ref="designEditor" :beforeClose="closeDesignerIfUnchanged">
      <Designer ref="designEditorInner" :merchantId="merchantId" :merchant="merchant" :allCards="cards" :giftcard="editingDesign" @saved="refreshCards" @cancel="$refs['designEditor'].hide()" />
    </fullscreen-editor>
  </McLayoutDefault>
</template>

<script>
import { mapActions } from 'vuex'
import GiftcardService from '@/services/GiftcardService'
import MerchantService from '@/services/MerchantService'

export default {
  data() {
    return {
      cards: [],
      merchantId: null,
      merchant: null,
      editingGiftcard: {},
      editingDesign: {},
      merchantDomains: [],
    }
  },
  watch: {
    merchantId() {
      this.refreshCards()
      MerchantService.getDomains(this.merchantId).then(data => {
        this.merchantDomains = data
      })
    },
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    cardsSorted(e) {
      let card = this.getCardFromElement(e.item)
      let oldParent = this.getCardFromElement(e.from)
      let newParent = this.getCardFromElement(e.to)
      if (oldParent.id != newParent.id) {
        GiftcardService.setParent(this.merchantId, { id: card.id }, newParent.id)
        //console.log(card.id, oldParent.id + ' > ' + newParent.id)
      }

      let newOrder = []
      for (let i in newParent.children) {
        newOrder.push(newParent.children[i].id)
      }
      if (newOrder.length > 1) {
        GiftcardService.orderChildren(this.merchantId, { id: newParent.id }, newOrder)
        //console.log('new order: ', newOrder)
      }
    },
    getCardFromElement(el) {
      while (el) {
        if (el._underlying_vm_) {
          return el._underlying_vm_
        } else if (el.__vue__ && el.__vue__.card) {
          return el.__vue__.card
        } else {
          el = el.parentNode
        }
      }
      return {
        id: 0,
        children: this.cards,
      }
    },
    editGiftcard(giftcard) {
      this.editingGiftcard = { ...giftcard }
      this.$bvModal.show('giftcardform')
    },
    editDesign(giftcard) {
      this.editingDesign = giftcard
      this.$refs['designEditor'].show().then(() => {
        this.$refs['designEditorInner'].setDesign(giftcard.design)
      })
    },
    closeDesignerIfUnchanged() {
      this.$refs['designEditorInner'].closeIfUnchanged()
    },
    refreshCards() {
      GiftcardService.get(this.merchantId).then(
        data => {
          this.cards = data
        },
        error => {
          console.error('Error getting gift cards', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
