<template>
  <McLayoutDefault :heading="$t('headings.DeliveryMethods')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.hasMerchantsWithPermission('manageCertificates')">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col md="4" cols="12">
        <b-card class="mb-2">
          <p>{{ $t('deliveryMethods.PageDescription') }}</p>
          <MerchantSelector @merchantSelected="m => (merchant = m)" permission="manageCertificates" v-model="merchantId" />
          <div v-if="merchantDomains && merchantDomains.length > 0">
            <p>
              {{ $tc('general.RememberToCheckWebshop', merchantDomains.length) }}
              <br />
              <small>
                {{ $tc('general.LinkWillOpenInNewTab', merchantDomains.length) }}
              </small>
            </p>
            <ul>
              <li v-for="(domain, key) in merchantDomains" v-bind:key="key">
                <a :href="'https://' + domain" target="_blank">{{ domain }}</a>
              </li>
            </ul>
          </div>
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <!--pre>{{ deliveryMethods }}</pre-->
        <draggable class="delivery-methods-list-drag-area" tag="div" :list="deliveryMethods" swapThreshold=".65" fallbackOnBody animation="100" @end="deliveryMethodsSorted" :group="{ name: 'g1' }">
          <!-- eslint-disable-next-line -->
          <DeliveryMethodListItem
            v-for="deliveryMethod in deliveryMethods"
            v-bind:key="deliveryMethod.id"
            :deliveryMethod="deliveryMethod"
            @edit-delivery-method="editDeliveryMethod"
            @refresh-delivery-methods="refreshDeliveryMethods"
            @sort-end="e => $emit('sort-end', e)"
            :merchantId="merchantId"
            :merchant="merchant"
          />
        </draggable>

        <div class="text-center my-3">
          <!-- eslint-disable-next-line -->
          <McButton
            @click="editDeliveryMethod({})"
            variant="primary"
            icon="plus"
            :text="$t('deliveryMethods.CreateNewDeliveryMethod')"
          />
        </div>
      </b-col>
    </b-row>
    <DeliveryMethodForm
      id="deliverymethodform"
      :merchantId="merchantId"
      isModal
      size="lg"
      :value="editingDeliveryMethod"
      :title="$t('deliveryMethods.CreateNewDeliveryMethod')"
      @completed="refreshDeliveryMethods()"
    />
  </McLayoutDefault>
</template>

<script>
import { mapActions } from 'vuex'
import DeliveryMethodsService from '@/services/DeliveryMethodsService'
import MerchantService from '@/services/MerchantService'

export default {
  data() {
    return {
      deliveryMethods: [],
      merchantId: null,
      merchant: null,
      editingDeliveryMethod: {},
      merchantDomains: [],
    }
  },
  watch: {
    merchantId() {
      this.refreshDeliveryMethods()
      MerchantService.getDomains(this.merchantId).then(data => {
        this.merchantDomains = data
      })
    },
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    deliveryMethodsSorted(e) {
      let newOrder = []
      for (let i in this.deliveryMethods) {
        newOrder.push(this.deliveryMethods[i].id)
      }
      if (newOrder.length > 1) {
        DeliveryMethodsService.order(this.merchantId, newOrder)
        //console.log('new order: ', newOrder)
      }
    },
    editDeliveryMethod(deliveryMethod) {
      this.editingDeliveryMethod = { ...deliveryMethod }
      this.$bvModal.show('deliverymethodform')
    },
    refreshDeliveryMethods() {
      DeliveryMethodsService.get(this.merchantId).then(
        data => {
          this.deliveryMethods = data
        },
        error => {
          console.error('Error getting deliveryMethod', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
