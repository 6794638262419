import Vue from 'vue'
import Router from 'vue-router'

//No idea why this is needed, but everything breaks if removed :/
// eslint-disable-next-line
import uselessimport from '@/services/AuthenticationService'

import LoginStore from '@/store/LoginStore'

//Public pages
import LoginPage from '@/views/public/LoginPage'
import CreateAccountPage from '@/views/public/CreateAccountPage'
import ResetPasswordPage from '@/views/public/ResetPasswordPage'
import ConfirmEmailPage from '@/views/public/ConfirmEmailPage'
import QrHandler from '@/views/public/QrHandler'
import PdfHandler from '@/views/public/PdfHandler'

//Protected pages
import Home from '@/views/protected/Home'
import Users from '@/views/protected/Users'
import Profile from '@/views/protected/Profile'
import Orders from '@/views/protected/Orders'
import Reports from '@/views/protected/Reports'
import Billing from '@/views/protected/Billing'
import GiftCards from '@/views/protected/GiftCards'
import DeliveryMethods from '@/views/protected/DeliveryMethods'
import Merchants from '@/views/protected/Merchants'
import Integration from '@/views/protected/Integration'
import OfflineGiftcards from '@/views/protected/OfflineGiftcards'

//Test pages
import TestQr from '@/views/testpages/TestQr'
import TestToast from '@/views/testpages/TestToast'
import TestPermissions from '@/views/testpages/TestPermissions'
import TestUploads from '@/views/testpages/TestUploads'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    //Public routes
    { path: '/login', component: LoginPage },
    { path: '/pdf', component: PdfHandler },
    { path: '/qr/:token', component: QrHandler },
    { path: '/create-account', component: CreateAccountPage },
    { path: '/confirm-:action(change|create)/:token/:email', component: ConfirmEmailPage },
    { path: '/reset-password', component: ResetPasswordPage },
    { path: '/:action(reset|choose)-password/:token', component: ResetPasswordPage },

    //Protected routes
    { path: '/', component: Home },
    { path: '/users', component: Users },
    { path: '/profile', component: Profile },
    { path: '/merchants', component: Merchants },
    { path: '/orders', component: Orders },
    { path: '/reports', component: Reports },
    { path: '/billing', component: Billing },
    { path: '/gift-cards', component: GiftCards },
    { path: '/delivery-methods', component: DeliveryMethods },
    { path: '/integration', component: Integration },
    { path: '/offline-giftcards', component: OfflineGiftcards },

    //Test-only routes
    { path: '/test/toast', component: TestToast },
    { path: '/test/permissions', component: TestPermissions },
    { path: '/test/uploads', component: TestUploads },
    { path: '/test/qr', component: TestQr },

    // otherwise redirect to home
    { path: '*', redirect: '/' },
  ],
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/loginqr', '/login', '/reset-password', '/create-account']
  let authRequired = !publicPages.includes(to.path)
  if (to.path.match(/^\/qr\/.{2,}/)) {
    authRequired = false
  }
  if (to.path.match(/^\/(choose|reset)-password\/.{2,}/)) {
    authRequired = false
  }
  if (to.path.match(/^\/confirm-(create|change)\/.{2,}/)) {
    authRequired = false
  }
  if (to.path.match(/^\/test\/.+/)) {
    authRequired = false
  }
  const loggedIn = LoginStore.state.user ? true : false

  if (authRequired && !loggedIn) {
    const loginpath = to.path.replace(new RegExp('^/+'), '')
    return next({
      path: '/login',
      query: loginpath
        ? {
            from: loginpath,
          }
        : null,
    })
  }

  next()
})

export default router
