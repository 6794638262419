<template>
  <div v-if="card" class="card my-2 selected-giftcard-in-create-order-form">
    <div class="card-body py-0 px-2">
      <div class="my-2 clearfix">
        <div class="item-cols">
          <div class="item-col item-preview" v-if="card.image.url">
            <div class="item-image" :style="card.image.url ? 'background-image: url(' + card.image.url + '/240x180)' : ''"></div>
          </div>
          <div class="item-col item-preview px-2 limitheight">
            <p style="margin-bottom: 0">
              <b-badge v-if="card.priceInCents > 0" class="float-right ml-1 mt-1 mb-1 mr-0">{{ displayPrice }}</b-badge>
              <b-badge v-if="card.priceInCents == 0" class="float-right ml-1 mt-1 mb-1 mr-0">{{ displayFreeAmount }}</b-badge>
              <strong v-html="card.webshopName.replaceAll('\n', '<br>')"></strong>
            </p>
            <div class="item-description" v-if="card.description" v-html="card.description"></div>
          </div>
        </div>
        <div class="item-terms p-2 my-2" v-html="card.terms"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { niceCurrencyMaybeWithoutCents } from '@/helpers/Formatting'

export default {
  computed: {
    displayPrice() {
      return niceCurrencyMaybeWithoutCents(this.card.priceInCents)
    },
    displayFreeAmount() {
      return this.$t('giftcards.FreeAmountColon', {
        range: niceCurrencyMaybeWithoutCents(this.card.minFreeAmountInCents) + ' - ' + niceCurrencyMaybeWithoutCents(this.card.maxFreeAmountInCents)
      })
    },
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.selected-giftcard-in-create-order-form {
  .item-image {
    width: 128px;
    height: 96px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    vertical-align: text-top;
  }
  .item-cols {
    width: 100%;
    display: flex;
    .item-col:last-child {
      flex-grow: 1;
    }
  }
  .item-description {
    font-size: 0.8em;
    margin-top: 1em;
  }
  .limitheight {
    max-height: 100px;
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      content: '';
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
      height: 40px;
    }
  }
  .item-terms {
    background: #eee;
    font-size: 0.8em;
  }
}
</style>