<template>
  <!--pre>Image: {{ element }}</pre-->
  <div>
    <!-- eslint-disable-next-line -->
    <McFormImage
      name="file"
      :label="$t('general.Image')"
      :placeholder="$t('general.PickImage')"
      icon="image"
      :value="element.image"
      @input="val => $emit('change-prop', ['image', val])"
      :description="$t('general.PickImage')"
    />
    <mc-form-checkboxes :label="$t('designer.Sizing')" :description="$t('designer.SizingDescription')" v-model="element.sizing" :icon="sizingIcon" :options="sizingOptions" />
    <mc-form-checkbox :label="$t('designer.Background')" v-model="element.useBackground">{{ $t('designer.BackgroundColor') }}</mc-form-checkbox>
    <mc-form-color-input v-if="element.useBackground" :description="$t('designer.ChooseColorAndOpacity')" v-model="element.backcolor" format="hex" opacity />
    <McFormInput v-if="element.useBackground" type="number" size="sm" unit="mm" :min="0" :max="100" :step="1 / pixelsPerMm" :label="$t('designer.Padding')" v-model="element.padding" />
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    pixelsPerMm: Number,
  },
  computed: {
    sizingIcon() {
      if (this.element.sizing === 'contain') {
        return ['far', 'compress-arrows-alt']
      } else if (this.element.sizing === 'cover') {
        return ['far', 'crop-alt']
      }
      return ['far', 'expand-arrows-alt']
    },
    sizingOptions() {
      return [
        { label: this.$t('designer.sizingOptions.Contain'), value: 'contain' },
        { label: this.$t('designer.sizingOptions.Stretch'), value: 'stretch' },
        { label: this.$t('designer.sizingOptions.Cover'), value: 'cover' },
      ]
    },
  },
  /*methods: {
    changeProp(prop, newValue) {
      let newElement = { ...this.element }
      newElement[prop] = newValue
      this.$emit('change', newElement)
    },
  },*/
}
</script>
