<template>
  <div>
    <McTable class="merchants-table" ref="merchantsTable" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :provider="dataProvider">
      <McButton @click="e => editMerchant({})" variant="primary" icon="plus" :text="$t('merchants.CreateNewMerchant')" />
      <template v-slot:cell(niceName)="data">
        <div class="text-center">
          <img v-if="data.item.logoDark.url" style="max-height: 50px; max-width: 200px; margin: 0 auto; display: block" :src="data.item.logoDark.url" />
          <p v-else>
            <strong>{{ data.item.niceName }}</strong>
          </p>
          <p>
            <small>{{ $t('general.MerchantIdColon', data.item) }}</small>
          </p>
        </div>
      </template>
      <template v-slot:cell(userName)="data">
        {{ data.item.userName }}<br />
        <small>({{ data.item.userEmail }})</small>
      </template>
      <template v-slot:cell(fullAddress)="data">
        <McAddress :address="data.item" />
      </template>
      <template v-slot:cell(contactInfo)="data">
        <!--pre>{{ data.item }}</pre-->
        <label class="in_table_list_label">{{ $t('merchants.ForDG') }}</label>
        <ul class="in_table_flat_list">
          <li v-if="data.item.contactEmail">
            <a :href="'mailto:' + data.item.contactEmail">{{ data.item.contactEmail }}</a>
          </li>
          <li v-if="data.item.contactPhone">
            <a :href="'tel:' + data.item.contactPhone">{{ data.item.contactPhone | nicePhone }}</a>
          </li>
        </ul>
        <label class="in_table_list_label">{{ $t('merchants.ForCustomers') }}</label>
        <ul class="in_table_flat_list">
          <li v-if="data.item.email">
            <a :href="'mailto:' + data.item.email">{{ data.item.email }}</a>
          </li>
          <li v-if="data.item.phone">
            <a :href="'tel:' + data.item.phone">{{ data.item.phone | nicePhone }}</a>
          </li>
          <li v-if="data.item.website">
            <a :href="data.item.website" target="_blank">{{ data.item.website }}</a>
          </li>
        </ul>
      </template>
      <template v-slot:cell(actions)="data">
        <b-button-group>
          <McButton @click="e => editMerchant(data.item)" variant="primary" size="sm" icon="edit" />
          <McButton v-if="data.item.orderCount === 0" icon="trash" variant="danger" size="sm" @click="deleteMerchant(data.item.id, data.item.niceName)" />
        </b-button-group>
      </template>
    </McTable>
    <MerchantForm id="merchantform" isModal :value="editingMerchant" :title="$t('merchants.CreateNewMerchant')" @completed="refreshTable()" />
  </div>
</template>

<script>
import MerchantService from '@/services/MerchantService'

export default {
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'niceName',
      dataProvider: MerchantService.query,
      editingMerchant: {},
    }
  },
  computed: {
    fields() {
      let retval = []
      retval.push({ key: 'niceName', priority: 0, mw: 200, sortable: true, label: this.$t('general.Name') })
      retval.push({ key: 'userName', priority: 0, mw: 200, sortable: true, label: this.$t('general.AdminUser') })
      retval.push({ key: 'fullAddress', mw: 250, sortable: false, label: this.$t('general.FullAddress') })
      retval.push({ key: 'contactInfo', priority: 2, mw: 150, sortable: false, label: this.$t('general.ContactInfo') })
      retval.push({ key: 'actions', priority: 0, mw: 120, label: this.$t('general.Actions'), class: 'text-right' })

      return retval
    },
  },
  methods: {
    editMerchant(item) {
      this.editingMerchant = { ...item }
      this.$bvModal.show('merchantform')
    },
    refreshTable() {
      this.$refs.merchantsTable.refresh()
    },
    deleteMerchant(id, name) {
      if (confirm(this.$t('merchants.ConfirmDelete', { name }))) {
        MerchantService.delete(id).then(data => {
          this.refreshTable()
        })
      }
    },
  },
  filters: {
    nicePhone(uglyPhone) {
      return uglyPhone.replace(/(\d\d?)(?=\d\d)/g, '$1 ')
    },
  },
}
</script>
