<template>
  <McLayoutDefault :heading="$t('headings.Profile')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="$perms.isQrUser()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col md="4" cols="12">
        <b-card :title="$t('profile.Description1', { name: LoginStore.user.name })" class="mb-2">
          <p>{{ $t('profile.Description2') }}</p>
          <i18n path="profile.Description3" tag="p">
            <template v-slot:link>
              <router-link to="/merchants">{{ $tc('headings.Merchants', 2) }}</router-link>
            </template>
          </i18n>
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <ProfileForm />
      </b-col>
    </b-row>
  </McLayoutDefault>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
  },
}
</script>
