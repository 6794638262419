<template>
  <!-- eslint-disable-next-line -->
  <McMoveable
    :position="element.position"
    @moved="newPosition => $emit('moved', newPosition)"
    v-bind="moveable"
    :container="container"
    :bounds="bounds"
    :horizontalGuidelines="horizontalGuidelines"
    :verticalGuidelines="verticalGuidelines"
    :pixelsPerMm="pixelsPerMm"
    @mousedown="e => $emit('mousedown', e)"
    ref="moveable"
  >
    <DesignerViewShape v-if="element.type === 'shape'" :element="element" :pixelsPerMm="pixelsPerMm" />
    <DesignerViewQr v-else-if="element.type === 'qr'" :element="element" :giftcard="giftcard" :merchant="merchant" :pixelsPerMm="pixelsPerMm" />
    <DesignerViewImage v-else-if="element.type === 'image'" :element="element" :pixelsPerMm="pixelsPerMm" />
    <DesignerViewTextarea v-else-if="['textarea', 'valexp'].includes(element.type)" :element="element" :giftcard="giftcard" :merchant="merchant" :pixelsPerMm="pixelsPerMm" />
    <pre v-else style="width: 100%; height: 100%; overflow: scroll">{{ element }}</pre>
  </McMoveable>
</template>

<script>
export default {
  props: {
    element: Object,
    giftcard: Object,
    merchant: Object,
    container: HTMLElement,
    bounds: Object,
    horizontalGuidelines: Array,
    verticalGuidelines: Array,
    zoom: [Number, String],
    pixelsPerMm: Number,
  },
  data: () => ({
    moveable: {
      draggable: true,
      throttleDrag: 0,

      resizable: true,
      throttleResize: 0,
      keepRatio: false,

      rotatable: true,
      throttleRotate: 1,
      pinchable: ['draggable', 'resizable', 'rotatable'],

      snappable: true,
      snapThreshold: 5,
      snapCenter: true,
      snapHorizontal: true,
      snapVertical: true,
      elementGuidelines: [],
      snapElement: true,
      snapGap: true,
      snapDigit: 0,
      snapDistFormat: v => v + 'mm',
      snapDirections: { left: true, top: true, right: true, bottom: true, center: true, middle: true },
      isDisplaySnapDigit: true,

      scalable: false,
      origin: false,

      zoom: 1,
    },
  }),
  methods: {
    setActive(act) {
      //this.moveable.draggable = act
      this.moveable.snappable = act
      this.moveable.rotatable = act
      this.moveable.resizable = act
      this.moveable.pinchable = act ? ['draggable', 'resizable', 'rotatable'] : false
      this.$refs['moveable'].updateSnapElements()
    },
    updateRect() {
      this.moveable.zoom = 1 / this.zoom
      setTimeout(() => {
        this.$refs['moveable'].updateRect()
      }, 1)
    },
  },
  mounted() {
    this.setActive(this.element.isActive)
    this.moveable.zoom = 1 / this.zoom
    this.moveable.snapDistFormat = v => Math.round(v) / this.pixelsPerMm + 'mm'
  },
  watch: {
    'element.isActive'(newActive) {
      this.setActive(newActive)
    },
    'element.position'() {
      //console.log('position changed: ' + this.element.position)
      this.updateRect()
    },
    zoom() {
      //console.log('zoom changed: ' + this.zoom)
      this.updateRect()
    },
  },
}
</script>
