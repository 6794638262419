<template>
  <div v-if="resendCompleted">
    <p>
      <strong>{{ $t('loginform.ResendSuccess1', resendCompleted) }}</strong>
    </p>
    <p>{{ $t('loginform.ResendSuccess2', resendCompleted) }}</p>
  </div>
  <div v-else-if="resendConfirmation">
    <McForm :onSubmit="handleResend" submiticon="paper-plane" key="f1" :submittext="$t('loginform.Resend')">
      <p>{{ $t('loginform.UnconfirmedEmail') }}</p>
    </McForm>
  </div>
  <div v-else>
    <McForm v-bind="$attrs" @reset="handleReset" :onSubmit="handleSubmit" key="f2" submiticon="unlock" :submittext="$t('general.LogIn')">
      <mc-form-input
        name="email"
        type="email"
        v-model="curData.email"
        v-validate="'required|email'"
        :placeholder="$t('general.EnterEmail')"
        key="loginform.email"
        :errorHint="errors.first('email')"
        :state="!errors.has('email')"
        icon="at"
      />

      <mc-form-input
        name="password"
        type="password"
        v-model="curData.password"
        v-validate="'required'"
        :placeholder="$t('general.EnterPassword')"
        key="loginform.password"
        :errorHint="errors.first('password')"
        :state="!errors.has('password')"
        icon="key"
      />

      <b-form-group>
        <b-input-group>
          <b-form-checkbox name="remember" v-model="curData.remember">
            {{ $t('loginform.Remember') }}
          </b-form-checkbox>
        </b-input-group>
      </b-form-group>

      <template v-slot:secondarybuttons>
        <b-button to="/reset-password" variant="link">{{ $t('general.forgotPassword') }}</b-button>
        <b-button v-if="$perms.isDevelopment()" to="/create-account" variant="link">{{ $t('general.createAccount') }}</b-button>
      </template>
    </McForm>
  </div>
</template>

<script>
import Router from '@/setup/Router'
import { mapActions } from 'vuex'
import AuthenticationService from '@/services/AuthenticationService'

const defaultData = {
  email: '',
  password: '',
  remember: false,
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      from: this.$route.query.from,
      resendConfirmation: false,
      resendCompleted: false,
    }
  },
  created() {
    // Log out when opening this form
    this.logout()
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  watch: {
    '$route.query.from'() {
      this.from = this.$route.query.from
    },
  },
  methods: {
    ...mapActions('LoginStore', [
      'login', //
      'logout',
    ]),
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return this.login(this.curData).then(
        data => {
          if (this.from) {
            Router.push('/' + this.from)
          } else {
            Router.push('/')
          }
          this.toastSuccess(this.$t('loginform.Success', { name: data.user.name }))
          return Promise.resolve(data)
        },
        error => {
          if (error === 'authentication.unconfirmedEmail') {
            this.resendConfirmation = true
          } else {
            console.error('Error logging in', error)
          }
          return Promise.reject(error)
        },
      )
    },

    handleResend(e) {
      return AuthenticationService.resendCreateMail(this.curData).then(
        data => {
          this.resendCompleted = { ...this.curData }
          return Promise.resolve(data)
        },
        error => {
          console.error('Error resending confirmation', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
