<template>
  <b-modal :id="$id('redeemModal')" :title="$t('general.RedeemGiftcard')" @ok="sendRedeem" :cancel-title="$t('general.Cancel')" :ok-title="$t('general.OK')">
    <p v-if="codeInfo.expired && !$perms.isQrUser()">{{ $t('general.RedeemingExpiredDescription') }}</p>
    <mc-form-input
      v-if="codeInfo"
      type="number"
      ref="redeemAmountInput"
      :min="-codeInfo.maxRefill / 100"
      :max="codeInfo.balance / 100"
      step=".25"
      :label="$t('giftcards.EnterSpentAmount')"
      v-model="spentAmount"
      :placeholder="$t('giftcards.EnterSpentAmount')"
      :description="$t('giftcards.NoteNegativeSpend')"
      :errorHint="amountError"
      :state="amountError == null"
      :icon="['fad', 'money-bill-wave']"
      :unit="$t('general.DKK')"
    />
    <p v-html="$t('giftcards.RemainingValue', { value: remainingValueAfterSpend })" />
  </b-modal>
</template>

<script>
import { FormatError } from '@/helpers/ErrorFormatting'
import CodesService from '@/services/CodesService'
import { niceCurrency } from '@/helpers/Formatting'
import { mapActions } from 'vuex'

export default {
  props: {
    codeInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      spentAmount: -1,
    }
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
      'toastWarning',
    ]),
    show() {
      const fakeInput = document.createElement('input')
      fakeInput.setAttribute('type', 'text')
      fakeInput.style.position = 'fixed'
      fakeInput.style.top = '0px'
      fakeInput.style.left = '0px'
      fakeInput.style.right = '0px'
      fakeInput.style.height = '40px'
      fakeInput.style.opacity = '1'
      fakeInput.style.fontSize = '16px'
      fakeInput.readOnly = true

      // You may need to append to another element depending on the browser's auto zoom/scroll behavior
      document.body.prepend(fakeInput)

      // Focus so that subsequent async focus will work
      fakeInput.focus()

      this.$bvModal.show(this.$id('redeemModal'))
      setTimeout(() => {
        if (this.$perms.isQrUser()) {
          this.spentAmount = 0
        } else {
          this.spentAmount = this.codeInfo.balance / 100
        }
      }, 100)
      setTimeout(() => {
        let el = this.$refs['redeemAmountInput'].$el.querySelector('input')
        el.focus()
        el.select()
        fakeInput.remove()
      }, 110)
    },
    sendRedeem(e) {
      if (!this.$perms.isAdministrator() && !this.$perms.hasPermission('markCodes', this.codeInfo.merchant)) {
        this.toastWarning(this.$t('permissions.LackingMarkCodesPermission'))
        return
      }
      if (this.amountError) {
        alert(this.amountError)
        e.preventDefault()
        return
      }
      let amount = parseInt(this.spentAmount * 100)
      CodesService.redeem(this.codeInfo.code, amount).then(
        data => {
          switch (data.message) {
            case 'code.redeemed':
              this.toastSuccess(this.$t('general.CodeRedeemed'))
              break
            case 'code.refilled':
              this.toastSuccess(this.$t('general.CodeRefilled'))
              break
            case 'code.partiallyRedeemed':
              this.toastSuccess(this.$t('general.CodePartiallyRedeemed'))
              break
          }
          this.$emit('redeemed')
          return Promise.resolve(data)
        },
        error => {
          alert(FormatError(error))
          return Promise.reject(error)
        },
      )
    },
  },
  computed: {
    remainingValueAfterSpend() {
      if (!this.codeInfo || this.amountError) {
        return '???'
      }
      return niceCurrency(this.codeInfo.balance - this.spentAmount * 100)
    },
    amountError() {
      if (!this.codeInfo) {
        return null
      }
      if (this.spentAmount < (this.codeInfo.balance - this.codeInfo.amount) / 100) {
        return this.$t('giftcards.AmountTooLow')
      } else if (this.spentAmount > this.codeInfo.balance / 100) {
        return this.$t('giftcards.AmountTooLarge')
      } else if (this.spentAmount == 0) {
        return this.$t('giftcards.AmountRequired')
      }
      return null
    },
  },
}
</script>
