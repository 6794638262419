import { authorizedApiRequest } from '@/setup/ApiService'

export default {
  getDefault: function (merchantId) {
    return authorizedApiRequest(
      `/webshop-designs/${merchantId}/default`, //
      'GET',
    )
  },
  setDefault: function (merchantId, design) {
    return authorizedApiRequest(
      `/webshop-designs/${merchantId}/default`, //
      'PUT',
      design,
    )
  },
}
