<template>
  <b-row>
    <b-col md="4" cols="12">
      <b-card :title="$t('merchants.SideBarGreeting', { name: LoginStore.user.name })" class="mb-2">
        <p>{{ $t('merchants.SideBar1') }}</p>
        <p class="text-center">
          <McButton @click="editMerchant({})" variant="secondary" icon="plus" :text="$t('general.AddMerchant')" />
        </p>
        <p>{{ $t('merchants.SideBar2') }}</p>
      </b-card>
    </b-col>
    <b-col md="8" cols="12">
      <div v-for="item in LoginStore.merchantInfo" v-bind:key="item.id">
        <b-card class="mb-4">
          <template v-slot:header>
            <div class="text-center">
              <b-card-title>{{ item.niceName }}</b-card-title>
              <b-card-sub-title class="mb-2">Merchant ID: {{ item.id }} - Prefix: {{ item.prefix }} </b-card-sub-title>
            </div>
          </template>
          <b-row>
            <b-col cols="12" lg="6">
              <label>{{ $t('general.CompanyAddress') }}</label>
              <address v-html="address(item)"></address>
            </b-col>
            <b-col v-if="item.logoDark.url || item.logoLight.url" cols="12" lg="6">
              <label>{{ $t('general.Logos') }}</label>
              <div v-if="item.logoDark.url" class="merchant_logo light">
                <div :style="'background-image: url(' + item.logoDark.url + ')'"><img :src="item.logoDark.url" /></div>
              </div>
              <div v-if="item.logoLight.url" class="merchant_logo dark">
                <div :style="'background-image: url(' + item.logoLight.url + ')'"><img :src="item.logoLight.url" /></div>
              </div>
              <!--pre>{{ item }}</pre-->
            </b-col>
          </b-row>
          <template v-if="$perms.isOwnerOfMerchant(item.id) || $perms.hasPermission('handleCompany', item.id)" v-slot:footer>
            <div class="text-center">
              <McButton @click="editMerchant(item)" variant="primary" icon="edit" :text="$t('general.Edit')" />
            </div>
          </template>
        </b-card>
      </div>
    </b-col>
    <MerchantForm id="merchantform" isModal size="lg" :value="editingMerchant" @completed="refreshTable()" />
  </b-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      editingMerchant: {},
    }
  },
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
  },
  methods: {
    editMerchant(item) {
      this.editingMerchant = { ...item }
      this.$bvModal.show('merchantform')
    },
    refreshTable() {
      console.log('test')
    },
    address(item) {
      let html = []
      if (item.company) {
        html.push(`<strong>${item.company}</strong>`)
      }
      if (item.name) {
        html.push(`<span>` + (item.company ? 'att. ' : '') + `${item.name}</span>`)
      }
      if (item.address1) {
        html.push(`<span>${item.address1}</span>`)
      }
      if (item.address2) {
        html.push(`<span>${item.address2}</span>`)
      }
      if (item.zip || item.city) {
        html.push(`<span>${item.zip} ${item.city}</span>`)
      }
      if (item.country && item.country.toUpperCase() !== 'denmark') {
        html.push(`<span>${item.country.toUpperCase()}</span>`)
      }
      if (item.cvr) {
        html.push(`<small>CVR: ${item.cvr}</small>`)
      }
      if (item.ean) {
        html.push(`<small>EAN: ${item.ean}</small>`)
      }
      if (item.reference) {
        html.push(`<small>REF: ${item.reference}</small>`)
      }
      if (item.rekvisition) {
        html.push(`<small>REK: ${item.rekvisition}</small>`)
      }
      return html.join('<br>')
    },
  },
}
</script>

<style lang="scss" scoped>
address {
  padding: 1em;
  border-radius: 3px;
  background: #eee;
}
.merchant_logo {
  padding: 10px;
  margin-bottom: 1em;
  border-radius: 3px;
  > div {
    background-size: contain;
    height: 58px;
    width: auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  &.light {
    border: 1px solid #ddd;
    background: #fff;
  }
  &.dark {
    border: 1px solid #000;
    background: #333;
  }
  img {
    width: 100%;
    height: auto;
    opacity: 0;
  }
}
</style>
