<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    submiticon="plus"
    :title="$t('integration.AddNewDomain')"
    :submittext="$t('integration.AddNewDomain')"
  >
    <div v-html="$t('integration.CreateHostedDomainDescription')" />

    <mc-form-select
      icon="signature"
      name="topDomain"
      v-model="curData.topDomain"
      :options="domainsList"
      v-validate="'required'"
      :data-vv-as="$t('integration.TopDomain')"
      :label="$t('integration.TopDomain')"
      :state="!errors.has('topDomain')"
      :placeholder="$t('integration.PickOne')"
      key="hosteddomainform.topDomain"
      :errorHint="errors.first('topDomain')"
    />

    <mc-form-input
      v-if="curData.topDomain"
      :unit="domainPostfix"
      :rightalign="domainPostfix != ''"
      name="myDomain"
      type="text"
      v-model="curData.myDomain"
      v-validate="'required'"
      :data-vv-as="$t('integration.FullDomainName')"
      :label="$t('integration.FullDomainName')"
      :placeholder="$t('integration.EnterDomainName')"
      key="hosteddomainform.name"
      :errorHint="errors.first('myDomain')"
    />

    <div v-if="curData.topDomain == 'myown'" class="alert alert-warning" v-html="$t('integration.OwnDomainNotice')" />
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import MerchantService from '@/services/MerchantService'

const defaultData = {
  myDomain: '',
  topDomain: '',
}

export default {
  data() {
    return {
      curData: { ...defaultData },
      domainsList: [
        { label: this.$t('integration.PickOne'), value: '' },
        { label: 'danskegavekort.dk', value: '.danskegavekort.dk' },
        { label: this.$t('integration.UseMyOwn'), value: 'myown' },
      ],
    }
  },
  computed: {
    domainPostfix() {
      return this.curData.topDomain == 'myown' ? '' : this.curData.topDomain
    },
  },

  props: {
    merchantId: Number,
    merchantPrefix: {
      type: String,
      default: '',
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    forceReset() {
      this.$refs['mcFormInstance'].forceReset()
    },

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      let fullDomain = this.curData.myDomain + this.domainPostfix

      let regex = /^(?=.{1,254}$)((?=[a-z0-9-]{1,63}\.)(xn--+)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i
      if (!fullDomain.match(regex)) {
        this.errors.add({
          field: 'myDomain',
          msg: this.$t('integration.YouNeedToEnterAFQDN'),
        })
        return
      }

      return MerchantService.addDomain(this.merchantId, fullDomain).then(
        data => {
          this.toastSuccess(this.$t('integration.DomainWasAdded'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating domain', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
