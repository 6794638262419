<template>
  <div aria-live="polite" aria-atomic="true" style="position: fixed; bottom: 1em; left: 1em; z-index: 99">
    <TransitionToAuto v-for="(message, index) in ToastStore.messages" :key="index">
      <div @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" class="toast show bg-white text-dark" role="alert" aria-live="assertive" aria-atomic="true">
        <div v-if="message.heading" :class="headingClasses(message)">
          <font-awesome-icon v-if="message.icon" :icon="message.icon" />&nbsp;
          <strong class="mr-auto">{{ $te(message.heading) ? $t(message.heading) : message.heading }}</strong>
          <small v-if="message.timeout == -1">{{ message.timein }}</small>
          <button type="button" class="ml-2 mb-1 close" @click="toastClear(index)" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          <div class="toast-timer" :style="getTimerWidth(message, index)"></div>
          <button v-if="!message.heading" type="button" class="ml-2 mb-1 close" @click="toastClear(index)" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          {{ $te(message.body) ? $t(message.body) : message.body }}
        </div>
      </div>
    </TransitionToAuto>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      now: new Date(),
      timeLeft: {},
      hovered: {},
      elapsed: 0,
    }
  },
  created() {
    this.setNow()
    window.requestAnimationFrame(this.setNow)
  },
  computed: {
    ...mapState(['ToastStore']),
  },
  methods: {
    ...mapActions('ToastStore', ['toastClear']),
    headingClasses(message) {
      let classes = ['toast-header', 'bg-' + message.type]
      if (message.type !== 'light') classes.push('text-white')
      return classes
    },
    setNow() {
      const newNow = new Date()
      this.elapsed = newNow - this.now
      this.now = newNow
      window.requestAnimationFrame(this.setNow)
    },
    mouseEnter(index) {
      this.hovered = { ...this.hovered, [index]: true }
    },
    mouseLeave(index) {
      this.hovered = { ...this.hovered, [index]: false }
    },
    getTimerWidth(message, index) {
      if (message.timeout === false) {
        return {}
      }
      if (this.timeLeft[index] === undefined) {
        this.timeLeft[index] = message.timeout * 1000 - (this.now - message.timein)
      }
      if (this.hovered[index] !== true) {
        this.timeLeft[index] -= this.elapsed
        if (this.timeLeft[index] < 0) {
          this.toastClear(index)
          delete this.timeLeft[index]
        }
      }
      return {
        width: 100 - this.timeLeft[index] / 10 / message.timeout + '%',
      }
    },
  },
}
</script>

<style>
.toast {
  width: 300px;
  max-width: 300px;
  margin-bottom: 10px;
}
.toast-body {
  position: relative;
}
.toast-timer {
  position: fixed;
  top: 0;
  left: 0;
  height: 5px;
  background: #666;
  opacity: 0.2;
  width: 0;
  /*transition: width .01s ease-in-out;*/
}
</style>
