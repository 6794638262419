<template>
  <McFormSelect v-if="canSelectMerchant" :options="merchantSelectOptions" :value="internalvalue" @input="emitNewValue" :label="$t('general.ChooseMerchant')" :icon="['fa', 'building']">
    <template slot="option" slot-scope="props">
      <div>
        <span>{{ props.option.label }}</span>
        <br v-if="props.option.$isDisabled" />
        <i18n v-if="props.option.$isDisabled" path="permissions.ParensNoPermission" tag="small" />
      </div>
    </template>
  </McFormSelect>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: null,
    permission: {
      type: String,
    },
    allowAll: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitNewValue(val) {
      this.$emit('input', val)
      for (let id in this.LoginStore.merchantInfo) {
        if (this.LoginStore.merchantInfo[id].id == val) {
          this.$emit('merchantSelected', this.LoginStore.merchantInfo[id])
          window.localStorage.setItem('lastSelectedMerchant', val)
        }
      }
    },
  },
  created() {
    if (!this.canSelectMerchant && this.merchantSelectOptions.length == 1) {
      this.emitNewValue(this.merchantSelectOptions[0].value)
    } else if (this.value == null) {
      this.emitNewValue(this.internalvalue)
    }
  },
  computed: {
    internalvalue() {
      if (this.value == null && !this.allowAll) {
        if (this.merchantSelectOptions.length > 1) {
          let prevSelection = window.localStorage.getItem('lastSelectedMerchant')
          for (var i in this.merchantSelectOptions) {
            if (this.merchantSelectOptions[i].value == prevSelection) {
              return this.merchantSelectOptions[i].value
            }
          }
        }
      }
      return this.value
    },
    canSelectMerchant() {
      return this.$perms.isAdministrator() || Object.keys(this.LoginStore.merchantInfo).length > 1
    },
    merchantSelectOptions() {
      let ret = []
      if (this.allowAll) {
        ret.push({
          label: this.$t('general.AllMerchants'),
          value: null,
        })
      }
      for (let id in this.LoginStore.merchantInfo) {
        let hasPerm = this.$perms.isAdministrator() || this.$perms.hasPermission(this.permission, this.LoginStore.merchantInfo[id].id)
        ret.push({
          label: this.LoginStore.merchantInfo[id].niceName,
          value: this.LoginStore.merchantInfo[id].id,
          $isDisabled: !hasPerm,
        })
      }
      ret.sort((a, b) => {
        return a.label == b.label ? 0 : a.label > b.label ? 1 : -1
      })
      return ret
    },
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
  },
}
</script>
