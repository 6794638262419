<template>
  <!--pre>Image: {{ element }}</pre-->
  <div>
    <mc-form-color-input :description="$t('designer.ChooseColorAndOpacity')" :label="$t('designer.Color')" v-model="element.backcolor" format="hex" opacity />
    <mc-form-checkboxes
      v-model="element.shape"
      :label="$t('designer.Shape')"
      :options="[
        { label: $t('designer.Rectangle'), value: 'rectangle' },
        { label: $t('designer.Ellipse'), value: 'ellipse' },
      ]"
      :icon="['fad', 'user-tag']"
    />
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    pixelsPerMm: Number,
  },
}
</script>
