<template>
  <div
    class="mc_moveable"
    :style="{
      top: scaledPosition.top + 'px',
      left: scaledPosition.left + 'px',
      width: scaledPosition.width + 'px',
      height: scaledPosition.height + 'px',
      transform: 'rotate(' + scaledPosition.rotation + 'deg)',
    }"
    @mousedown="e => $emit('mousedown', e)"
  >
    <div style="height: 100%; width: 100%; pointer-events: none">
      <slot />
    </div>
  </div>
</template>

<script>
import Moveable, { EVENTS, PROPERTIES, METHODS } from 'moveable'

const watchReactiveProp = (key, deep) => ({
  handler(newValue) {
    const existingValue = this.moveable[key]
    if (existingValue === newValue) return
    this.moveable[key] = newValue
  },
  deep,
})

const watchMoveableProps = () =>
  PROPERTIES.reduce((acc, prop) => {
    acc[prop] = watchReactiveProp(prop, false)
    return acc
  }, {})
const methodMap = {}
METHODS.forEach(name => {
  methodMap[name] = function func(...args) {
    return this.moveable[name](...args)
  }
})

export default {
  name: 'McMoveable',
  inheritAttrs: false,
  props: {
    roundable: Boolean,
    roundRelative: Boolean,
    originDraggable: Boolean,
    originRelative: Boolean,
    clippable: Boolean,
    customClipPath: String,
    defaultClipPath: String,
    clipRelative: Boolean,
    dragWithClip: Boolean,
    clipArea: Boolean,
    defaultGroupOrigin: String,
    cspNonce: String,
    checkInput: Boolean,
    groupable: Boolean,
    snappable: [Boolean, Array],
    snapCenter: Boolean,
    snapHorizontal: Boolean,
    snapVertical: Boolean,
    snapElement: Boolean,
    snapGap: Boolean,
    snapThreshold: Number,
    snapDigit: Number,
    snapDirections: Object,
    isDisplaySnapDigit: Boolean,
    horizontalGuidelines: Array,
    verticalGuidelines: Array,
    elementGuidelines: Array,
    bounds: Object,
    innerBounds: Object,
    snapDistFormat: Function,
    defaultGroupRotate: Number,
    scrollable: Boolean,
    scrollContainer: [HTMLElement, SVGElement],
    scrollThreshold: Number,
    getScrollPosition: Function,
    warpable: Boolean,
    renderDirections: Array,
    rotatable: Boolean,
    rotationPosition: String,
    throttleRotate: Number,
    pinchable: [Boolean, Array],
    scalable: Boolean,
    throttleScale: Number,
    keepRatio: Boolean,
    resizable: Boolean,
    throttleResize: Number,
    baseDirection: Array,
    draggable: Boolean,
    throttleDrag: Number,
    throttleDragRotate: Number,
    startDragRotate: Number,
    dragTarget: [HTMLElement, SVGElement],
    container: {
      type: [HTMLElement, SVGElement],
      default: () => document.body,
    },
    rootContainer: HTMLElement,
    dragArea: Boolean,
    origin: Boolean,
    zoom: Number,
    transformOrigin: [Array, String],
    edge: Boolean,
    ables: Array,
    className: String,
    pinchThreshold: Number,
    pinchOutside: Boolean,
    triggerAblesSimultaneously: Boolean,
    padding: Object, // { left: number, top: number, right: number, bottom: number }

    position: {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      rotation: 0,
    },
    pixelsPerMm: Number,
  },
  data: function () {
    return {
      frame: {
        translate: [0, 0],
      },
    }
  },
  computed: {
    scaledPosition() {
      return this.scalePosition(this.position)
    },
  },
  methods: {
    ...methodMap,
    scalePosition(position) {
      return {
        left: position.left * this.pixelsPerMm,
        top: position.top * this.pixelsPerMm,
        width: position.width * this.pixelsPerMm,
        height: position.height * this.pixelsPerMm,
        rotation: position.rotation,
      }
    },
    applyPosition(newPosition) {
      let scaled = this.scalePosition(newPosition)
      this.$el.style.left = scaled.left + 'px'
      this.$el.style.top = scaled.top + 'px'
      this.$el.style.width = scaled.width + 'px'
      this.$el.style.height = scaled.height + 'px'
      this.$el.style.transform = `rotate(${scaled.rotation}deg)`
    },
    resetTransform() {
      this.$el.style.transform = `rotate(${this.position.rotation}deg)`
    },
    updateSnapElements() {
      //console.log('updateSnapElements')
      let divs = document.querySelectorAll('.mc_moveable')
      let otherMovables = []
      for (var i = 0; i < divs.length; ++i) {
        if (divs[i] !== this.$el) {
          otherMovables.push(divs[i])
        }
      }
      this.moveable.elementGuidelines = otherMovables
    },
  },
  mounted() {
    this.moveable = new Moveable(this.$props.container, {
      ...this.$props,
      target: this.$el,
    })

    this.updateSnapElements()

    this.moveable
      .on('dragStart', ({ target }) => {
        target.classList.add('dragging')
      })
      .on('drag', ({ target, beforeTranslate }) => {
        target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${this.position.rotation}deg)`
      })
      .on('dragEnd', ({ target, isDrag, clientX, clientY, lastEvent }) => {
        target.classList.remove('dragging')
        this.resetTransform()
        if (!lastEvent) {
          return
        }
        let newPosition = {
          ...this.position,
          left: Math.round(this.position.left * this.pixelsPerMm + lastEvent.beforeTranslate[0]) / this.pixelsPerMm,
          top: Math.round(this.position.top * this.pixelsPerMm + lastEvent.beforeTranslate[1]) / this.pixelsPerMm,
        }
        //console.log(lastEvent, newPosition)
        this.applyPosition(newPosition)
        this.$emit('moved', newPosition)
      })
    this.moveable
      .on('resizeStart', ({ target, set, setOrigin, dragStart }) => {
        setOrigin(['%', '%'])
        const style = window.getComputedStyle(target)
        const cssWidth = parseFloat(style.width)
        const cssHeight = parseFloat(style.height)
        set([cssWidth, cssHeight])
      })
      .on('resize', ({ target, width, height, drag }) => {
        target.style.width = `${width}px`
        target.style.height = `${height}px`
        target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px) rotate(${this.position.rotation}deg)`
      })
      .on('resizeEnd', ({ target, isDrag, clientX, clientY, lastEvent }) => {
        this.resetTransform()
        if (!lastEvent) {
          return
        }
        let newPosition = {
          ...this.position,
          width: Math.round(lastEvent.width) / this.pixelsPerMm,
          height: Math.round(lastEvent.height) / this.pixelsPerMm,
          left: Math.round(this.position.left * this.pixelsPerMm + lastEvent.drag.beforeTranslate[0]) / this.pixelsPerMm,
          top: Math.round(this.position.top * this.pixelsPerMm + lastEvent.drag.beforeTranslate[1]) / this.pixelsPerMm,
        }
        //console.log(lastEvent, newPosition)
        this.applyPosition(newPosition)
        this.$emit('moved', newPosition)
      })
    this.moveable
      .on('rotateStart', ({ set }) => {
        set(this.position.rotation)
      })
      .on('rotate', ({ target, beforeRotate }) => {
        target.style.transform = `rotate(${beforeRotate}deg)`
      })
      .on('rotateEnd', ({ target, isDrag, clientX, clientY, lastEvent }) => {
        if (!lastEvent) {
          return
        }
        let newPosition = {
          ...this.position,
          rotation: parseInt(Math.round(lastEvent.beforeRotate / 1) * 1) % 360,
        }
        //console.log(newPosition)
        this.applyPosition(newPosition)
        this.$emit('moved', newPosition)
      })

    EVENTS.forEach(event => {
      const kebabCaseEvent = event.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
      this.moveable.on(event, this.$emit.bind(this, kebabCaseEvent))
      // Backwards support for camelCase events
      this.moveable.on(event, this.$emit.bind(this, event))
    })
    window.addEventListener('resize', this.updateRect, { passive: true })
  },
  watch: {
    ...watchMoveableProps(),
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateRect)
    this.moveable.destroy()
  },
}
</script>

<style lang="scss">
.mc_moveable {
  position: absolute;
  transform-origin: 50% 50%;
  cursor: grab;
  &.dragging {
    cursor: grabbing;
  }
}
.moveable-line.moveable-direction {
  display: none;
}
.moveable-control-box {
  .moveable-guideline.moveable-line.moveable-gap:before,
  .moveable-guideline.moveable-line.moveable-dashed:before {
    font-size: 14px;
    font-weight: normal;
    color: #666;
    padding: 2px 6px;
    z-index: 8;
  }
  /* Hide "normal" guidelines when gap is shown?*/
  .moveable-guideline.moveable-line.moveable-gap ~ .moveable-dashed {
    display: none;
  }
  /* Scale gap labels */
  .moveable-line.moveable-dashed:before {
    font-size: calc(12px * var(--zoom)) !important;
  }
}
</style>
