<template>
  <b-card v-if="(withJoke || hasData || loadingState) && ($perms.isAdministrator() || $perms.hasMerchantsWithPermission('seeOrders'))" v-bind="$attrs">
    <McSpinner v-if="loadingState === true" large />
    <div v-else-if="hasData">
      <p>{{ $t('codesChart.DistributionOfGiftCards', { count: totalCount }) }}</p>
      <div style="height: 200px">
        <chartPie ref="theChart" :chartData="datacollection" :options="options" style="height: 100%; position: relative"></chartPie>
      </div>
    </div>
    <div v-else class="text-center" style="position: relative; min-height: 200px">
      <p>
        {{ $t('ordersChart.NoChartData') }}
      </p>
      <div style="height: 100px"></div>
      <p style="position: absolute; bottom: 0; left: 0; right: 0" class="mb-0">
        {{ $t('ordersChart.NoChartData2') }}
      </p>
    </div>
  </b-card>
</template>

<script>
import CodesService from '@/services/CodesService'
import { FormatError } from '@/helpers/ErrorFormatting'
import { niceCurrency } from '@/helpers/Formatting'

let chartColors = {
  blue: 'rgb(54, 162, 235)', //
  green: 'rgb(75, 192, 192)',
  grey: 'rgb(201, 203, 207)',
  orange: 'rgb(255, 159, 64)',
  purple: 'rgb(153, 102, 255)',
  red: 'rgb(255, 99, 132)',
  yellow: 'rgb(255, 205, 86)',
}

export default {
  props: {
    withJoke: {
      type: Boolean,
      default: true,
    },
    merchantId: {
      type: Number,
      default: null,
    },
    includeTestMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingState: true,
      hasData: true,
      datacollection: {},
      totalCount: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: 'nearest',
          intersect: false,
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, chart) => {
              var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || ''
              datasetLabel += ' ' + chart.labels[tooltipItem.index]
              if (tooltipItem.datasetIndex == 1) {
                return datasetLabel + ': ' + niceCurrency(this.datacollection.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
              }
              return datasetLabel + ': ' + this.datacollection.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
            },
          },
        },
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadCodesOverview()
    }, 500)
  },
  watch: {
    merchantId() {
      this.loadCodesOverview()
    },
    includeTestMode() {
      this.loadCodesOverview()
    },
  },
  methods: {
    loadCodesOverview() {
      return CodesService.overview(this.merchantId, this.$perms.isAdministrator() ? this.includeTestMode : null)
        .then(data => {
          this.loadingState = false
          this.displayOverview(data)
        })
        .catch(error => {
          console.log(FormatError(error))
          this.loadingState = false
        })
    },
    displayOverview(data) {
      let datacollection = {
        labels: [
          this.$t('codesChart.Outstanding'), //
          this.$t('codesChart.Spent'),
          this.$t('codesChart.SpentExpired'),
          this.$t('codesChart.Expired'),
          this.$t('codesChart.Refunded'),
        ],
        datasets: [
          {
            name: 'counts',
            label: this.$t('codesChart.Count'),
            backgroundColor: [
              chartColors.blue, //
              chartColors.yellow,
              chartColors.purple,
              chartColors.green,
              chartColors.red,
            ],
            data: [
              data.counts.outstanding, //
              data.counts.spent,
              data.counts.spentExpired,
              data.counts.expired,
              data.counts.refunded,
            ],
          },
          {
            name: 'amounts',
            label: this.$t('codesChart.Amount'),
            backgroundColor: [
              '#00a', //
              '#aa0',
              '#a0a',
              '#0a0',
              '#a00',
            ],
            data: [
              data.amounts.outstanding, //
              data.amounts.spent,
              data.amounts.spentExpired,
              data.amounts.expired,
              data.amounts.refunded,
            ],
          },
        ],
      }
      this.hasData = false
      for (let series in data.amounts) {
        if (data.counts[series] > 0) {
          this.hasData = true
        }
      }
      this.totalCount = data.totalCount

      this.datacollection = datacollection

      if (!this.hasData) {
        this.$emit('nothingToShow')
      }
    },
  },
}
</script>
