<template>
  <div>
    <span class="localtime" :data-gmt="time">
      {{ outputFormat }}
    </span>
  </div>
</template>

<script>
import { localizedHumanreadableAge, localizedDatetime } from '@/helpers/Time'

export default {
  props: {
    time: String,
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss',
    },
  },
  data() {
    return {
      ticker: 0, //Force refresh every second
      updateTimer: null,
    }
  },
  mounted() {
    this.updateTimer = setInterval(() => {
      this.ticker++ //Force refresh every second
    }, 1000)
  },
  beforeDestroy() {
    if (this.updateTimer !== null) {
      clearInterval(this.updateTimer)
    }
  },
  computed: {
    outputFormat() {
      switch (this.format) {
        case 'age':
          // eslint-disable-next-line
          let dummy = this.ticker //Force refresh every second
          return localizedHumanreadableAge(this.time)
        default:
          return localizedDatetime(this.time, this.format)
      }
    },
  },
}
</script>
