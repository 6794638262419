import { authorizedApiUpload, authorizedApiRequest, queryParameters } from '@/setup/ApiService'

export default {
  image: function (file) {
    return authorizedApiUpload(
      `/uploads/image`, //
      'POST',
      file,
    )
  },
  myImages: function () {
    return authorizedApiRequest(
      `/uploads/images`, //
      'GET',
    )
  },
  stockImages: function (query, page) {
    return authorizedApiRequest(
      '/stock-photos/query?' + queryParameters({ query, page }), //
      'GET',
    )
  },
  downloadStockImage: function (provider, imageId) {
    return authorizedApiRequest(
      `/stock-photos/download`, //
      'POST',
      {
        provider,
        imageId,
      },
    )
  },
}
