<template>
  <div>
    <div class="text-center py-2" style="background: rgba(0, 0, 0, 0.1); border-bottom: 1px solid #aaa" v-if="heading">
      <div class="container">
        <h1>{{ heading }}</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 my-5">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['heading'],
}
</script>
