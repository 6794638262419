<template>
  <b-button v-bind="$attrs" :class="(square ? 'square' : 'nonsquare') + (active ? ' active' : '')" v-on="listeners">
    <Transition v-if="icon" name="formbuttonicon">
      <span class="iconposition" v-if="active" key="icon.disabled"><font-awesome-icon icon="spinner" spin fixed-width /></span>
      <span class="iconposition" v-else key="icon.enabled">
        <font-awesome-layers v-if="icon === 'clone'" fixed-width>
          <font-awesome-icon :icon="['fas', 'sheep']" fixed-width transform="up-3 right-3" />
          <font-awesome-icon :icon="['fas', 'sheep']" fixed-width transform="down-3 left-3" style="color: #ccc; opacity: 0.9" />
        </font-awesome-layers>
        <font-awesome-icon v-else :icon="icon" fixed-width />
      </span>
    </Transition>
    <TransitionToAuto horisontal v-else-if="active" name="formbuttonnoicon">
      <span class="iconposition"><font-awesome-icon icon="spinner" spin fixed-width /></span>
    </TransitionToAuto>
    <span class="textposition" v-if="text">{{ text }}</span>
  </b-button>
</template>

<script>
export default {
  props: {
    active: Boolean,
    text: String,
    icon: [String, Array],
    square: Boolean,
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
  },
}
</script>

<style scoped>
.active {
  cursor: progress !important;
}
.iconposition {
  display: inline-block;
  margin-right: 0.2em;
}
.formbuttonicon-enter-active {
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}
.formbuttonicon-leave-active {
  transition: opacity 0.2s ease-in, transform 0.2s ease-in;
  margin-right: 0;
  width: 0;
}

.formbuttonicon-enter,
.formbuttonicon-leave-to {
  opacity: 0;
  transform: scale(0.1);
}

.square {
  width: 8em;
  height: 8em;
  font-size: .9em;
}
.square .iconposition {
  font-size: 3.5em;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.square .textposition {
  font-size: 1em;
  display: block;
  text-align: center;
  margin: 0 auto;
}
</style>
