import app from '@/main'
import { i18n } from '@/setup/i18n'

const state = {
  currentLanguage: i18n.locale,
}

const actions = {
  setLang({ commit }, payload) {
    if (payload in app.$i18n.messages) {
      commit('setLang', payload)
    } else {
      try {
        const asyncMain = import(`../language/${payload}.json`)
        const asyncValidation = import(`../language/${payload}-validations`)
        Promise.all([asyncMain, asyncValidation])
          .then(([mainMessages, validationMessages]) => {
            app.$validator.localize(payload, validationMessages.default)
            i18n.setLocaleMessage(payload, mainMessages)
            commit('setLang', payload)
          })
          .catch(error => {
            console.log(error)
          })
      } catch (e) {
        console.log(e)
      }
    }
  },
}

const mutations = {
  setLang(state, payload) {
    localStorage.setItem('currentLanguage', payload)
    app.$validator.localize(payload)
    app.$moment.locale(payload)
    state.currentLanguage = payload
    i18n.locale = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
