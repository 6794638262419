<template>
  <div v-bind="$attrs" :class="'mc-spinner' + (centercenter ? ' center-center' : '')" :style="{ fontSize: large ? '4em' : '1em', display: inline ? 'inline-block' : 'block', textAlign: 'center' }">
    <div class="mc-spinner-inner">
      <font-awesome-icon icon="spinner" spin fixed-width />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    centercenter: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style>
.mc-spinner.center-center {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mc-spinner.center-center .mc-spinner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
