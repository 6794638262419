<!-- eslint-disable -->
<template>
  <McLayoutDefault>
    <h1>Your user:</h1>
    <pre>{{ LoginStore.user }}</pre>
    
    <h1>Your merchants:</h1>
    <pre>{{ LoginStore.merchantInfo }}</pre>
    
    <h1>Your $perms:</h1>
    <pre>{{ LoginStore.permissions }}</pre>
    
    <h1>Your $owns:</h1>
    <pre>{{ LoginStore.owns }}</pre>
    
    <h3>Tests:</h3>
    <table class="permstests">
      <tr><td>isAdministrator():</td><td>{{ $perms.isAdministrator() }}</td></tr>
      <tr><td>hasRole('administrator'):</td><td>{{ $perms.hasRole('administrator') }}</td></tr>
      <tr><td>hasRole('user'):</td><td>{{ $perms.hasRole('user') }}</td></tr>

      <tr><td>isOwnerOfMerchants():</td><td>{{ $perms.isOwnerOfMerchants() }}</td></tr>

      <tr><td>hasSeveralMerchants():</td><td>{{ $perms.hasSeveralMerchants() }}</td></tr>

      <tr><td>hasMerchantsWithPermission('seeOrders'):</td><td>{{ $perms.hasMerchantsWithPermission('seeOrders') }}</td></tr>
      <tr><td>hasMerchantsWithPermission('markCodes'):</td><td>{{ $perms.hasMerchantsWithPermission('markCodes') }}</td></tr>
      <tr><td>hasMerchantsWithPermission('manageCertificates'):</td><td>{{ $perms.hasMerchantsWithPermission('manageCertificates') }}</td></tr>
      <tr><td>hasMerchantsWithPermission('handleCompany'):</td><td>{{ $perms.hasMerchantsWithPermission('handleCompany') }}</td></tr>
    </table>
  </McLayoutDefault>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
  },
}
</script>

<style lang="scss" scoped>
.permstests {
  td:first-child {
    text-align: right;
    padding-right: 1em;
  }
}
</style>
