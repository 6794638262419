import { authorizedApiRequest, queryParameters } from '@/setup/ApiService'

export default {
  create: function (params) {
    return authorizedApiRequest(
      '/users', //
      'POST',
      params,
    )
  },

  list: function () {
    return authorizedApiRequest(
      '/users/list', //
      'GET',
    )
  },

  query: function (params) {
    return authorizedApiRequest(
      '/users?' + queryParameters(params), //
      'GET',
    )
  },

  getById: function (id) {
    return authorizedApiRequest(
      `/users/${id}`, //
      'GET',
    )
  },

  update: function (user) {
    return authorizedApiRequest(
      `/users/${user.id}`, //
      'PUT',
      user,
    )
  },

  delete: function (id) {
    return authorizedApiRequest(
      `/users/${id}`, //
      'DELETE',
    )
  },
}
