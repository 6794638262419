import { authorizedApiRequest, queryParameters } from '@/setup/ApiService'

export default {
  query: function (params, merchantId) {
    let newParams = { ...params }
    let url = '/billing?' + queryParameters(newParams)
    if (merchantId) {
      url = '/billing/' + merchantId + '?' + queryParameters(newParams)
    }
    return authorizedApiRequest(
      url, //
      'GET',
    )
  },
  addReminder(invoiceId, reminderNum) {
    let url = '/billing/add-reminder'
    return authorizedApiRequest(
      url, //
      'POST',
      {
        invoiceId,
        reminderNum,
      },
    )
  },
  cancelReminder(invoiceId, reminderNum) {
    let url = '/billing/cancel-reminder'
    return authorizedApiRequest(
      url, //
      'POST',
      {
        invoiceId,
        reminderNum,
      },
    )
  },
  markPaid(invoiceId, amountInCents) {
    let url = '/billing/mark-paid'
    return authorizedApiRequest(
      url, //
      'POST',
      {
        invoiceId,
        amountInCents,
      },
    )
  },
}
