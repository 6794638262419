var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mc_moveable",style:({
    top: _vm.scaledPosition.top + 'px',
    left: _vm.scaledPosition.left + 'px',
    width: _vm.scaledPosition.width + 'px',
    height: _vm.scaledPosition.height + 'px',
    transform: 'rotate(' + _vm.scaledPosition.rotation + 'deg)',
  }),on:{"mousedown":e => _vm.$emit('mousedown', e)}},[_c('div',{staticStyle:{"height":"100%","width":"100%","pointer-events":"none"}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }