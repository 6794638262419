<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group>
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <b-input-group-text v-if="prepend" slot="prepend" class="bg-primary text-white">
        {{ prepend }}
      </b-input-group-text>
      <!-- prettier-ignore -->
      <date-picker v-if="!nativeSupport"
        :config="internalConfig"
        v-model="internalDate"
        readonly="readonly"
      />
      <b-form-input v-else type="date" :min="minDateForNative" :max="maxDateForNative" v-model="internalDate" />
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
import { mapState } from 'vuex'
import { dateTodateLocalString, datetimeLocalStringToDate } from '../helpers/Time'
import moment from 'moment'

export default {
  data() {
    return {
      componentWidth: 0,
      nativeSupport: false,
    }
  },
  methods: {
    handleResize() {
      const target = this.$el
      this.componentWidth = target.getBoundingClientRect().width
      if (this.componentWidth === 0) {
        this.componentWidth = 2000
        setTimeout(this.handleResize, 500)
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    //Use native input on touch devices that support it
    if (this.isTouchDevice) {
      var i = document.createElement('input')
      i.setAttribute('type', 'datetime-local')
      this.nativeSupport = i.type !== 'text'
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    internalConfig() {
      return Object.assign(
        {},
        {
          format: 'YYYY-MM-DD',
          useCurrent: false,
          inline: false,
          locale: this.LanguageStore.currentLanguage,
          ignoreReadonly: true,
          showClose: true,
          widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto',
          },
          icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'fas fa-calendar-check',
            clear: 'far fa-trash-alt',
            close: 'far fa-check-circle',
          },
        },
        this.min ? { minDate: moment(this.min) } : {},
        this.max ? { maxDate: moment(this.max) } : {},
        this.config,
      )
    },
    minDateForNative() {
      if (!this.min) {
        return null
      }
      return dateTodateLocalString(this.min)
    },
    maxDateForNative() {
      if (!this.max) {
        return null
      }
      return dateTodateLocalString(this.max)
    },
    internalDate: {
      set: function (val) {
        //console.log(this.internalConfig.format, val, moment(datetimeLocalStringToDate(val).toDate()).format(this.internalConfig.format))
        this.$emit('input', moment(datetimeLocalStringToDate(val).toDate()).format(this.internalConfig.format))
      },
      get: function () {
        if (this.nativeSupport) {
          return dateTodateLocalString(this.value)
        }
        return this.value
      },
    },
    isSmall() {
      return this.componentWidth < 420
    },
    isTouchDevice() {
      return 'ontouchstart' in window /* works on most browsers */ || navigator.maxTouchPoints /* works on IE10/11 and Surface */
    },
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    ...mapState({
      LanguageStore: state => state.LanguageStore,
    }),
  },
  props: {
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    prepend: String,
    min: Date,
    max: Date,
    config: {
      type: Object,
      default() {
        return {}
      },
    },
    value: String,
  },
}
</script>
