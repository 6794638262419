<template>
  <McLayoutDefault>
    <h1>Testpage...</h1>
    <QrScanner ref="scanner" />
  </McLayoutDefault>
</template>

<script>
export default {
  methods: {
    start() {
      return null
    },
  },
}
</script>
