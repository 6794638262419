import Vue from 'vue'
import store from '@/setup/Store'

Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.perms) {
      this.$perms = options.perms
    } else if (options.parent && options.parent.$perms) {
      this.$perms = options.parent.$perms
    }
  },
})

export default {
  userId: function () {
    if (!store.state.LoginStore.user) {
      return 0
    }
    return store.state.LoginStore.user.id
  },

  isLoggedIn: function () {
    if (!store.state.LoginStore.user) {
      return false
    }
    return true
  },

  loggedInUser: function () {
    if (!this.isLoggedIn()) {
      return false
    }
    return store.state.LoginStore.user
  },

  hasRole: function (role) {
    if (!this.isLoggedIn()) {
      return false
    }
    return this.loggedInUser().role === role
  },

  isAdministrator: function () {
    return this.hasRole('administrator')
  },

  isQrUser: function () {
    return this.hasRole('qr')
  },

  ownerOfMerchants: function () {
    let ret = []
    if (store.state.LoginStore.owns) {
      for (var a in store.state.LoginStore.owns) {
        ret.push(store.state.LoginStore.owns[a])
      }
    }
    return ret
  },

  isOwnerOfMerchants: function () {
    return this.ownerOfMerchants().length > 0
  },

  isOwnerOfMerchant: function (merchantId) {
    return store.state.LoginStore.owns.includes(merchantId.toString())
  },

  hasSeveralMerchants: function () {
    return Object.keys(store.state.LoginStore.merchantInfo).length > 1
  },

  hasPermission: function (perm, merchantId) {
    if (!store.state.LoginStore.permissions) {
      return false
    }
    if (!store.state.LoginStore.permissions[merchantId]) {
      return false
    }
    return store.state.LoginStore.permissions[merchantId][perm]
  },

  merchantsWithPermission: function (permission) {
    if (!store.state.LoginStore.permissions || !store.state.LoginStore.permissions || Object.keys(store.state.LoginStore.permissions).length === 0) {
      return []
    }
    let retval = []
    for (let id in store.state.LoginStore.permissions) {
      let perms = store.state.LoginStore.permissions[id]
      if (perms[permission]) {
        retval.push(id)
      }
    }

    return retval
  },

  hasMerchantsWithPermission: function (permission) {
    return this.merchantsWithPermission(permission).length > 0
  },

  hasMultipleMerchantsWithPermission: function (permission) {
    return this.merchantsWithPermission(permission).length > 1
  },

  isDevelopment: function () {
    return process.env.NODE_ENV === 'development'
  },

  isDeveloper: function () {
    return store.state.LoginStore.isDeveloper
  },
}
