<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    keepOpen
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :successText="false"
    :submiticon="!pdfLink ? ['far', 'print'] : ['far', 'check']"
    :title="$t('offlineGiftcards.PrintOfflineGiftcards')"
    :hasCancel="!pdfLink"
    :submittext="pdfLink ? $t('offlineGiftcards.Finished') : $t('offlineGiftcards.Print')"
  >
    <transition-to-auto>
      <div v-if="!pdfLink">
        <mc-form-checkbox v-model="curData.overview" :description="$t('offlineGiftcards.OverviewListDescription')">{{ $t('offlineGiftcards.OverviewList') }}</mc-form-checkbox>
        <mc-form-checkbox v-model="curData.giftcards" :description="$t('offlineGiftcards.GiftcardsDescription')">{{ $t('offlineGiftcards.Giftcards') }}</mc-form-checkbox>
        <mc-form-checkbox v-if="$perms.isDevelopment()" v-model="curData.qrLabels" :description="$t('offlineGiftcards.QRLabelsDescription')">{{ $t('offlineGiftcards.QRLabels') }}</mc-form-checkbox>
      </div>
    </transition-to-auto>
    <transition-to-auto>
      <div v-if="pdfLink" class="text-center">
        <p>{{ $t('offlineGiftcards.ExportReady') }}</p>
        <mc-button class="mb-4" :href="pdfLink" target="_blank" :icon="['fad', 'cloud-download']" variant="success" :text="$t('offlineGiftcards.FetchList')" />
      </div>
    </transition-to-auto>
  </McForm>
</template>

<script>
import OfflineGiftcardsService from '@/services/OfflineGiftcardsService'
import TransitionToAuto from '../transitions/TransitionToAuto.vue'

const defaultData = {
  overview: true,
  giftcards: false,
  qrLabels: false,
}

export default {
  components: { TransitionToAuto },
  data() {
    return {
      curData: { ...defaultData },
      merchantId: null,
      pdfLink: null,
      resolver: null,
    }
  },
  mounted() {
    this.curData = { ...defaultData }
  },
  methods: {
    forceReset(merchantId) {
      this.$refs['mcFormInstance'].forceReset()
      this.merchantId = merchantId
      this.pdfLink = null
    },

    handleReset() {
      this.curData = { ...defaultData }
      this.pdfLink = null
    },

    handleSubmit(e) {
      if (this.pdfLink) {
        this.$refs['mcFormInstance'].hideModal()
        return Promise.resolve()
      }

      var sections = []

      if (this.curData.overview) {
        sections.push('overview')
      }
      if (this.curData.giftcards) {
        sections.push('giftcards')
      }
      if (this.curData.qrLabels) {
        sections.push('qrLabels')
      }

      if (sections.length === 0) {
        return Promise.reject(this.$t('offlineGiftcards.PleaseChooseAtLeastOneSection'))
      }

      return OfflineGiftcardsService.export(this.merchantId, sections).then(
        data => {
          this.pdfLink = data.pdfLink
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating offline giftcard(s)', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
