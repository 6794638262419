<template>
  <McLayoutDefault :heading="$t('headings.Billing')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.hasMerchantsWithPermission('seeBilling')">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col md="4" cols="12">
        <b-card class="mb-2">
          <p>{{ $t('billing.Description') }}</p>
          <MerchantSelector allowAll permission="seeBilling" v-model="merchantId" />
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <McTable class="billing-table" ref="billingTable" :emptyText="$t('billing.YouHaveNoInvoices')" :sortBy="sortBy" sortDesc :primaryKey="primaryKey" :fields="fields" :provider="dataProvider" :tbody-tr-class="item => item && (item.creditsId > 0 ||  item.creditedById > 0) ? 'credited' : ''">
          <template v-slot:cell(invoiceId)="data">
            <a :href="data.item.invoiceLink" target="_blank">{{ data.item.invoiceId }}</a>
            <div v-if="data.item.creditedById > 0" class="credited_wrap">
              <small>{{ $t('billing.creditedByInvoiceNum', { invoiceNum: 'FAK-' + data.item.creditedById }) }}</small>
            </div>
            <div v-if="data.item.creditsId > 0" class="credited_wrap">
              <small>{{ $t('billing.creditsInvoiceNum', { invoiceNum: 'FAK-' + data.item.creditsId }) }}</small>
            </div>
            <div v-if="!merchantId && ($perms.hasMultipleMerchantsWithPermission('seeBilling') || $perms.isAdministrator())" class="merchant_wrap">
              <small>{{ data.item.merchantName }}</small>
            </div>
          </template>
          <template v-slot:cell(soldAmount)="data">
            {{ data.item.soldAmount | niceCurrency }}
          </template>
          <template v-slot:cell(createdAmount)="data">
            {{ data.item.createdAmount | niceCurrency }}
          </template>
          <template v-slot:cell(commissionAmount)="data">
            {{ data.item.commissionAmount | niceCurrency }}
          </template>
          <template v-slot:cell(payoutAmount)="data">
            <span :class="data.item.payoutAmount < 0 ? 'text-danger' : ''">{{ data.item.payoutAmount | niceCurrency }}</span>
          </template>
          <template v-slot:cell(status)="data">
            <span :class="data.item.status | paidClass">{{ $t('billing.InvoiceStatuses.' + data.item.status) }}</span>
            <div v-for="(reminder, reminderNum) in data.item.reminderInfo" v-bind:key="reminderNum">
              <small :class="reminder.status | paidClass">+ {{ reminder.heading }} ({{ reminder | feeTotal }})</small>
            </div>
            <div v-if="$perms.isAdministrator() && cancelableReminder(data.item)">
              <small>
                - <a href="#" @click.prevent="startCancelReminder(data.item)">{{ $t('billing.CancelReminder.' + cancelableReminder(data.item)) }}</a>
              </small>
            </div>
            <div v-if="$perms.isAdministrator() && addableReminder(data.item)">
              <small>
                - <a href="#" @click.prevent="startAddReminder(data.item)">{{ $t('billing.SendReminder.' + addableReminder(data.item)) }}</a>
              </small>
            </div>
            <div v-if="$perms.isAdministrator() && data.item.status === 'unpaid'">
              <small>
                - <a href="#" @click.prevent="markPaid(data.item)">{{ $t('billing.MarkPaid') }}</a>
              </small>
            </div>
          </template>
          <template v-slot:cell(month)="data">
            {{ data.item.month | formatMonth }}
          </template>
          <template v-slot:cell(duedate)="data">
            {{ data.item.duedate | formatDuedate }}
          </template>
        </McTable>

        <!--div v-if="loadingState">
          <McSpinner large />
        </div>
        <div v-else>
          <pre>{{ invoiceData }}</pre>
        </div-->
      </b-col>
    </b-row>
    <b-modal id="markPaidModal" :title="$t('billing.MarkPaid')" @ok="doMarkPaid" :cancel-title="$t('general.Cancel')" :ok-title="$t('general.OK')">
      <p>{{ $t('billing.EnterPaymentAmount') }}</p>
      <mc-form-input
        type="number"
        ref="markpaidAmountInput"
        :min="0"
        step=".01"
        :label="$t('billing.EnterPaidAmount')"
        v-model="paidAmount"
        :placeholder="$t('billing.EnterPaidAmount')"
        :icon="['fad', 'money-bill-wave']"
        :unit="$t('general.DKK')"
      />
    </b-modal>
  </McLayoutDefault>
</template>

<script>
import { mapState } from 'vuex'
import { FormatError } from '@/helpers/ErrorFormatting'
import moment from 'moment-timezone'
import BillingService from '@/services/BillingService'
import { niceCurrency } from '@/helpers/Formatting'

export default {
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'invoiceId',
      loadingState: true,
      invoiceData: null,
      merchantId: null,
      paidItem: null,
      paidAmount: 0,
    }
  },
  watch: {
    merchantId() {
      this.refreshTable()
    },
  },
  computed: {
    fields() {
      let retval = []
      retval.push({ key: 'invoiceId', priority: 0, mw: 120, sortable: true, label: this.$t('billing.InvoiceId') })
      retval.push({ key: 'status', priority: 1, mw: 120, sortable: true, label: this.$t('billing.InvoiceStatus') })
      retval.push({ key: 'month', priority: 1, mw: 120, sortable: true, label: this.$t('billing.Month') })
      retval.push({ key: 'duedate', priority: 2, mw: 120, sortable: true, label: this.$t('billing.DueDate') })
      retval.push({ key: 'commissionAmount', priority: 6, mw: 1200, sortable: false, label: this.$t('billing.CommissionAmount'), tdClass: 'text-right' })
      retval.push({ key: 'soldAmount', priority: 7, mw: 120, sortable: false, label: this.$t('billing.SoldAmount'), tdClass: 'text-right' })
      retval.push({ key: 'createdAmount', priority: 8, mw: 120, sortable: false, label: this.$t('billing.CreatedAmount'), tdClass: 'text-right' })
      retval.push({ key: 'payoutAmount', priority: 3, mw: 120, sortable: true, label: this.$t('billing.PayoutAmount'), tdClass: 'text-right' })

      return retval
    },
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
  },
  methods: {
    dataProvider(ctx) {
      return BillingService.query(ctx, this.merchantId)
    },
    refreshTable() {
      if (this.$refs.billingTable) {
        this.$refs.billingTable.refresh()
      }
    },
    addableReminder(item) {
      if (item.status !== 'unpaid' || !item.pastDue) {
        return false
      }
      for (let reminderNum in item.reminderInfo) {
        if (item.reminderInfo[reminderNum].status !== 'unpaid') {
          return false
        }
      }
      if (typeof item.reminderInfo.first === 'undefined') {
        return 'first'
      } else if (typeof item.reminderInfo.second === 'undefined') {
        return 'second'
      } else if (typeof item.reminderInfo.third === 'undefined') {
        return 'third'
      }
      return false
    },
    cancelableReminder(item) {
      if (item.status !== 'unpaid') {
        return false
      } else if (item.reminderInfo.third && item.reminderInfo.third.status === 'unpaid') {
        return 'third'
      } else if (item.reminderInfo.second && item.reminderInfo.second.status === 'unpaid') {
        return 'second'
      }
      return false
    },
    startAddReminder(item) {
      this.$bvModal.msgBoxConfirm(this.$t('billing.ConfirmSendingReminder')).then(value => {
        if (value) {
          BillingService.addReminder(item.invoiceId, this.addableReminder(item))
            .then(() => {
              this.refreshTable()
            })
            .catch(error => {
              this.$bvModal.msgBoxOk(FormatError(error))
            })
        }
      })
    },
    startCancelReminder(item) {
      this.$bvModal.msgBoxConfirm(this.$t('billing.ConfirmCancelReminder')).then(value => {
        if (value) {
          BillingService.cancelReminder(item.invoiceId, this.cancelableReminder(item))
            .then(() => {
              this.refreshTable()
            })
            .catch(error => {
              this.$bvModal.msgBoxOk(FormatError(error))
            })
        }
      })
    },
    markPaid(item) {
      this.paidItem = item
      this.paidAmount = 0
      this.$bvModal.show('markPaidModal')
      setTimeout(() => {
        let el = this.$refs['markpaidAmountInput'].$el.querySelector('input')
        el.focus()
        el.select()
      }, 50)
    },
    doMarkPaid() {
      BillingService.markPaid(this.paidItem.invoiceId, this.paidAmount * 100)
        .then(() => {
          this.refreshTable()
        })
        .catch(error => {
          this.$bvModal.msgBoxOk(FormatError(error))
        })
    },
  },
  filters: {
    paidClass(status) {
      if (status === 'paid') {
        return 'text-success'
      } else if (status === 'unpaid') {
        return 'text-danger'
      } else if (status === 'credited') {
        return 'text-muted text-decoration-line-through'
      }
    },
    feeTotal(reminder) {
      let s = Object.values(reminder.fees).reduce((pv, cv) => {
        return pv + cv
      }, 0)
      return niceCurrency(s)
    },
    niceCurrency(amountInCents) {
      return niceCurrency(amountInCents)
    },
    formatMonth(month) {
      let m = moment(month + '-01')
      return m.format("MMM 'YY")
    },
    formatDuedate(duedate) {
      return moment(duedate).format('ll')
    },
  },
}
</script>
<style lang="scss">
.billing-table {
  tr.credited td {
    opacity: 0.5;
    text-decoration: line-through;
  }
  .credited_wrap {
    position: relative;
    height: 0.7em;
    small {
      position: absolute;
      bottom: 0;
      left: 0.5em;
      color: #d00;
      white-space: nowrap;
    }
  }
}
.text-decoration-line-through {
  text-decoration: line-through;
}
</style>
