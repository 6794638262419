<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    submiticon="plus"
    :title="offline ? $t('orders.CreateOfflineGiftcards') : $t('orders.CreateManualOrder')"
    :submittext="$t('general.Create')"
  >
    <div v-if="offline" v-html="$t('orders.CreateOfflineGiftcardsDescription')" />
    <div v-else v-html="$t('orders.CreateManualOrderDescription')" />

    <MerchantSelector @merchantSelected="refreshCards()" permission="createOrders" v-model="merchantId" />

    <!-- eslint-disable-next-line -->
    <GiftCardListInCreateOrder
      data-parent-card-id="0"
      :cards="cards"
      :merchantId="merchantId"
      :showmoreState="showmoreState"
      @showmore="showmore"
      @selectCard="cardSelected"
      v-if="!selectedCard"
    />

    <div v-else>
      <giftcard-in-create-order-form :card="selectedCard" />

      <div class="text-right" v-if="activeCards.length > 1">
        <mc-button :text="$t('orders.ChangeType')" :icon="['fas', 'undo']" @click="cardSelected(null)" />
      </div>

      <mc-form-input
        name="count"
        type="number"
        min="1"
        max="99"
        v-model="curData.order.count"
        v-validate="'required|numeric'"
        :data-vv-as="$t('orders.Count')"
        :label="$t('orders.Count')"
        :placeholder="$t('orders.ChooseNumberOfGiftcards')"
        key="orderform.count"
        :errorHint="errors.first('count')"
        :state="!errors.has('count')"
        icon="hashtag"
      />

      <div v-if="selectedCard.priceInCents == 0">
        <label v-if="offline" style="margin: 1em auto; display: inline-block"><input type="checkbox" v-model="setAmount" /> {{ $t('orders.SetAmount') }}</label>
        <mc-form-input
          v-if="!offline || setAmount"
          name="amount"
          type="number"
          :min="selectedCard.minFreeAmountInCents / 100"
          :max="selectedCard.maxFreeAmountInCents / 100"
          :step="selectedCard.stepFreeAmountInCents / 100"
          v-model="selectedPrice"
          v-validate="'required|decimal:2|min_value:' + (selectedCard.minFreeAmountInCents / 100) + '|max_value:' + (selectedCard.maxFreeAmountInCents / 100)"
          :data-vv-as="$t('orders.Amount')"
          :label="$t('orders.Amount')"
          :placeholder="$t('orders.ChooseGiftcardAmount')"
          key="orderform.amount"
          :errorHint="errors.first('amount')"
          :state="!errors.has('amount')"
          :icon="['fad', 'money-bill-wave']"
          :unit="$t('general.DKK')"
        />
      </div>

      <div v-if="offline">
        <label style="margin: 1em auto; display: inline-block"><input type="checkbox" v-model="setExpiry" /> {{ $t('orders.SetExpiry') }}</label>
        <transition-to-auto>
          <mcFormDatePicker :label="$t('general.Expiry')" :icon="['fas', 'calendar-check']" v-model="selectedExpiry" v-if="setExpiry" />
        </transition-to-auto>
      </div>

      <div v-if="!offline && selectedCard.supportsPersonalMessage" style="text-align: left">
        <label style="margin: 1em auto; display: inline-block"><input type="checkbox" v-model="addPersonalMessage" /> {{ $t('orders.AddPersonalMessage') }}</label>
        <transition-to-auto>
          <div v-if="addPersonalMessage">
            <textarea
              v-model="curData.order.meta.personalMessage"
              :maxlength="messageCharsMax"
              style="width: 100%; height: 120px; padding: 10px"
              :placeholder="$t('orders.TypePersonalMessage')"
            ></textarea>
            <label v-if="hasEmojis" style="color: #e00; display: block; text-align: left">
              <span>{{ $t('orders.SorryNoEmojis') }}</span>
            </label>
            <label v-else style="display: block; font-size: 0.8em; text-align: right">
              <span>{{ messageCharsLeft }}</span> / <span>{{ messageCharsMax }}</span>
            </label>
          </div>
        </transition-to-auto>
      </div>
    </div>

    <div v-if="!offline">
      <mc-form-input
        name="email"
        type="email"
        v-model="curData.customer.email"
        v-validate="'email'"
        :data-vv-as="$t('general.Email')"
        :label="$t('general.EmailParensOptional')"
        :description="$t('general.GiftCardSentThereIfFilled')"
        :placeholder="$t('general.EnterEmail')"
        key="orderform.email"
        :errorHint="errors.first('email')"
        :state="!errors.has('email')"
        icon="at"
      />

      <mc-form-input
        name="phone"
        type="text"
        v-model="curData.customer.phone"
        v-validate="'numeric'"
        :data-vv-as="$t('general.Phone')"
        :label="$t('general.PhoneParensOptional')"
        :placeholder="$t('general.EnterPhone')"
        key="orderform.phone"
        :errorHint="errors.first('phone')"
        :state="!errors.has('phone')"
        icon="phone"
      />

      <mc-form-checkboxes
        inline
        name="invoiceType"
        v-model="invoiceType"
        :label="$t('general.InvoiceType')"
        key="orderform.invoiceType"
        :errorHint="errors.first('invoiceType')"
        :state="!errors.has('invoiceType')"
        :options="invoiceTypes"
        :icon="['fad', 'file-invoice']"
        :description="$t('general.InvoiceTypeDescription')"
      />

      <TransitionToAuto v-if="invoiceType === 'full'" zoom>
        <mc-form-checkboxes
          inline
          name="type"
          v-model="curData.customer.type"
          :label="$t('general.CustomerType')"
          key="orderform.type"
          :errorHint="errors.first('type')"
          :state="!errors.has('type')"
          :options="customerTypes"
          icon="user"
        />

        <mc-form-input
          name="name"
          type="text"
          v-model="curData.customer.name"
          v-validate="'required'"
          :data-vv-as="$t('general.Name')"
          :label="$t('general.Name')"
          :placeholder="$t('general.EnterName')"
          key="orderform.name"
          :errorHint="errors.first('name')"
          :state="!errors.has('name')"
          icon="signature"
        />

        <TransitionToAuto v-if="curData.customer.type == 'C'" zoom>
          <mc-form-input
            name="company"
            type="text"
            v-model="curData.customer.company"
            v-validate="'required'"
            :data-vv-as="$t('general.Company')"
            :label="$t('general.Company')"
            :placeholder="$t('general.EnterCompany')"
            key="orderform.company"
            :errorHint="errors.first('company')"
            :state="!errors.has('company')"
            icon="building"
          />

          <mc-form-input
            name="cvr"
            type="text"
            v-validate="'required|numeric'"
            :data-vv-as="$t('general.Cvr')"
            :label="$t('general.Cvr')"
            v-model="curData.customer.cvr"
            :placeholder="$t('general.EnterCvr')"
            key="orderform.cvr"
            :errorHint="errors.first('cvr')"
            :state="!errors.has('cvr')"
            :icon="['fad', 'id-card-alt']"
          />
        </TransitionToAuto>

        <mc-form-input
          name="address1"
          type="text"
          v-model="curData.customer.address1"
          v-validate="'required'"
          :data-vv-as="$t('general.Address1')"
          :label="$t('general.Address1')"
          :placeholder="$t('general.EnterAddress1')"
          key="orderform.address1"
          :errorHint="errors.first('address1')"
          :state="!errors.has('address1')"
          icon="map-marker"
        />

        <mc-form-input
          name="address2"
          type="text"
          v-model="curData.customer.address2"
          :placeholder="$t('general.EnterAddress2')"
          :data-vv-as="$t('general.Address2')"
          :label="$t('general.Address2')"
          key="orderform.address2"
          :errorHint="errors.first('address2')"
          :state="!errors.has('address2')"
          icon="map-marker"
        />

        <!-- eslint-disable-next-line -->
        <mcFormDualInputs
          :errorHint="errors.first('zip') || errors.first('city')"
          :state="!errors.has('zip') && !errors.has('city')"
        >
          <template v-slot:input1>
            <!-- eslint-disable-next-line -->
            <mc-form-input
              icon="city"
              name="zip"
              type="text"
              v-validate="'required|numeric'"
              :data-vv-as="$t('general.Zip')"
              :label="$t('general.Zip')"
              v-model="curData.customer.zip"
              :placeholder="$t('general.EnterZip')"
              key="orderform.zip"
            />
          </template>
          <template v-slot:input2>
            <!-- eslint-disable-next-line -->
            <mc-form-input
              icon="city"
              name="city"
              type="text"
              v-validate="'required'"
              :data-vv-as="$t('general.City')"
              :label="$t('general.City')"
              v-model="curData.customer.city"
              :placeholder="$t('general.EnterCity')"
              key="orderform.city"
            />
          </template>
        </mcFormDualInputs>

        <!--mc-form-input
          name="country"
          type="text"
          v-validate="'required'"
          v-model="curData.customer.country"
          :placeholder="$t('general.EnterCountry')"
          :data-vv-as="$t('general.Country')"
          :label="$t('general.Country')"
          key="orderform.country"
          :errorHint="errors.first('country')"
          :state="!errors.has('country')"
          :icon="['fad', 'globe-europe']"
        /-->
      </TransitionToAuto>

      <mc-form-checkbox name="acceptTerms" v-model="acceptTerms" :description="$t('orders.CommissionDescription')">{{ $t('orders.acceptTerms') }}</mc-form-checkbox>
    </div>
    <!--pre>{{ curData }}, {{ selectedPrice }}</pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import OrderService from '@/services/OrderService'
import GiftcardService from '@/services/GiftcardService'
import OfflineGiftcardsService from '@/services/OfflineGiftcardsService'

const defaultData = {
  order: {
    count: 1,
    priceInCents: 40000,
    giftcardId: null,
    meta: {
      personalMessage: '',
    },
  },
  customer: {
    email: '',
    phone: '',
    type: 'I',
    name: '',
    company: '',
    cvr: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    country: 'Denmark',
  },
}

export default {
  data() {
    return {
      curData: { ...defaultData },
      acceptTerms: false,
      selectedCard: null,
      invoiceType: 'none',
      addPersonalMessage: false,
      setAmount: false,
      setExpiry: false,
      cards: [],
      merchantId: null,
      showmoreState: [],
      selectedPrice: 0,
      selectedExpiry: moment().add(730, 'days').format('YYYY-MM-DD'),
      messageCharsMax: 350,
    }
  },
  mounted() {
    this.curData = { ...defaultData }
  },
  computed: {
    messageCharsLeft() {
      return this.messageCharsMax - this.curData.order.meta.personalMessage.length
    },
    hasEmojis() {
      const regex_emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
      return regex_emoji.test(this.curData.order.meta.personalMessage);
    },
    customerTypes() {
      return [
        { value: 'I', label: this.$t('general.ContactTypeIndividual') },
        { value: 'C', label: this.$t('general.ContactTypeCompany') },
      ]
    },
    invoiceTypes() {
      return [
        { value: 'none', label: this.$t('general.InvoiceTypeNone') },
        { value: 'simple', label: this.$t('general.InvoiceTypeSimple') },
        { value: 'full', label: this.$t('general.InvoiceTypeFull') },
      ]
    },
    activeCards() {
      let activeCards = []
      for (var i in this.cards) {
        if (this.cards[i].published) {
          activeCards.push(this.cards[i])
        }
      }
      return activeCards
    },
  },
  props: {
    offline: Boolean,
  },
  watch: {
    merchantId() {
      this.selectedCard = null
      this.curData.order.giftcardId = 0
      this.refreshCards()
    },
    selectedPrice() {
      this.curData.order.priceInCents = Math.round(this.selectedPrice * 100)
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    showmore(e) {
      this.$set(this.showmoreState, e, (!this.showmoreState[e] || this.showmoreState[e] == false))
    },
    cardSelected(card) {
      if (card === null) {
        this.selectedCard = null
        this.curData.order.giftcardId = 0
        this.curData.order.priceInCents = 0
        this.selectedExpiry = moment().add(730, 'days').format('YYYY-MM-DD')
        this.selectedPrice = 0
      } else {
        this.selectedCard = card
        this.curData.order.giftcardId = card.id
        this.curData.order.priceInCents = card.priceInCents
        if (this.selectedCard.validity == -1) {
          this.selectedExpiry = moment(this.selectedCard.staticValidity).format('YYYY-MM-DD')
        } else {
          this.selectedExpiry = moment().add(this.selectedCard.validity, 'days').format('YYYY-MM-DD')
        }
        if (card.priceInCents == 0) {
          this.curData.order.priceInCents = card.defFreeAmountInCents
          this.selectedPrice = card.defFreeAmountInCents / 100
        }
      }
    },
    refreshCards() {
      if (!this.merchantId) {
        this.cards = []
        return
      }
      GiftcardService.get(this.merchantId).then(
        data => {
          this.cards = data
          if (this.activeCards.length == 1) {
            this.cardSelected(this.activeCards[0])
          }
        },
        error => {
          console.error('Error getting gift cards', error)
          return Promise.reject(error)
        },
      )
    },

    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    forceReset() {
      this.$refs['mcFormInstance'].forceReset()
      this.cardSelected(null)
      this.refreshCards()
    },

    handleReset() {
      // Only reset order, not customer data
      this.curData.order = { ...defaultData.order }
    },

    handleSubmit(e) {
      if (!this.selectedCard) {
        return Promise.reject(this.$t('orders.YouNeedToChooseTheType'))
      }
      if (this.offline) {
        const order = {
          items: [
            {
              giftcardId: this.curData.order.giftcardId,
              count: this.curData.order.count,
            },
          ],
        }
        if (this.selectedCard.priceInCents == 0 && this.setAmount) {
          order.items[0].priceInCents = this.curData.order.priceInCents
        }
        if (this.setExpiry) {
          order.items[0].dateExpires = this.selectedExpiry
        }
        return OfflineGiftcardsService.create(this.merchantId, order).then(
          data => {
            this.$emit('offlineGiftcardsCreated', data)
            return Promise.resolve(data)
          },
          error => {
            console.error('Error creating offline giftcard(s)', error)
            return Promise.reject(error)
          },
        )
      } else {
        if (!this.acceptTerms) {
          return Promise.reject(this.$t('orders.YouNeedToAcceptTheTerms'))
        }
        if (this.hasEmojis) {
          return Promise.reject(this.$t('orders.YouCannotUseEmojisInPersonalMessage'))
        }
        const order = {
          items: [this.curData.order],
          invoiceType: this.invoiceType,
          customer: this.curData.customer,
        }
        return OrderService.createOrder(this.merchantId, order).then(
          data => {
            this.$emit('orderCreated', data)
            return Promise.resolve(data)
          },
          error => {
            console.error('Error creating order', error)
            return Promise.reject(error)
          },
        )
      }
    },
  },
}
</script>
