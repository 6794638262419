<template>
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'save' : 'plus'"
    :title="isEditing ? $t('general.EditMerchantName', { name: curData.niceName }) : $t('general.AddMerchant')"
    :submittext="isEditing ? $t('general.Save') : $t('general.Create')"
  >
    <mc-form-select
      v-if="$perms.isAdministrator()"
      name="administratorUserId"
      v-model="curData.userId"
      :data-vv-as="$t('general.AdminUser')"
      :label="$t('general.AdminUser')"
      :placeholder="$t('general.ChooseAdminUser')"
      key="merchantform.userId"
      :errorHint="errors.first('userId')"
      :state="!errors.has('userId')"
      :options="userList"
      :icon="['fad', 'user-cog']"
    />

    <mc-form-select
      v-if="$perms.isAdministrator()"
      name="status"
      v-model="curData.status"
      :label="$t('general.MerchantStatus')"
      :placeholder="$t('general.ChooseStatus')"
      key="merchantform.status"
      :options="statusList"
      :icon="['fad', 'user-cog']"
    />

    <h4>{{ $t('general.ContactPersonForDg') }}</h4>
    <mc-form-input
      name="name"
      type="text"
      v-model="curData.name"
      v-validate="'required'"
      :data-vv-as="$t('general.ContactName')"
      :label="$t('general.ContactName')"
      :placeholder="$t('general.EnterContactName')"
      key="merchantform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
    />

    <mc-form-input
      name="contactEmail"
      type="email"
      v-model="curData.contactEmail"
      v-validate="'required|email'"
      :data-vv-as="$t('general.ContactEmail')"
      :label="$t('general.ContactEmail')"
      :placeholder="$t('general.EnterEmail')"
      key="merchantform.contactEmail"
      :errorHint="errors.first('contactEmail')"
      :state="!errors.has('contactEmail')"
      icon="at"
    />

    <mc-form-input
      name="contactPhone"
      type="text"
      v-model="curData.contactPhone"
      v-validate="'required|numeric'"
      :data-vv-as="$t('general.ContactPhone')"
      :label="$t('general.ContactPhone')"
      :placeholder="$t('general.EnterPhone')"
      key="merchantform.contactPhone"
      :errorHint="errors.first('contactPhone')"
      :state="!errors.has('contactPhone')"
      icon="phone"
    />

    <h4>{{ $t('general.CompanyAddress') }}</h4>
    <mc-form-input
      name="company"
      type="text"
      v-model="curData.company"
      v-validate="'required'"
      :data-vv-as="$t('general.Company')"
      :label="$t('general.Company')"
      :placeholder="$t('general.EnterCompany')"
      key="merchantform.company"
      :errorHint="errors.first('company')"
      :state="!errors.has('company')"
      icon="building"
      :description="$t('general.CompanyDescription')"
    >
      <template slot="append" v-if="$perms.isAdministrator()">
        <mc-button variant="success" :href="'https://datacvr.virk.dk/soegeresultater?fritekst=' + curData.company + '&sideIndex=0&size=10'" target="_blank" :icon="['fas', 'search']" text="CVR" />
      </template>
    </mc-form-input>

    <mc-form-input
      name="cvr"
      type="text"
      v-validate="'required|numeric'"
      :data-vv-as="$t('general.Cvr')"
      :label="$t('general.Cvr')"
      v-model="curData.cvr"
      :placeholder="$t('general.EnterCvr')"
      key="merchantform.cvr"
      :errorHint="errors.first('cvr')"
      :state="!errors.has('cvr')"
      :icon="['fad', 'id-card-alt']"
    >
      <template slot="append" v-if="$perms.isAdministrator()">
        <mc-button @click="checkVat" variant="success" target="_blank" :icon="['fas', 'search']" text="MOMS" />
      </template>
    </mc-form-input>

    <mc-form-input
      name="address1"
      type="text"
      v-model="curData.address1"
      v-validate="'required'"
      :data-vv-as="$t('general.Address1')"
      :label="$t('general.Address1')"
      :placeholder="$t('general.EnterAddress1')"
      key="merchantform.address1"
      :errorHint="errors.first('address1')"
      :state="!errors.has('address1')"
      icon="map-marker"
    />

    <mc-form-input
      name="address2"
      type="text"
      v-model="curData.address2"
      :placeholder="$t('general.EnterAddress2')"
      :data-vv-as="$t('general.Address2')"
      :label="$t('general.Address2')"
      key="merchantform.address2"
      :errorHint="errors.first('address2')"
      :state="!errors.has('address2')"
      icon="map-marker"
    />

    <!-- eslint-disable-next-line -->
    <mcFormDualInputs
      :errorHint="errors.first('zip') || errors.first('city')"
      :state="!errors.has('zip') && !errors.has('city')"
    >
      <template v-slot:input1>
        <!-- eslint-disable-next-line -->
        <mc-form-input
          icon="city"
          name="zip"
          type="text"
          v-validate="'required|numeric'"
          :data-vv-as="$t('general.Zip')"
          :label="$t('general.Zip')"
          v-model="curData.zip"
          :placeholder="$t('general.EnterZip')"
          key="merchantform.zip"
        />
      </template>
      <template v-slot:input2>
        <!-- eslint-disable-next-line -->
        <mc-form-input
          icon="city"
          name="city"
          type="text"
          v-validate="'required'"
          :data-vv-as="$t('general.City')"
          :label="$t('general.City')"
          v-model="curData.city"
          :placeholder="$t('general.EnterCity')"
          key="merchantform.city"
        />
      </template>
    </mcFormDualInputs>

    <mc-form-input
      name="country"
      type="text"
      v-validate="'required'"
      v-model="curData.country"
      :placeholder="$t('general.EnterCountry')"
      :data-vv-as="$t('general.Country')"
      :label="$t('general.Country')"
      key="merchantform.country"
      :errorHint="errors.first('country')"
      :state="!errors.has('country')"
      :icon="['fad', 'globe-europe']"
    />

    <h4>{{ $t('general.ContactForCustomers') }}</h4>

    <mc-form-input
      name="email"
      type="email"
      v-model="curData.email"
      v-validate="'required|email'"
      :data-vv-as="$t('general.Email')"
      :label="$t('general.Email')"
      :placeholder="$t('general.EnterEmail')"
      key="merchantform.email"
      :errorHint="errors.first('email')"
      :state="!errors.has('email')"
      icon="at"
    />

    <mc-form-input
      name="phone"
      type="text"
      v-model="curData.phone"
      v-validate="'required|numeric'"
      :data-vv-as="$t('general.Phone')"
      :label="$t('general.Phone')"
      :placeholder="$t('general.EnterPhone')"
      key="merchantform.phone"
      :errorHint="errors.first('phone')"
      :state="!errors.has('phone')"
      icon="phone"
    />

    <mc-form-input
      name="website"
      type="url"
      v-model="curData.website"
      v-validate="'required'"
      :data-vv-as="$t('general.Website')"
      :label="$t('general.Website')"
      :placeholder="$t('general.EnterWebsite')"
      key="merchantform.website"
      :errorHint="errors.first('website')"
      :state="!errors.has('website')"
      :icon="['fad', 'browser']"
    />
    <h4>{{ $t('general.PayoutInfo') }}</h4>
    <p>{{ $t('general.PayoutDescription') }}</p>
    <!-- eslint-disable-next-line -->
    <mcFormDualInputs
      :errorHint="errors.first('regNo') || errors.first('accountNo')"
      :state="!errors.has('regNo') && !errors.has('accountNo')"
    >
      <template v-slot:input1>
        <!-- eslint-disable-next-line -->
        <mc-form-input
          icon="hashtag"
          name="regNo"
          type="text"
          v-validate="'required|numeric'"
          :data-vv-as="$t('general.RegNo')"
          :label="$t('general.RegNo')"
          v-model="curData.regNo"
          :placeholder="$t('general.EnterRegNo')"
          key="merchantform.regNo"
        />
      </template>
      <template v-slot:input2>
        <!-- eslint-disable-next-line -->
        <mc-form-input
          icon="hashtag"
          name="accountNo"
          type="text"
          v-validate="'required|numeric'"
          :data-vv-as="$t('general.AccountNo')"
          :label="$t('general.AccountNo')"
          v-model="curData.accountNo"
          :placeholder="$t('general.EnterAccountNo')"
          key="merchantform.accountNo"
        />
      </template>
    </mcFormDualInputs>

    <mc-form-input
      name="orderCopyEmail"
      type="email"
      v-model="curData.orderCopyEmail"
      v-validate="'email'"
      :data-vv-as="$t('general.OrderCopies')"
      :label="$t('general.OrderCopies')"
      :description="$t('general.OrderCopiesDescription')"
      :placeholder="$t('general.EnterEmail')"
      key="merchantform.orderCopyEmail"
      :errorHint="errors.first('orderCopyEmail')"
      :state="!errors.has('orderCopyEmail')"
      icon="at"
    />

    <mc-form-input
      name="invoiceEmail"
      type="email"
      v-model="curData.invoiceEmail"
      v-validate="'email'"
      :data-vv-as="$t('general.InvoiceEmail')"
      :label="$t('general.InvoiceEmail')"
      :description="$t('general.InvoiceEmailDescription')"
      :placeholder="$t('general.EnterEmail')"
      key="merchantform.invoiceEmail"
      :errorHint="errors.first('invoiceEmail')"
      :state="!errors.has('invoiceEmail')"
      icon="at"
    />

    <mc-form-input
      :icon="['fad', 'greater-than-equal']"
      name="minAmount"
      type="number"
      min="0"
      max="2000"
      step="5"
      v-validate="'required|numeric'"
      :data-vv-as="$t('general.MinimumAmount')"
      :label="$t('general.MinimumAmount')"
      v-model="curData.minAmount"
      :placeholder="$t('general.EnterMinimumAmount')"
      :errorHint="errors.first('minAmount')"
      :state="!errors.has('minAmount')"
      key="merchantform.minAmount"
      :disabled="!$perms.isAdministrator()"
    />

    <mc-form-input
      :icon="['fad', 'badge-percent']"
      name="vatPercent"
      type="number"
      min="0"
      max="50"
      step="0.5"
      v-validate="'required|decimal:1'"
      :data-vv-as="$t('general.VatPercent')"
      :label="$t('general.VatPercent')"
      v-model="curData.vatPercent"
      :placeholder="$t('general.EnterVatPercent')"
      :errorHint="errors.first('vatPercent')"
      :state="!errors.has('vatPercent')"
      key="merchantform.vatPercent"
      :disabled="!$perms.isAdministrator()"
      :description="$t('general.VatPercentDescription')"
    />

    <mc-form-input
      :icon="['fad', 'badge-percent']"
      name="commission"
      type="number"
      min="0"
      max="20"
      step="0.1"
      v-validate="'required|decimal:1'"
      :data-vv-as="$t('general.CommissionWebshop')"
      :label="$t('general.CommissionWebshop')"
      v-model="curData.commission"
      :placeholder="$t('general.EnterCommission')"
      :errorHint="errors.first('commission')"
      :state="!errors.has('commission')"
      key="merchantform.commission"
      v-if="$perms.isAdministrator()"
    />

    <mc-form-input
      :icon="['fad', 'badge-percent']"
      name="commissionManual"
      type="number"
      min="0"
      max="20"
      step="0.1"
      v-validate="'required|decimal:1'"
      :data-vv-as="$t('general.CommissionManual')"
      :label="$t('general.CommissionManual')"
      v-model="curData.commissionManual"
      :placeholder="$t('general.EnterCommission')"
      :errorHint="errors.first('commissionManual')"
      :state="!errors.has('commissionManual')"
      key="merchantform.commissionManual"
      v-if="$perms.isAdministrator()"
    />

    <mc-form-input
      :icon="['fad', 'file-contract']"
      name="commissionAgreement"
      type="textarea"
      :label="$t('general.CommissionAgreement')"
      v-model="curData.commissionAgreement"
      key="merchantform.commissionAgreement"
      v-if="$perms.isAdministrator()"
      :placeholder="$t('general.OptionalDefaultColon', { default: curData.commission == curData.commissionManual ? 'Provision ' + curData.commission + '% jf. aftale' : 'Provision ' + curData.commissionManual + '% eller ' + curData.commission + '% jf. aftale' })"
    />

    <div v-if="$perms.isAdministrator()">
      <h4>{{ $t('general.Reseller') }}</h4>
      <mc-form-select
        name="resellerMerchantId"
        v-model="curData.resellerMerchantId"
        :data-vv-as="$t('general.ResellerMerchant')"
        :label="$t('general.ResellerMerchant')"
        :placeholder="$t('general.ChooseResellerMerchant')"
        key="merchantform.resellerMerchantId"
        :errorHint="errors.first('resellerMerchantId')"
        :state="!errors.has('resellerMerchantId')"
        :options="merchantListReseller"
        :icon="['fad', 'user-cog']"
      />

      <mc-form-input
        v-if="curData.resellerMerchantId"
        :icon="['fad', 'badge-percent']"
        name="resellerCommission"
        type="number"
        min="0"
        max="20"
        step="0.1"
        v-validate="'required|decimal:1'"
        :data-vv-as="$t('general.ResellerCommission')"
        :label="$t('general.ResellerCommission')"
        v-model="curData.resellerCommission"
        :placeholder="$t('general.EnterCommission')"
        :errorHint="errors.first('resellerCommission')"
        :state="!errors.has('resellerCommission')"
        key="merchantform.resellerCommission"
      />
    </div>

    <h4>{{ $t('general.CompanyIdentity') }}</h4>
    <p>{{ $t('general.LogoRecommendations') }}</p>
    <p>{{ $t('general.LogoRecommendations2') }}</p>
    <McFormImage
      only-my-own
      name="file"
      :label="$t('general.LogoLightBg')"
      :placeholder="$t('general.PickLogo')"
      icon="image"
      v-model="curData.logoDark"
      :description="$t('general.PickLogoLightBgDescription')"
      variant="light"
    />

    <McFormImage
      only-my-own
      name="file"
      :label="$t('general.LogoDarkBg')"
      :placeholder="$t('general.PickLogo')"
      icon="image"
      v-model="curData.logoLight"
      :description="$t('general.PickLogoDarkBgDescription')"
      variant="dark"
    />

    <mc-form-input
      name="niceName"
      type="text"
      v-model="curData.niceName"
      v-validate="'required'"
      :placeholder="$t('general.EnterNiceName')"
      :data-vv-as="$t('general.NiceName')"
      :label="$t('general.NiceName')"
      key="merchantform.nicename"
      :errorHint="errors.first('niceName')"
      :state="!errors.has('niceName')"
      :icon="['fad', 'file-signature']"
      :description="$t('general.NiceNameDescription')"
    />

    <mc-form-input
      name="prefix"
      type="text"
      v-model="curData.prefix"
      v-validate="{ required: true, min: 3, max: 6, regex: /^[ÆØÅA-Z](?:[ÆØÅA-Z\-_]+)[ÆØÅA-Z]$/ }"
      :data-vv-as="$t('general.Prefix')"
      :label="$t('general.Prefix')"
      :placeholder="$t('general.EnterPrefix')"
      key="merchantform.prefix"
      :errorHint="errors.first('prefix')"
      :state="!errors.has('prefix')"
      icon="hashtag"
      :description="$t('general.PrefixDescription')"
      :disabled="isEditing"
      @input="val => (curData.prefix = curData.prefix.toUpperCase())"
    />

    <mc-form-input
      name="codeFormat"
      type="text"
      prepend="gd-"
      v-model="curData.codeFormat"
      v-validate="{ required: true, min: 6, max: 24, regex: /^([A-Z0-9][-_]?){5,15}[A-Z0-9]$/ }"
      :data-vv-as="$t('general.CodeFormat')"
      :label="$t('general.CodeFormat')"
      :placeholder="$t('general.EnterCodeFormat')"
      key="merchantform.codeFormat"
      :errorHint="errors.first('codeFormat')"
      :state="!errors.has('codeFormat')"
      :description="$t('general.CodeFormatDescription', { example1: exampleCode1, example2: exampleCode2, unique: uniquePossibilities })"
      :disabled="!$perms.isAdministrator()"
      @input="val => (curData.codeFormat = curData.codeFormat.toUpperCase())"
    >
      <template slot="prepend">
        <b-input-group-text class="pr-1">{{ curData.prefix }}-</b-input-group-text>
      </template>
    </mc-form-input>

    <h4>{{ $t('general.IntegrationSecurityEtc') }}</h4>
    <mc-form-input
      name="canonical"
      type="url"
      v-model="curData.canonical"
      v-validate="'url'"
      :data-vv-as="$t('general.Canonical')"
      :label="$t('general.Canonical')"
      :placeholder="$t('general.EnterCanonical')"
      key="merchantform.canonical"
      :errorHint="errors.first('canonical')"
      :state="!errors.has('canonical')"
      :icon="['fad', 'browser']"
      :description="$t('general.CanonicalDescription')"
    />

    <mc-form-checkbox v-model="useSpecialBalanceUrl" :description="$t('general.UseSpecialBalanceUrlDescription')">{{ $t('general.UseSpecialBalanceUrl') }}</mc-form-checkbox>

    <transitionToAuto v-if="useSpecialBalanceUrl" zoom>
      <mc-form-input
        name="balanceUrl"
        type="url"
        v-model="curData.balanceUrl"
        v-validate="'url'"
        :data-vv-as="$t('general.BalanceUrl')"
        :label="$t('general.BalanceUrl')"
        :placeholder="$t('general.EnterBalanceUrl')"
        key="merchantform.balanceUrl"
        :errorHint="errors.first('balanceUrl')"
        :state="!errors.has('balanceUrl')"
        :icon="['fad', 'browser']"
      />
    </transitionToAuto>
    <!--mc-form-checkboxes v-if="$perms.isAdministrator()" :options="permOptions" multiple :icon="['fas', 'user']" v-model="curData.perms" /-->

    <mc-form-checkbox v-model="curData.requireFullCodes" :description="$t('general.RequireFullCodesDescription')">{{ $t('general.RequireFullCodes') }}</mc-form-checkbox>

    <McFormSelect
      :label="$t('general.LateRedeem')"
      :icon="['fad', 'calendar-exclamation']"
      v-model="curData.lateRedeem"
      :options="[
        { label: $t('general.LateRedeemNo'), value: 0 },
        { label: $t('general.LateRedeem14days'), value: 14 },
        { label: $t('general.LateRedeem30days'), value: 30 },
        { label: $t('general.LateRedeem90days'), value: 90 },
        { label: $t('general.LateRedeem180days'), value: 180 },
        { label: $t('general.LateRedeem365days'), value: 365 },
        { label: $t('general.LateRedeemNoLimit'), value: -1 },
      ]"
      :description="$t('general.LateRedeemDescription')"
    />
  </McForm>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserService from '@/services/UserService'
import { FormatError } from '@/helpers/ErrorFormatting'
import { isEmptyObject } from '@/helpers/Misc'
import MerchantService from '@/services/MerchantService'
import store from '@/setup/Store'

const defaultData = {
  id: 0,
  userId: 0,
  resellerMerchantId: 0,
  commission: 10,
  commissionManual: 5,
  resellerCommission: 10,
  vatPercent: 25,
  minAmount: 250,
  commissionAgreement: '',
  regNo: '',
  accountNo: '',
  orderCopyEmail: '',
  invoiceEmail: '',
  name: store.state.LoginStore.user ? store.state.LoginStore.user.name : '',
  contactEmail: store.state.LoginStore.email ? store.state.LoginStore.user.email : '',
  contactPhone: '',
  company: '',
  niceName: '',
  status: 'active',
  prefix: '',
  codeFormat: '123456',
  cvr: '',
  address1: '',
  address2: '',
  zip: '',
  city: '',
  country: 'Danmark',
  email: store.state.LoginStore.user ? store.state.LoginStore.user.email : '',
  phone: '',
  website: '',
  canonical: '',
  balanceUrl: '',
  logoLight: null,
  logoDark: null,
  requireFullCodes: false,
  lateRedeem: -1,
  perms: {},
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      loadingState: true,
      userList: [],
      merchantListReseller: [],
      statusList: [
        { value: 'demo', label: this.$t('general.merchantStatuses.demo') },
        { value: 'active', label: this.$t('general.merchantStatuses.active') },
        { value: 'nosales', label: this.$t('general.merchantStatuses.nosales') },
      ],
      isEditing: false,
      useSpecialBalanceUrl: false,
    }
  },
  props: {
    value: Object,
  },
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
    exampleCode1() {
      let prefix = this.curData.prefix + '-'
      return prefix + this.curData.codeFormat.replace(/[A-Z]/g, c => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[Math.floor(Math.random() * 26)]).replace(/[0-9]/g, c => '0123456789'[Math.floor(Math.random() * 10)])
    },
    exampleCode2() {
      let prefix = this.curData.prefix + '-'
      return prefix + this.curData.codeFormat.replace(/[A-Z]/g, c => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[Math.floor(Math.random() * 26)]).replace(/[0-9]/g, c => '0123456789'[Math.floor(Math.random() * 10)])
    },
    uniquePossibilities() {
      let chars = this.curData.codeFormat.replace(/[^A-Z]/g, '').length
      let nums = this.curData.codeFormat.replace(/[^0-9]/g, '').length
      return new Intl.NumberFormat().format(Math.pow(26, chars) * Math.pow(10, nums))
    },
    permOptions() {
      return [
        {
          label: 'testtest',
          value: 'test',
        },
        {
          label: 'testtest2',
          value: 'test2',
        },
      ]
    },
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.curData = newVal

      this.useSpecialBalanceUrl = this.curData.balanceUrl !== ''
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    checkVat() {
      let form = document.createElement('form')
      form.innerHTML =
        '<input type="text" name="sogCVRSENummer" value="CVRSENummer1"><input type="text" name="virksomhedCVRNummer.cvrNummer" value="' +
        this.curData.cvr +
        '"><input type="text" name="_eventId_sogDKMomsNumreEvent" value="Søg"><input type="submit">'
      form.action = 'https://ntse.skat.dk/ntse-front/public/momsnummer/soeg?execution=e1s1'
      form.target = '_blank'
      form.method = 'POST'
      document.body.append(form)
      form.submit()
      document.body.removeChild(form)
    },

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      let action = MerchantService.create
      if (this.isEditing) {
        action = MerchantService.update
      }
      let postData = { ...this.curData }
      if (!this.useSpecialBalanceUrl) {
        postData.balanceUrl = ''
      }
      return action(postData).then(
        data => {
          this.toastSuccess(this.$t('general.ChangesSaved'))
          store.commit('LoginStore/loginSuccess', data.newUserInfo)
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating merchant', error)
          return Promise.reject(error)
        },
      )
    },
  },

  mounted() {
    if (!this.$perms.isAdministrator()) {
      this.loadingState = false
      return
    }
    UserService.list()
      .then(data => {
        //console.log(data)
        var theList = [{ value: 0, label: this.$t('general.ChooseAdminUser') }]
        for (var n in data) {
          if (data[n].role == 'user') {
            theList.push({ value: data[n].id, label: data[n].label })
          }
        }
        this.userList = theList
        this.loadingState = false
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
    var theList = []
    for (let id in this.LoginStore.merchantInfo) {
      theList.push({ value: this.LoginStore.merchantInfo[id].id, label: this.LoginStore.merchantInfo[id].niceName })
    }
    theList.sort((a, b) => {
      return a.label == b.label ? 0 : a.label > b.label ? 1 : -1
    })
    theList.unshift({ value: 0, label: this.$t('general.ChooseResellerMerchant') })
    this.merchantListReseller = theList
  },
}
</script>
