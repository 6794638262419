import { i18n } from '@/setup/i18n'

export const FormatError = error => {
  // Make sure we have something that resembles a throw'n error
  if (typeof error === 'string') error = { message: error }
  if (error.message) {
    // If error looks like a translation path
    if (error.message.match(/^([a-zA-Z0-9]*\.)*([a-zA-Z0-9]*)$/)) {
      // Check if a translation exists in the error namespace
      if (i18n.te('apiErrors.' + error.message)) {
        return i18n.t('apiErrors.' + error.message)
      } else if (i18n.te('error.' + error.message)) {
        return i18n.t('error.' + error.message)
      }
      //If not, just translate the raw error path (it will get reported if it isn't translated)
      return i18n.t(error.message)
    } else {
      // If not a translation path, just use raw error (it might be a network error or similar)
      return error.message
    }
  } else {
    console.log('Malformed error', error)
    return i18n.t('error.Unexpected')
  }
}
