import AuthenticationService from '@/services/AuthenticationService'

import VueCookies from 'vue-cookies'

const token = VueCookies.get('token')

let state = {
  loggingIn: false,
  user: null,
  owns: [],
  permissions: null,
  isDeveloper: false,
  merchantInfo: {},
  token: token,
  pendingEmailChange: null,
}

if (token) {
  try {
    const stateStr = localStorage.getItem('loginstate')
    const loadedStateData = JSON.parse(stateStr)
    for (var field in state) {
      if (loadedStateData[field]) {
        state[field] = loadedStateData[field]
      }
    }
  } catch (e) {
    //
  }
}

const actions = {
  login({ commit }, { email, password, remember }) {
    commit('loginRequest', { email })

    //The "Remember" setting is stored in localstorage as
    //we may receive a new token at any point...
    localStorage.setItem('remember', remember ? 1 : 0)

    return new Promise((resolve, reject) => {
      AuthenticationService.login(email, password).then(
        data => {
          commit('loginSuccess', data.userInfo)
          resolve(data)
        },
        error => {
          commit('loginFailure', error)
          reject(error)
        },
      )
    })
  },
  logout({ commit }) {
    commit('logout')
  },
}

const mutations = {
  loginRequest(state) {
    state.loggingIn = true
  },
  loginSuccess(state, data) {
    state.loggingIn = false
    state.user = data.user
    state.permissions = data.permissions
    state.isDeveloper = data.isDeveloper
    state.owns = data.owns
    state.merchantInfo = data.merchantInfo
    state.pendingEmailChange = data.pendingEmailChange
    localStorage.setItem('loginstate', JSON.stringify(state))
  },
  loginFailure(state) {
    state.loggingIn = false
    state.user = null
    state.permissions = null
  },
  logout(state) {
    state.loggingIn = false
    state.user = null
    state.permissions = null
    VueCookies.remove('token')
    localStorage.removeItem('user')
    localStorage.removeItem('permissions')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
