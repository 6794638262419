import { authorizedApiRequest, queryParameters } from '@/setup/ApiService'

export default {
  query: function (params, merchantId) {
    let newParams = { ...params }
    let url = '/offline-giftcards?' + queryParameters(newParams)
    if (merchantId) {
      url = '/offline-giftcards/' + merchantId + '?' + queryParameters(newParams)
    }
    return authorizedApiRequest(
      url, //
      'GET',
    )
  },

  create: function (merchantId, orderData) {
    let url = '/offline-giftcards/' + merchantId + '/create'
    return authorizedApiRequest(
      url, //
      'POST',
      orderData,
    )
  },

  export: function (merchantId, sections) {
    let url = '/offline-giftcards/' + merchantId + '/export'
    return authorizedApiRequest(
      url, //
      'POST',
      {
        sections,
      },
    )
  },

  delete: function (id) {
    return authorizedApiRequest(
      `/offline-giftcards/${id}`, //
      'DELETE',
    )
  },

  finalize: function (id, orderData) {
    return authorizedApiRequest(
      `/offline-giftcards/${id}/finalize`, //
      'POST',
      orderData,
    )
  },
}
