<template>
  <div v-if="!this.deleted" :data-delivery-method-id="deliveryMethod.id" :class="'delivery-method-listitem-wrapper card mb-2 ' + (deliveryMethod.published ? 'published' : 'draft')">
    <div class="card-body py-0 pr-2">
      <div class="my-2 clearfix">
        <div class="clearfix">
          <div class="item-cols">
            <div class="item-col item-preview">
              <div class="item-image" v-if="deliveryMethod.image.url" :style="deliveryMethod.image.url ? 'background-image: url(' + deliveryMethod.image.url + '/240x180)' : ''"></div>
            </div>
            <div class="item-col item-preview px-2 limitheight">
              <p>
                <b-badge v-if="deliveryMethod.priceInCentsPerItem > 0" class="float-right m-1">{{ displayPricePerItem }}</b-badge>
                <b-badge v-if="deliveryMethod.priceInCentsPerOrder > 0" class="float-right m-1">{{ displayPricePerOrder }}</b-badge>
                <b-badge v-if="deliveryMethod.priceInCentsPerOrder == 0 && deliveryMethod.priceInCentsPerItem == 0" class="float-right m-1">{{ $t('general.Free') }}</b-badge>
                <strong v-html="deliveryMethod.headline"></strong>
              </p>
              <div style="font-size: 0.8em" v-html="deliveryMethod.description"></div>
            </div>
            <div class="item-col text-right">
              <label style="display: block">
                {{ deliveryMethod.published ? $t('deliveryMethods.Published') : $t('deliveryMethods.Draft') }}
                <b-form-checkbox variant="danger" v-model="checked" switch style="display: inline-block" />
              </label>
              <b-dropdown :disabled="busy" right :text="$t('deliveryMethods.Actions')" variant="outline-primary" class="mt-2">
                <b-dropdown-item href="#" @click="$emit('edit-delivery-method', deliveryMethod)"><McIcon icon="edit" />{{ $t('deliveryMethods.Edit') }} </b-dropdown-item>
                <b-dropdown-item href="#" @click="cloneDeliveryMethod"><McIcon icon="clone" />{{ $t('deliveryMethods.Clone') }} </b-dropdown-item>
                <b-dropdown-item href="#" variant="danger" @click="deleteDeliveryMethod"><McIcon icon="trash" />{{ $t('deliveryMethods.Delete') }} </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryMethodsService from '@/services/DeliveryMethodsService'
import { niceCurrencyMaybeWithoutCents } from '@/helpers/Formatting'

export default {
  props: {
    deliveryMethod: Object,
    merchant: Object,
    merchantId: Number,
  },
  data() {
    return {
      cloning: false,
      deleting: false,
      deleted: false,
      publishing: false,
    }
  },
  computed: {
    busy() {
      return this.cloning || this.deleting || this.publishing || this.deleted
    },
    displayPricePerItem() {
      return niceCurrencyMaybeWithoutCents(this.deliveryMethod.priceInCentsPerItem)
    },
    displayPricePerOrder() {
      return niceCurrencyMaybeWithoutCents(this.deliveryMethod.priceInCentsPerOrder)
    },
    checked: {
      get() {
        return this.deliveryMethod.published
      },
      set(val) {
        this.publishing = true
        this.deliveryMethod.published = val
        DeliveryMethodsService.update(this.merchantId, {
          id: this.deliveryMethod.id,
          published: this.deliveryMethod.published ? 1 : 0,
        }).finally(() => {
          this.publishing = false
        })
      },
    },
  },
  methods: {
    refreshDeliveryMethods() {
      this.$emit('refresh-delivery-methods')
    },
    cloneDeliveryMethod() {
      this.cloning = true
      DeliveryMethodsService.clone(this.merchantId, this.deliveryMethod).finally(() => {
        this.cloning = false
        this.refreshDeliveryMethods()
      })
    },
    deleteDeliveryMethod() {
      this.deleting = true
      if (confirm(this.$t('deliveryMethods.ConfirmDelete', { name: this.deliveryMethod.headline }))) {
        DeliveryMethodsService.delete(this.merchantId, this.deliveryMethod).finally(() => {
          this.deleting = false
          this.deleted = true
          this.refreshDeliveryMethods()
        })
      }
    },
  },
}
</script>

<style lang="scss">
.delivery-method-listitem-wrapper {
  border-width: 2px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  &.draft {
    border-style: dashed;
    .delivery-method-listitem-wrapper {
      border-style: dashed;
    }
    .item-preview {
      opacity: 0.6;
    }
  }

  .item-image {
    width: 128px;
    height: 96px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    vertical-align: text-top;
  }
  .item-cols {
    width: 100%;
    display: flex;
    .item-col:nth-child(2) {
      flex-grow: 1;
    }
  }
  .limitheight {
    max-height: 100px;
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      content: '';
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
      height: 40px;
    }
  }
}
</style>
