<template>
  <div class="fullscreencontent">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.fullscreencontent {
  width: 100%;
  height: 100%;
  min-height: 100px;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  methods: {
    showFooter: function () {
      this.$store.dispatch('LayoutStore/showFooter')
    },
    hideFooter: function () {
      this.$store.dispatch('LayoutStore/hideFooter')
    },
  },
  computed: {
    ...mapState({
      LayoutStore: state => state.LayoutStore,
    }),
  },
  beforeMount() {
    this.hideFooter()
  },
  beforeDestroy() {
    this.showFooter()
  },
}
</script>
