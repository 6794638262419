<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group>
      <!-- prettier-ignore -->
      <b-form-checkbox
        v-bind="$attrs"
        v-on="listeners"
        v-bind:checked="value"
        v-on:input="handleinput"
      >
        <slot></slot>
      </b-form-checkbox>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  methods: {
    handleinput(e) {
      this.$emit('input', e)
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
  },
  props: {
    errorHint: String,
    label: String,
    description: String,
    value: Boolean,
  },
}
</script>
