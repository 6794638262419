<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group :class="'mc-form-checkboxes' + (inline ? ' inline' : '')">
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <!-- prettier-ignore -->
      <div class="custom-file">
        {{ placeholder }}
        <div class="checkboxes" v-if="multiple">
          <label v-for="(item, id) in options" v-bind:key="id"><input type="checkbox" v-model="internalValue" :value="item.value" />{{ item.label }}</label>
        </div>
        <div class="radioboxes" v-else>
          <label v-for="(item, id) in options" v-bind:key="id"><input type="radio" v-model="internalValue" :value="item.value" />{{ item.label }}</label>
        </div>
      </div>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        return val
      },
    },
  },
  props: {
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    placeholder: String,
    value: [Number, String, Array, Boolean],
    options: [Array, Object],
    inline: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.input-group.mc-form-checkboxes > .custom-file {
  height: auto;
  display: block;
  padding: 0 10px;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #aaa;
  border-left: 0;
  border-radius: 0 3px 3px 0;
  margin-top: 1px;
  margin-bottom: 1px;
}
.input-group.mc-form-checkboxes input {
  margin-right: 5px;
  margin-left: 5px;
}
.input-group.mc-form-checkboxes label {
  display: block;
  margin: 0;
}
.input-group.mc-form-checkboxes.inline label {
  display: inline-block;
  line-height: 2em;
  margin-right: 15px;
}
</style>
