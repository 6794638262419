<template>
  <div class="webshopdesigner-wrap">
    <div class="webshopdesigner-panel">
      <WebshopDesignerForm ref="webshopDesignerForm" @cancel="$emit('cancel')" @setDesign="setDesign" :merchantId="merchantId" />
    </div>
    <div class="webshopdesigner-preview">
      <iframe ref="previewFrame" :src="'https://preview.danskegavekort.dk/' + merchantId" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    merchantId: Number,
  },
  methods: {
    setDesign(design) {
      //console.log(this.$refs['previewFrame'], design)
      this.$refs['previewFrame'].contentWindow.postMessage(
        {
          action: 'setDesign',
          design: design,
        },
        'https://preview.danskegavekort.dk',
      )
    },
    closeIfUnchanged() {
      this.$refs['webshopDesignerForm'].closeIfUnchanged()
    },
  },
}
</script>

<style lang="scss">
.webshopdesigner-panel {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  overflow: hidden;
}
.webshopdesigner-preview {
  position: absolute;
  left: 400px;
  top: 0;
  bottom: 0;
  width: calc(100% - 400px);
  z-index: 1;
  iframe {
    width: 100%;
    border: 0;
    height: 100%;
  }
}
</style>
