import Vue from 'vue'
import store from '@/setup/Store'

Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.merchantInfo) {
      this.$merchantInfo = options.merchantInfo
    } else if (options.parent && options.parent.$merchantInfo) {
      this.$merchantInfo = options.parent.$merchantInfo
    }
  },
})

export default {
  fromId: function (id) {
    for (var intId in store.state.LoginStore.merchantInfo) {
      let merchantInfo = store.state.LoginStore.merchantInfo[intId]
      if (merchantInfo.id == id) {
        return merchantInfo
      }
    }
    return null
  },
}
