<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'save' : 'plus'"
    :title="title"
    :submittext="isEditing ? $t('general.Save') : $t('general.Create')"
  >
    <mc-form-input
      name="headline"
      type="text"
      v-model="curData.headline"
      v-validate="'required'"
      :data-vv-as="$t('deliveryMethods.Headline')"
      :label="$t('deliveryMethods.Headline')"
      :placeholder="$t('deliveryMethods.EnterHeadline')"
      key="deliverymethodform.headline"
      :errorHint="errors.first('headline')"
      :state="!errors.has('headline')"
      :icon="['far', 'heading']"
      :description="$t('deliveryMethods.HeadlineDescription')"
    />

    <mc-form-input
      :icon="['fad', 'file-contract']"
      name="description"
      type="wysiwyg"
      buttons="most"
      :data-vv-as="$t('deliveryMethods.Description')"
      :label="$t('deliveryMethods.Description')"
      v-model="curData.description"
      :errorHint="errors.first('description')"
      :state="!errors.has('description')"
      key="deliverymethodform.description"
      :placeholder="$t('deliveryMethods.EnterDescription')"
      :description="$t('deliveryMethods.DescriptionDescription')"
    />

    <mcFormDualInputs
      :errorHint="errors.first('pricePerItem') || errors.first('pricePerOrder')"
      :state="!errors.has('pricePerItem') && !errors.has('pricePerOrder')"
      :description="$t('deliveryMethods.PriceDescription')"
    >
      <template v-slot:input1>
        <mc-form-input
          :icon="['fad', 'money-bill-wave']"
          name="pricePerItem"
          type="number"
          :min="0"
          max="1000"
          step="1"
          v-validate="'required|decimal:2|min_value:0'"
          :data-vv-as="$t('deliveryMethods.PricePerItem')"
          :label="$t('deliveryMethods.PricePerItem')"
          v-model="curData.pricePerItem"
          :placeholder="$t('deliveryMethods.EnterPrice')"
          :state="!errors.has('pricePerItem')"
          key="deliverymethodform.pricePerItem"
          :unit="$t('general.DKK')"
        />
      </template>
      <template v-slot:input2>
        <mc-form-input
          :icon="['fad', 'money-bill-wave']"
          name="pricePerOrder"
          type="number"
          :min="0"
          max="1000"
          step="1"
          v-validate="'required|decimal:2|min_value:0'"
          :data-vv-as="$t('deliveryMethods.PricePerOrder')"
          :label="$t('deliveryMethods.PricePerOrder')"
          v-model="curData.pricePerOrder"
          :placeholder="$t('deliveryMethods.EnterPrice')"
          :state="!errors.has('pricePerOrder')"
          key="deliverymethodform.pricePerOrder"
          :unit="$t('general.DKK')"
        />
      </template>
    </mcFormDualInputs>

    <mc-form-select
      v-if="curData.pricePerItem || curData.pricePerOrder"
      name="userId"
      v-model="curData.userId"
      :data-vv-as="$t('deliveryMethods.UserToCredit')"
      :label="$t('deliveryMethods.UserToCredit')"
      :placeholder="$t('deliveryMethods.ChooseUser')"
      :description="$t('deliveryMethods.UserToCreditDescription')"
      key="deliverymethodform.userId"
      :errorHint="errors.first('userId')"
      :state="!errors.has('userId')"
      :options="userList"
      :icon="['fad', 'user-cog']"
    />

    <!-- eslint-disable-next-line -->
    <McFormImage
      name="file"
      :label="$t('deliveryMethods.Image')"
      :placeholder="$t('deliveryMethods.PickImage')"
      icon="image"
      v-model="curData.image"
      :description="$t('deliveryMethods.ImageDescription')"
    />

    <mc-form-checkbox :label="$t('deliveryMethods.RequireAddress')" v-model="curData.requireAddress" :description="$t('deliveryMethods.RequireAddressDescription')">{{ $t('deliveryMethods.AddressMandatoryWithDeliveryMethod') }}</mc-form-checkbox>

    <mc-form-input
      name="additionalEmail"
      type="email"
      v-model="curData.additionalEmail"
      v-validate="'email'"
      :data-vv-as="$t('deliveryMethods.AdditionalEmail')"
      :label="$t('deliveryMethods.AdditionalEmail')"
      :description="$t('deliveryMethods.AdditionalEmailDescription')"
      :placeholder="$t('general.EnterEmail')"
      key="deliverimethodsform.additionalEmail"
      :errorHint="errors.first('additionalEmail')"
      :state="!errors.has('additionalEmail')"
      icon="at"
    />
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmptyObject } from '@/helpers/Misc'
import DeliveryMethodsService from '@/services/DeliveryMethodsService'
import MerchantService from '@/services/MerchantService'
import { FormatError } from '@/helpers/ErrorFormatting'

const defaultData = {
  id: 0,
  userId: 0,
  headline: '',
  pricePerItem: 49,
  pricePerOrder: 0,
  description: '',
  image: null,
  additionalEmail: '',
  requireAddress: false,
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      isEditing: false,
      userList: [],
    }
  },
  props: {
    value: Object,
    merchantId: Number,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      let newVal = {}

      for (var field in defaultData) {
        //console.log(field, val.hasOwnProperty('minFreeAmountInCents'))
        if (field === 'pricePerItem' && val.hasOwnProperty('priceInCentsPerItem')) {
          newVal[field] = val.priceInCentsPerItem / 100
        } else if (field === 'pricePerOrder' && val.hasOwnProperty('priceInCentsPerOrder')) {
          newVal[field] = val.priceInCentsPerOrder / 100
        } else {
          newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
        }
      }

      this.curData = newVal

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)
    },
    merchantId(merchantId) {
      this.loadingState = true
      MerchantService.users(merchantId)
        .then(data => {
          var theList = [{ value: 0, label: this.$t('deliveryMethods.ChooseUser') }]
          for (var n in data) {
            theList.push({ value: data[n].id, label: data[n].label })
          }
          this.userList = theList
          this.loadingState = false
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      let action = DeliveryMethodsService.create
      if (this.isEditing) {
        action = DeliveryMethodsService.update
      }
      let postData = { ...this.curData }
      postData.priceInCentsPerItem = 100 * this.curData.pricePerItem
      delete postData['pricePerItem']
      postData.priceInCentsPerOrder = 100 * this.curData.pricePerOrder
      delete postData['pricePerOrder']
      return action(this.merchantId, postData).then(
        data => {
          this.toastSuccess(this.$t('general.ChangesSaved'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating delivery method', error)
          return Promise.reject(error)
        },
      )
    },
  },
  computed: {
    title() {
      return this.isEditing ? this.$t('deliveryMethods.EditDeliveryMethod') : this.$t('deliveryMethods.CreateNewDeliveryMethod')
    },
  },
}
</script>
