<template>
  <McLayoutDefault :heading="$t('headings.Users')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.isOwnerOfMerchants()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col v-if="!$perms.isAdministrator()" md="4" cols="12">
        <b-card :title="$t('users.Description1')" class="mb-2">
          <p>{{ $t('users.Description2') }}</p>
          <p>{{ $t('users.Description3') }}</p>
        </b-card>
      </b-col>
      <b-col :md="$perms.isAdministrator() ? 12 : 8" cols="12">
        <McTable class="users-table" ref="usersTable" :emptyText="$t('users.YouHaveNoUsers')" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :provider="dataProvider">
          <!-- eslint-disable-next-line -->
          <McButton
            @click="editUser({})"
            variant="primary"
            v-if="$perms.isOwnerOfMerchants() || $perms.isAdministrator()"
            icon="user-plus"
            :text="$t('users.CreateNewUser')"
          />
          <template v-slot:cell(role)="data">
            <strong v-if="data.item.role === 'administrator'">{{ formatRole(data.item.role) }}</strong>
            <div v-if="data.item.ownedMerchantCount > 0">
              <label class="in_table_list_label">{{ $t('users.AdminOfColon') }}</label>
              <ul class="in_table_flat_list">
                <li v-for="(merchantName, merchantId) in data.item.ownedMerchants" v-bind:key="merchantId">{{ merchantName }}</li>
              </ul>
            </div>
            <div v-if="data.item.role == 'qr'">
              <label class="in_table_list_label">{{ $t('users.RedeemerForColon') }}</label>
              <ul class="in_table_flat_list">
                <li v-for="(merchantName, merchantId) in data.item.permissionedMerchants" v-bind:key="merchantId">{{ merchantName }}</li>
              </ul>
            </div>
            <div v-else-if="data.item.role == 'api'">
              <label class="in_table_list_label">{{ $t('users.ApiUserForColon') }}</label>
              <ul class="in_table_flat_list">
                <li v-for="(merchantName, merchantId) in data.item.permissionedMerchants" v-bind:key="merchantId">{{ merchantName }}</li>
              </ul>
            </div>
            <div v-else-if="data.item.permissionedMerchantCount > 0">
              <label class="in_table_list_label">{{ $t('users.PermissionsForColon') }}</label>
              <ul class="in_table_flat_list">
                <li v-for="(merchantName, merchantId) in data.item.permissionedMerchants" v-bind:key="merchantId">
                  <PermissionInTable :merchantName="merchantName" :permissions="data.item.assignedPermissions[merchantId]" />
                </li>
              </ul>
            </div>
            <div v-if="data.item.role !== 'administrator' && data.item.permissionedMerchantCount == 0 && data.item.ownedMerchantCount == 0">
              <label class="in_table_list_label">{{ $t('permissions.NoPermissionsAtAll') }}</label>
            </div>
          </template>
          <template v-slot:cell(permissionedMerchantCount)="data">
            <div v-if="data.item.role == 'qr'">
              <label class="in_table_list_label">{{ $t('users.RedeemerForColon') }}</label>
              <ul class="in_table_flat_list">
                <li v-for="(merchantName, merchantId) in data.item.permissionedMerchants" v-bind:key="merchantId">{{ merchantName }}</li>
              </ul>
            </div>
            <div v-else-if="data.item.role == 'api'">
              <label class="in_table_list_label">{{ $t('users.ApiUserForColon') }}</label>
              <ul class="in_table_flat_list">
                <li v-for="(merchantName, merchantId) in data.item.permissionedMerchants" v-bind:key="merchantId">{{ merchantName }}</li>
              </ul>
            </div>
            <div v-else-if="data.item.permissionedMerchantCount > 0">
              <ul class="in_table_flat_list">
                <li v-for="(merchantName, merchantId) in data.item.permissionedMerchants" v-bind:key="merchantId">
                  <PermissionInTable :merchantName="merchantName" :permissions="data.item.assignedPermissions[merchantId]" />
                </li>
              </ul>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <!--pre>{{data.item}}</pre-->
            <b-button-group>
              <!-- eslint-disable-next-line -->
              <McButton
                @click="loginAsUser(data.item)"
                variant="success"
                size="sm"
                :icon="['fas', 'sign-in']"
                v-if="$perms.isAdministrator() && data.item.id > 1 && data.item.role !== 'api'"
              />
              <!-- eslint-disable-next-line -->
              <McButton
                v-if="data.item.role === 'qr'"
                @click="getLoginQrPdf(data.item)"
                :title="$t('users.DownloadLoginQr')"
                variant="info"
                size="sm"
                :icon="['far', 'qrcode']"
              />
              <!-- eslint-disable-next-line -->
              <McButton
                @click="editUser(data.item)"
                variant="primary"
                size="sm"
                icon="user-edit"
                v-if="data.item.id > 1 && data.item.role !== 'api'"
              />
              <!-- eslint-disable-next-line -->
              <McButton
                @click="showApiCredentials(data.item)"
                variant="primary"
                size="sm"
                :icon="['far', 'eye']"
                v-if="data.item.role === 'api'"
              />
              <McButton
                icon="trash"
                variant="danger"
                size="sm"
                @click="deleteuser(data.item.id, data.item.name)"
                v-if="data.item.id !== $perms.userId() && data.item.id > 1 && (!$perms.isAdministrator() || data.item.ownedMerchantCount === 0)"
              />
            </b-button-group>
          </template>
        </McTable>
      </b-col>
    </b-row>
    <UserForm id="userform" isModal :value="editingUser" :title="$t('users.CreateNewUser')" @completed="refreshTable()" />
    <b-modal id="apicredsmodal" ok-only no-close hide-header>
      <p class="text-center mt-4 mb-2">{{ $t('users.ApiCreds') }}</p>
      <input class="w-100 text-center" :value="apiCred" disabled />
      <p class="text-center mt-4 mb-2">{{ $t('users.YouMayNeedYourMerchantIdToo') }}</p>
      <input class="w-100 text-center" :value="apiCredMerchantId" disabled />
    </b-modal>
  </McLayoutDefault>
</template>

<script>
import Router from '@/setup/Router'
import store from '@/setup/Store'
import UserService from '@/services/UserService'
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'name',
      dataProvider: UserService.query,
      editingUser: {},
      apiCred: null,
      apiCredMerchantId: null,
    }
  },
  computed: {
    fields() {
      let retval = []
      retval.push({ key: 'name', priority: 0, mw: 200, sortable: true, label: this.$t('general.Name') })
      if (this.$perms.isAdministrator()) {
        retval.push({ key: 'role', priority: 1, mw: 160, sortable: true, label: this.$t('general.Role') })
      } else {
        retval.push({ key: 'permissionedMerchantCount', priority: 1, mw: 160, sortable: true, label: this.$t('general.Permissions') })
      }
      retval.push({ key: 'email', priority: 2, mw: 200, sortable: true, label: this.$t('general.Email') })
      retval.push({ key: 'actions', priority: 0, mw: 120, label: this.$t('general.Actions'), class: 'text-right' })

      return retval
    },
  },
  methods: {
    refreshTable() {
      this.$refs.usersTable.refresh()
    },
    editUser(user) {
      this.editingUser = { ...user }
      this.$bvModal.show('userform')
    },
    deleteuser(id, name) {
      if (confirm(this.$t('users.ConfirmDelete', { name }))) {
        UserService.delete(id).then(data => {
          this.refreshTable()
        })
      }
    },
    showApiCredentials(user) {
      this.apiCred = user.apiCred
      this.apiCredMerchantId = Object.keys(user.permissionedMerchants)[0]
      this.$bvModal.show('apicredsmodal')
    },
    loginAsUser(user) {
      AuthenticationService.loginAsUser(user.id).then(data => {
        store.commit('LoginStore/loginSuccess', data.userInfo)
        Router.push('/')
      })
    },
    getLoginQrPdf(user) {
      AuthenticationService.qrLoginPdf(user.id).then(data => {
        window.location = data.downloadUrl
      })
    },
    formatRole(value) {
      if (value === 'administrator') {
        return this.$t('users.UserTypeAdministrator')
      } else if (value === 'user') {
        return this.$t('users.UserTypeUser')
      } else if (value === 'qr') {
        return this.$t('users.UserTypeQrOnly')
      } else if (value === 'api') {
        return this.$t('users.UserTypeAPI')
      }
      return value
    },
  },
}
</script>
