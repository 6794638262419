/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.\nFor more details, visit https://goo.gl/AFskqB')
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      const updateInterval = 60 * 10
      const updateCheck = () => {
        console.log('Updating')
        registration
          .update()
          .then(() => {
            console.log('Updated')
            setTimeout(updateCheck, updateInterval * 1000)
          })
          .catch(() => {
            //Fails when another tab has updated during our waiting period.
            //Or when we're offline. It's OK, just try again later...
            console.log('Update failed')
            setTimeout(updateCheck, updateInterval * 1000)
          })
      }
      setTimeout(updateCheck, updateInterval * 1000)

      let newWorker = registration.installing || registration.active
      //console.log(registration)
      newWorker.addEventListener('statechange', e => {
        console.log('Statechange', e)
        if (newWorker.state === 'redundant') {
          // We have a new service worker (app-version)
          let useCoolKidsMethod = false
          if (useCoolKidsMethod) {
            //The cool kids obviously
            document.dispatchEvent(new CustomEvent('showRefreshUI', { detail: registration }))
          } else {
            //But for our non-techie crowd and perhaps bi-monthly updates a forced refresh is actually better
            window.location.reload()
          }
        }
      })
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New version available - the App will be refreshed')
      window.location.reload(true)
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
