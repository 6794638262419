import { apiRequest, authorizedApiRequest } from '@/setup/ApiService'

export default {
  login: function (email, password) {
    return apiRequest(
      '/authentication/login', //
      'POST',
      {
        email,
        password,
      },
    )
  },

  requestPasswordResetMail: function (email) {
    return apiRequest(
      '/authentication/request-password-reset-mail', //
      'POST',
      {
        email,
      },
    )
  },

  resendCreateMail: function (params) {
    return apiRequest(
      '/authentication/resend-create-mail', //
      'POST',
      params,
    )
  },

  checkConfirmationToken: function (token, purpose) {
    return apiRequest(
      '/authentication/check-confirmation-token', //
      'POST',
      {
        token,
        purpose,
      },
    )
  },

  checkCreateToken: function (token, email) {
    return apiRequest(
      '/authentication/check-create-token', //
      'POST',
      {
        token,
        email,
      },
    )
  },

  checkChangeToken: function (token, email) {
    return apiRequest(
      '/authentication/check-confirmation-token', //
      'POST',
      {
        token,
        purpose: 'emailChange',
        email,
      },
    )
  },

  changePasswordUsingToken: function (token, newPassword) {
    return apiRequest(
      '/authentication/change-password-using-token', //
      'POST',
      {
        token: token,
        newPassword: newPassword,
      },
    )
  },

  checkLoginToken: function () {
    return authorizedApiRequest(
      '/authentication/check-login-token', //
      'GET',
    )
  },

  renewToken: function () {
    return authorizedApiRequest(
      '/authentication/renew-token', //
      'POST',
    )
  },

  invalidateTokens: function () {
    return authorizedApiRequest(
      '/authentication/invalidate-tokens', //
      'POST',
    )
  },

  loginAsUser: function (id) {
    return authorizedApiRequest(
      `/authentication/login-as/${id}`, //
      'GET',
    )
  },

  qrLoginPdf: function (userId) {
    return authorizedApiRequest(
      '/authentication/login-qr/' + userId, //
      'GET',
    )
  },
}
