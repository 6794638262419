<template>
  <div class="table__wrap" ref="table_wrap">
    <div>
      <div class="table__filter mb-3"><b-input @update="handleFilter" v-model="filter" :placeholder="$t('tables.Search')" /></div>
      <div class="table__buttons mb-3">
        <slot></slot>
      </div>
      <div style="clear: both" />
    </div>
    <!-- prettier-ignore -->
    <b-table
      ref="theTable"
      :busy.sync="isBusy"
      :items="isStaticList ? items : internalProvider"
      :fields="fittedFields"
      :per-page="isStaticList ? -1 : perPage"
      :current-page="currentPage"
      :filter="filter"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :primary-key="primaryKey"
      :show-empty="emptyText != null && emptyText != ''"
      :empty-text="emptyText"
      :empty-filtered-text="emptyFilteredText ? emptyFilteredText : $t('tables.EmptyFilteredText')"
      sort-icon-left
      striped
      :small="isSmall"
      :stacked="mustStack"
      :tbody-tr-class="tbodyTrClass"
      @row-clicked="toggleDetails"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
      <template v-slot:row-details="row" v-if="Object.keys(detailCols).length > 0">
        <b-card>
          <div v-for="column in detailCols" v-bind:key="column.key">
            <b-row class="mb-2" v-if="showColumnInDetails(column, row)">
              <b-col sm="3" class="text-sm-right"><b>{{ column.label }}:</b></b-col>
              <b-col v-if="slotExists(column.key)"><slot :name="'cell(' + column.key + ')'" v-bind="row" /></b-col>
              <b-col v-else>{{ row.item[column.key] }}</b-col>
            </b-row>
          </div>
        </b-card>
      </template>
    </b-table>
    <!-- prettier-ignore -->
    <div v-if="unfilteredCount > perPage" class="table__pages"><b-pagination
      :total-rows="filteredCount"
      :per-page="perPage"
      v-model="currentPage"
    /></div>
    <!-- prettier-ignore -->
    <div class="table__counts" v-if="!isStaticList || items.length == 0"><span>{{ $tc('general.pagination', filteredCount, {
      filteredCount: filteredCount,
      filteredFrom: unfilteredCount !== filteredCount ? $tc('general.filteredFrom', unfilteredCount, {unfilteredCount: unfilteredCount}) : null
    }) }}</span></div>
  </div>
</template>

<script>
export default {
  methods: {
    refresh() {
      this.$refs.theTable.refresh()
    },
    slotExists(name) {
      return Object.keys(this.$scopedSlots).includes('cell(' + name + ')')
    },
    showColumnInDetails(column, row) {
      return column.showInDetailsIf === true || (typeof column.showInDetailsIf === 'string' && row.item[column.showInDetailsIf])
    },
    toggleDetails(item) {
      this.$set(item, '_showDetails', !item._showDetails)
    },
    handleFilter(val) {
      clearTimeout(this.$_filtertimeout)
      this.$_filtertimeout = setTimeout(() => {
        this.filter = val
      }, 150)
    },
    internalProvider(ctx) {
      if (ctx.sortBy == '') {
        ctx.sortBy = this.prevSort.sortBy
        ctx.sortDesc = this.prevSort.sortDesc
      }
      this.prevSort = { sortBy: ctx.sortBy, sortDesc: ctx.sortDesc }
      if (this.isStaticList) {
        return this.items
      }
      return this.$props
        .provider(ctx)
        .then(data => {
          this.$data.filteredCount = data.filteredCount
          this.$data.unfilteredCount = data.unfilteredCount
          let items = data.items
          for (var rowId in items) {
            items[rowId]._showDetails = false
          }
          return items
        })
        .catch(error => {
          // Returning an empty array, allows table to correctly handle
          // internal busy state in case of error
          return []
        })
    },
    handleResize() {
      const target = this.$refs.table_wrap
      this.componentWidth = target.getBoundingClientRect().width
    },
    setSearch(newSearch) {
      //this.handleFilter(newSearch)
      this.filter = newSearch
    },
  },
  data() {
    return {
      isBusy: false,
      data: null,
      filter: '',
      currentPage: 1,
      perPage: 10,
      filteredCount: 0,
      unfilteredCount: 0,
      componentWidth: 0,
      prevSort: null,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    if (this.query) {
      this.filter = this.query
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    this.$_filtertimeout = null
  },
  beforeDestroy() {
    clearTimeout(this.$_filtertimeout)
  },

  computed: {
    isStaticList() {
      return typeof this.provider !== 'function'
    },
    isSmall() {
      return this.componentWidth < 800
    },
    isSmaller() {
      return this.componentWidth < 400
    },
    internalFields() {
      let fields = []
      let defaultpriority = 100
      let defaultWidth = 170
      for (var colId in this.fields) {
        fields[colId] = { ...this.fields[colId] }
        fields[colId].priority = typeof fields[colId].priority == 'number' ? fields[colId].priority : defaultpriority++
        fields[colId].colW = typeof fields[colId].mw == 'number' ? fields[colId].mw : defaultWidth
        fields[colId].showInDetailsIf = typeof fields[colId].showInDetailsIf == 'string' ? fields[colId].showInDetailsIf : true
        fields[colId].thStyle = { minWidth: fields[colId].colW + 'px' }
      }
      return fields
    },
    absMinW() {
      let absMinW = 0
      for (var colId in this.internalFields) {
        if (this.internalFields[colId].priority < 1) {
          absMinW += this.internalFields[colId].colW
        }
      }
      return absMinW
    },
    prioritizedCols() {
      let prioritizedCols = []
      for (var colId in this.internalFields) {
        prioritizedCols.push({
          key: colId,
          priority: this.internalFields[colId].priority,
          width: this.internalFields[colId].colW,
        })
      }
      prioritizedCols.sort((a, b) => (a.priority > b.priority ? 1 : a.priority === b.priority ? 0 : -1))
      return prioritizedCols
    },
    mustStack() {
      return this.absMinW > this.componentWidth
    },
    fittedFields() {
      let fitted = [] // [...this.internalFields]
      this.internalFields.forEach(elm => {
        fitted.push({ ...elm })
      })

      let accW = 0
      for (var pId in this.prioritizedCols) {
        let colId = this.prioritizedCols[pId].key
        if (this.mustStack) {
          if (this.prioritizedCols[pId].priority < 0) {
            delete fitted[colId]
          } else {
            fitted[colId].class = null
          }
        } else {
          accW += this.prioritizedCols[pId].width
          if (accW > this.componentWidth) {
            delete fitted[colId]
          }
        }
      }
      return fitted.filter(n => n)
    },
    detailCols() {
      if (this.mustStack) {
        return []
      }
      let details = [...this.internalFields]

      let accW = 0
      for (var pId in this.prioritizedCols) {
        let colId = this.prioritizedCols[pId].key
        accW += this.prioritizedCols[pId].width
        if (accW <= this.componentWidth) {
          delete details[colId]
        }
      }
      return details.filter(n => n)
    },
  },
  watch: {
    items() {
      this.$refs.theTable.refresh()
    },
  },
  props: {
    emptyText: String,
    emptyFilteredText: String,
    query: String,
    fields: Array,
    provider: Function,
    items: Array,
    primaryKey: {
      type: String,
      default: 'id',
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    tbodyTrClass: {
      default: '',
    },
  },
}
</script>

<style lang="scss">
.table__counts,
.table__filter {
  float: left;
  width: 50%;
  text-align: left;
  input {
    max-width: 220px;
  }
}
.table__pages,
.table__buttons {
  float: right;
  width: 50%;
  text-align: right;
  .pagination {
    float: right;
  }
}
.table thead th {
  vertical-align: middle;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.in_table_list_label {
  font-size: 0.7em;
  margin: 0;
  text-transform: uppercase;
}
.in_table_flat_list {
  font-size: 0.9em;
  margin: 0 0 0 1em;
  padding: 0;
}
</style>
