<template>
  <div class="wysiwyg-wrap form-control" style="height: auto">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div>
        <b-button-group v-if="['all', 'most', 'few'].includes(buttons)" size="sm" class="my-2 mr-2">
          <McButton icon="bold" @click="commands.bold" :variant="isActive.bold() ? 'primary' : 'outline-secondary'" />
          <McButton icon="italic" @click="commands.italic" :variant="isActive.italic() ? 'primary' : 'outline-secondary'" />
          <McButton icon="underline" @click="commands.underline" :variant="isActive.underline() ? 'primary' : 'outline-secondary'" />
          <McButton v-if="['all', 'most'].includes(buttons)" icon="strikethrough" @click="commands.strike" :variant="isActive.strike() ? 'primary' : 'outline-secondary'" />
        </b-button-group>

        <b-button-group v-if="['all', 'most'].includes(buttons)" size="sm" class="my-2 mr-2">
          <McButton icon="heading" @click="commands.heading({ level: 3 })" :variant="isActive.heading({ level: 3 }) ? 'primary' : 'outline-secondary'" />
          <McButton icon="paragraph" @click="commands.paragraph()" :variant="isActive.paragraph() ? 'primary' : 'outline-secondary'" />
          <McButton icon="quote-right" @click="commands.blockquote()" :variant="isActive.blockquote() ? 'primary' : 'outline-secondary'" />
        </b-button-group>

        <McButton v-if="['all', 'most'].includes(buttons)" size="sm" class="my-2 mr-2" icon="horizontal-rule" @click="commands.horizontal_rule()" :variant="'outline-secondary'" />

        <b-button-group v-if="['all', 'most'].includes(buttons)" size="sm" class="my-2 mr-2">
          <McButton icon="list-ul" @click="commands.bullet_list" :variant="isActive.bullet_list() ? 'primary' : 'outline-secondary'" />
          <McButton icon="list-ol" @click="commands.ordered_list" :variant="isActive.ordered_list() ? 'primary' : 'outline-secondary'" />
        </b-button-group>

        <b-button-group v-if="buttons == 'all'" size="sm" class="my-2 ml-2 float-right">
          <McButton icon="undo" :disabled="undoDepth == 0" @click="commands.undo" :variant="undoDepth > 0 ? 'outline-primary' : 'outline-secondary'" />
          <McButton icon="redo" :disabled="redoDepth == 0" @click="commands.redo" :variant="redoDepth > 0 ? 'outline-primary' : 'outline-secondary'" />
        </b-button-group>

        <slot name="extraButtons" />
      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  HardBreak, //
  Heading,
  Blockquote,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Strike,
  Underline,
  History,
  Placeholder,
} from 'tiptap-extensions'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    buttons: {
      type: String,
      default: 'few',
    },
  },
  components: {
    EditorContent,
    EditorMenuBar,
  },
  data() {
    let extensions = [
      new Bold(),
      new Italic(),
      new Underline(),
      new History(),
      new HardBreak(),
      new Placeholder({
        emptyEditorClass: 'is-editor-empty',
        emptyNodeClass: 'is-empty',
        emptyNodeText: this.placeholder,
        showOnlyWhenEditable: true,
        showOnlyCurrent: true,
      }),
    ]
    if (['all', 'most'].includes(this.buttons)) {
      extensions.push(new Blockquote())
      extensions.push(new BulletList())
      extensions.push(new Heading({ levels: [3] }))
      extensions.push(new HorizontalRule())
      extensions.push(new ListItem())
      extensions.push(new OrderedList())
      extensions.push(new Strike())
    }

    return {
      undoDepth: 0,
      redoDepth: 0,
      editor: new Editor({
        extensions,
        content: this.value,
        onUpdate: ({ getHTML }) => {
          this.undoDepth = this.editor.commands.undoDepth()
          this.redoDepth = this.editor.commands.redoDepth()
          this.$emit('input', getHTML())
        },
      }),
    }
  },
  beforeDestroy() {
    //TODO: Giver det problemer ikke at gøre det her?
    // Det rydder indholdet "for tidligt" og ødelægger animationer
    // hvis det gøres - og lader ikke umiddelbart til at have konsekvenser
    // at lade være...
    //this.editor.destroy()
  },
}
</script>

<style lang="scss">
.wysiwyg-wrap {
  .btn-outline-secondary {
    color: #333;
  }
  blockquote {
    padding: 0.5em;
    background: #eee;
    border-left: 5px solid #aaa;
  }
  .editor__content {
    padding: 0.5em;
    border: 1px solid #aaa;
    border-radius: 3px;
    max-height: 280px;
    overflow: auto;
  }
  .ProseMirror {
    outline: none;
  }
  blockquote,
  h1,
  h2,
  h3,
  ol,
  p,
  pre,
  ul {
    margin: 1rem 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  hr {
    width: 90%;
    border-width: 3px;
  }
  p.is-editor-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    opacity: 0.5;
    pointer-events: none;
    height: 0;
  }
}
</style>
