<template>
  <McLayoutDefault :heading="$t('headings.Integration')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.hasMerchantsWithPermission('accessIntegration')">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col md="4" cols="12">
        <b-card class="mb-2">
          <div v-html="$t('integration.DescriptionHtml')" />
          <MerchantSelector @merchantSelected="m => (merchant = m)" permission="accessIntegration" v-model="merchantId" />
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <div v-html="$t('integration.DescriptionHtml2')" />
        <b-card no-body>
          <b-tabs card>
            <b-tab :title="$t('integration.GeneralSettings')" active>
              <b-card-text>
                <p>{{ $t('integration.GeneralSettingsDescription') }}</p>
                <p class="text-center"><mc-button variant="primary" :text="$t('integration.OpenWebshopDesigner')" @click="showWebshopDesigner" /></p>
                <div v-if="merchantDomains && merchantDomains.length > 0">
                  <p>
                    {{ $tc('general.RememberToCheckWebshop', merchantDomains.length) }}
                    <br />
                    <small>
                      {{ $tc('general.LinkWillOpenInNewTab', merchantDomains.length) }}
                    </small>
                  </p>
                  <ul>
                    <li v-for="(domain, key) in merchantDomains" v-bind:key="key">
                      <a :href="'https://' + domain" target="_blank">{{ domain }}</a>
                    </li>
                  </ul>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab :title="$t('integration.HostedSolution')">
              <b-card-text>
                <p>{{ $t('integration.HostedSolutionDescription') }}</p>
                <b-card class="my-3">
                  <div v-if="merchantDomains.length > 0">
                    <p v-if="merchantDomains.length == 1">{{ $t('integration.YouHaveThisDomain') }}</p>
                    <p v-else>{{ $t('integration.YouHaveTheFollowingDomains') }}</p>
                    <b-card body-class="py-2" class="mb-2" v-for="(domain, key) in merchantDomains" v-bind:key="key">
                      <a :href="'https://' + domain" target="_blank">https://{{ domain }}</a>
                      <McButton class="ml-3" icon="trash" variant="outline-danger" size="sm" @click="deleteDomain(domain)" />
                    </b-card>
                  </div>
                  <div class="mt-3 text-center">
                    <mc-button @click="showAddDomain" variant="success" icon="plus" :text="$t('integration.AddHostedDomain')" />
                  </div>
                </b-card>
                <HostedDomainForm @completed="refreshDomains" ref="addDomainForm" id="addDomainForm" :merchantId="merchantId" :merchantPrefix="merchant.prefix" isModal />
                <!--p>{{ $t('integration.HostedIntegrationDescriptionWordpress') }}</p>
                <p class="text-center">
                  <mc-button href="https://api.danskegavekort.dk/downloads/wordpress-plugin" variant="primary" :text="$t('integration.DownloadWordpressPlugin')" />
                </p-->
              </b-card-text>
            </b-tab>
            <b-tab :title="$t('integration.FullIntegration')">
              <b-card-text>
                <p v-html="$t('integration.FullIntegrationDescriptionWordpress', { merchantId: merchantId })" />
                <p class="text-center">
                  <mc-button href="https://api.danskegavekort.dk/downloads/wordpress-plugin" variant="primary" :text="$t('integration.DownloadWordpressPlugin')" />
                </p>
                <p>{{ $t('integration.FullIntegrationDescriptionOther') }}</p>
                <div class="text-center">
                  <pre class="fullintegrationCode">{{ scriptTag }}</pre>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <fullscreen-editor ref="webshopDesigner" :beforeClose="closeIfUnchanged">
      <WebshopDesigner ref="webshopDesignerForm" @cancel="$refs['webshopDesigner'].hide()" :merchantId="merchantId" />
    </fullscreen-editor>
  </McLayoutDefault>
</template>

<script>
import MerchantService from '@/services/MerchantService'
import { jsEmbedCode } from '@/helpers/EmbedCode'

export default {
  data() {
    return {
      merchantId: null,
      merchant: 'test',
      fontFamily: 'Open Sans',
      merchantDomains: [],
      todoimg: {
        backgroundImage: {
          url: 'https://api.danskegavekort.dk/images/YxuZCNUV3X9r1u8kraSngyrhLoiC791R',
          token: 'YxuZCNUV3X9r1u8kraSngyrhLoiC791R',
        },
      },
    }
  },
  watch: {
    merchantId() {
      this.refreshDomains()
    },
  },
  computed: {
    scriptTag() {
      return jsEmbedCode(this.merchantId)
    },
  },
  methods: {
    refreshDomains() {
      MerchantService.getDomains(this.merchantId).then(data => {
        this.merchantDomains = data
      })
    },
    deleteDomain(domain) {
      if (confirm(this.$t('integration.ConfirmDeleteDomain', { domain }))) {
        MerchantService.deleteDomain(this.merchantId, domain).then(data => {
          this.refreshDomains()
        })
      }
    },
    showAddDomain() {
      if (!this.merchantDomains || this.merchantDomains.length < 5) {
        this.$refs['addDomainForm'].forceReset()
        this.$bvModal.show('addDomainForm')
      } else {
        alert(this.$t('integration.YouCanOnlyAddFiveDomains'))
      }
    },
    closeIfUnchanged() {
      this.$refs['webshopDesignerForm'].closeIfUnchanged()
    },
    showWebshopDesigner() {
      this.$refs['webshopDesigner'].show()
    },
  },
}
</script>

<style lang="scss">
.fullintegrationCode {
  border: 1px solid #ddd;
  padding: 1em;
  display: inline-block;
  background: #eee;
  border-left: 3px solid #888;
  text-align: left;
}
</style>
