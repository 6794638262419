<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group>
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <McColorpicker :value="colorHex" format="hex" @input="emitValue" :opacity="opacity" pop="under" class="mc_form_color_input">
        <div class="mc_form_color_textbg" :style="textBgStyle" />
        <b-form-input :value="colorHex" @input="emitValue" class="mc_form_color_text" :style="previewStyle" />
      </McColorpicker>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
import Color from 'color'

export default {
  props: {
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    value: {
      type: [String, Object],
    },
    opacity: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'hex',
    },
  },
  data() {
    return {
      colorObj: Color(this.value),
    }
  },
  computed: {
    previewStyle() {
      let ret = {}
      ret.backgroundColor = this.colorObj.rgb()
      ret.color = this.colorObj.isDark() ? '#fff' : '#000'
      return ret
    },
    textBgStyle() {
      let ret = {}
      ret.backgroundColor = this.colorObj.alpha(0.8).rgb()
      return ret
    },
    colorHex() {
      let ret = this.colorObj.hex()
      if (this.opacity) {
        ret += ('0' + parseInt(255 * this.colorObj.alpha()).toString(16)).substr(-2)
      }
      return ret.toLowerCase()
    },
  },
  watch: {
    value(newVal) {
      this.colorObj = Color(newVal)
    },
  },
  methods: {
    emitValue(newVal) {
      try {
        let color = Color(newVal)
        if (this.format == 'hex') {
          let ret = color.rgb().hex()
          if (this.opacity) {
            ret += ('0' + parseInt(255 * color.alpha()).toString(16)).substr(-2)
          }
          this.$emit('input', ret.toLowerCase())
        } else if (this.format == 'rgb') {
          this.$emit('input', color.rgb().string())
        } else {
          this.$emit('input', color.rgb())
        }
      } catch (error) {
        console.log('Invalid color string: ', newVal, error)
      }
    },
  },
}
</script>

<style>
.mc_form_color_text {
  width: 100px;
  height: 38px;
  border: 1px solid #aaa;
  text-align: center;
  position: relative;
}
.mc_form_color_input {
  background: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="none"  viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="5" height="5" fill="grey" /><rect x="5" y="5" width="5" height="5" fill="grey" /><rect x="5" y="0" width="5" height="5" fill="white" /><rect x="0" y="5" width="5" height="5" fill="white" /></svg>');
  border-radius: 3px;
  background-size: 10px 10px;
  box-sizing: border-box;
  flex: 1 1 0%;
}
.mc_form_color_input > span,
.mc_form_color_input > span > input {
  width: 100%;
}
.mc_form_color_textbg {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 50%;
  width: 100px;
  margin-left: -50px;
}
</style>
