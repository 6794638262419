<template>
  <b-modal :id="$id('extendModal')" :title="$t('general.ExtendGiftcard')" @ok="sendExtend" :cancel-title="$t('general.Cancel')" :ok-title="$t('general.OK')">
    <p>{{ $t('general.ExtendDescription1') }}</p>
    <p>{{ $t('general.ExtendDescription2') }}</p>
    <!-- prettier-ignore -->
    <mcFormDatePicker
      v-if="codeInfo"
      :label="$t('general.PickNewExpiry')"
      :icon="['fas', 'calendar-check']"
      v-model="extendDate"
    />
  </b-modal>
</template>

<script>
import { FormatError } from '@/helpers/ErrorFormatting'
import CodesService from '@/services/CodesService'
import { mapActions } from 'vuex'

export default {
  props: {
    codeInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      extendDate: null,
    }
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
      'toastWarning',
    ]),
    show() {
      this.$bvModal.show(this.$id('extendModal'))
      setTimeout(() => {
        console.log(this.codeInfo.expires.substring(0, 10))
        this.extendDate = this.codeInfo.expires.substring(0, 10)
      }, 100)
    },
    sendExtend(e) {
      if (!this.$perms.isAdministrator() && !this.$perms.hasPermission('extendCodes', this.codeInfo.merchant)) {
        this.toastWarning(this.$t('permissions.LackingExtendCodesPermission'))
        return
      }
      CodesService.extend(this.codeInfo.code, this.extendDate).then(
        data => {
          switch (data.message) {
            case 'code.extended':
              this.toastSuccess(this.$t('general.CodeExtended'))
              break
          }
          this.$emit('extended')
          return Promise.resolve(data)
        },
        error => {
          alert(FormatError(error))
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
