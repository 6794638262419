<template>
  <div v-if="card.published" :data-card-id="card.id" class="gift-card-listitem-in-create-order-wrapper card mb-2">
    <div class="card-body py-0 pr-2">
      <div class="my-2 clearfix" @click="handleClick">
        <div class="clearfix">
          <div class="item-cols">
            <div class="item-col item-preview" v-if="card.image.url">
              <div class="item-image" :style="card.image.url ? 'background-image: url(' + card.image.url + '/240x180)' : ''"></div>
            </div>
            <div class="item-col item-preview px-2 limitheight">
              <p style="margin-bottom: 0">
                <b-badge v-if="card.type === 'giftcard' && card.priceInCents > 0" class="float-right m-1">{{ displayPrice }}</b-badge>
                <b-badge v-if="card.type === 'giftcard' && card.priceInCents == 0" class="float-right m-1">{{ displayFreeAmount }}</b-badge>
                <strong v-html="card.webshopName.replaceAll('\n', '<br>')"></strong>
              </p>
              <div class="item-description" v-if="card.description" v-html="card.description"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- eslint-disable-next-line -->
      <GiftCardListInCreateOrder
        v-if="card.type === 'category' && showmoreState[card.id]"
        class="my-2 category-sub-list"
        :data-parent-card-id="card.id"
        :cards="card.children"
        :showmoreState="showmoreState"
        @showmore="e => $emit('showmore', e)"
        @selectCard="e => $emit('selectCard', e)"
      />
    </div>
  </div>
</template>

<script>
import { niceCurrencyMaybeWithoutCents } from '@/helpers/Formatting'

export default {
  props: {
    card: Object,
    showmoreState: Array,
  },
  computed: {
    displayPrice() {
      return niceCurrencyMaybeWithoutCents(this.card.priceInCents)
    },
    displayFreeAmount() {
      return this.$t('giftcards.FreeAmountColon', { range: niceCurrencyMaybeWithoutCents(this.card.minFreeAmountInCents) + ' - ' + niceCurrencyMaybeWithoutCents(this.card.maxFreeAmountInCents) })
    },
  },
  methods: {
    handleClick() {
      if (this.card.type === 'giftcard') {
        this.$emit('selectCard', this.card)
      } else {
        this.$emit('showmore', this.card.id)
      }
    },
  },
}
</script>

<style lang="scss">
.category-sub-list:empty {
  background: #eee;
  border-radius: 3px;
}
.gift-card-listitem-in-create-order-wrapper {
  border-width: 2px;
  cursor: pointer;
  .gift-card-listitem-in-create-order-wrapper {
    border-width: 1px;
  }
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .item-image {
    width: 128px;
    height: 96px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    vertical-align: text-top;
  }
  .item-cols {
    width: 100%;
    display: flex;
    .item-col:last-child {
      flex-grow: 1;
    }
  }
  .item-description {
    font-size: 0.8em;
    margin-top: 1em;
  }
  .limitheight {
    max-height: 100px;
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      content: '';
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
      height: 40px;
    }
  }
}
.closeFullscreenModal {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
</style>
