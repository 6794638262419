<template>
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'user-edit' : 'user-plus'"
    :title="isEditing ? $t('users.EditUser') : $t('users.CreateNewUser')"
    :submittext="isEditing ? $t('general.Save') : $t('users.CreateNewUser')"
  >
    <mc-form-checkboxes
      :disabled="isEditing"
      name="role"
      :description="$t('users.UserTypeDescription')"
      v-model="curData.role"
      :label="$t('general.Role')"
      key="userform.role"
      :errorHint="errors.first('role')"
      :state="!errors.has('role')"
      :options="userRoles"
      :icon="['fad', 'user-tag']"
    />

    <mc-form-input
      name="name"
      type="text"
      :description="$t('users.NameDescription')"
      v-model="curData.name"
      v-validate="'required'"
      :data-vv-as="$t('general.Name')"
      :label="$t('general.Name')"
      :placeholder="$t('general.EnterName')"
      key="userform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
      :disabled="isEditing && !$perms.isAdministrator()"
    />

    <mc-form-input
      name="email"
      type="email"
      :description="!isEditing && !['qr', 'api'].includes(curData.role) ? $t('users.CreateDescriptionEmail') : ''"
      v-if="!['qr', 'api'].includes(curData.role)"
      v-model="curData.email"
      v-validate="'required|email'"
      :data-vv-as="$t('general.Email')"
      :label="$t('general.Email')"
      :placeholder="$t('general.EnterEmail')"
      key="userform.email"
      :errorHint="errors.first('email')"
      :state="!errors.has('email')"
      icon="at"
      :disabled="isEditing && !$perms.isAdministrator()"
    />

    <transitionToAuto v-if="['qr'].includes(curData.role) && (!isEditing || replaceQrIdentifier)" zoom key="userform.qrSelectorSection">
      <mc-form-checkboxes
        name="qrIdentifierMethod"
        :description="$t('users.QrIdentifierMethodDescription')"
        v-model="qrIdentifierMethod"
        key="userform.qrIdentifierMethod"
        inline
        :options="[
          { value: 'auto', label: $t('users.QrMethodAuto') },
          { value: 'manual', label: $t('users.QrMethodManual') },
        ]"
        :icon="['fad', 'user-tag']"
      />

      <transitionToAuto v-if="qrIdentifierMethod == 'manual'" zoom>
        <mc-form-input
          name="qrIdentifier"
          type="text"
          :description="$t('users.QrIdentifierDescription')"
          v-if="['qr'].includes(curData.role)"
          v-model="curData.qrIdentifier"
          v-validate="{ regex: /^[a-zA-Z0-9]{5,30}$/ }"
          :data-vv-as="$t('users.QrIdentifier')"
          :label="$t('users.QrIdentifier')"
          :placeholder="$t('users.EnterQrIdentifier')"
          key="userform.qrIdentifier"
          :errorHint="errors.first('qrIdentifier')"
          :state="!errors.has('qrIdentifier')"
          :sicon="['far', 'qrcode']"
        >
          <template #append>
            <mc-button @click="startQrScanner" :text="$t('general.QR')" variant="info" :icon="['far', 'qrcode']"></mc-button>
          </template>
        </mc-form-input>
      </transitionToAuto>
    </transitionToAuto>

    <transition-to-auto v-else-if="['qr'].includes(curData.role) && isEditing && !replaceQrIdentifier" key="userform.qrDisplaySection">
      <b-form-group :label="$t('users.QrCode')">
        <div class="userform-qr-example d-flex" style="align-items: center">
          <div class="text-center">
            <img class="" :src="qrPngUrl" />
            <div style="margin: -16px 0 0 0">
              <small>{{ curData.qrIdentifier }}</small>
            </div>
          </div>
          <div>
            <mc-button @click="replaceQrIdentifier = true" :text="$t('users.ReplaceQrIdentifier')" />
          </div>
        </div>
      </b-form-group>
    </transition-to-auto>

    <b-form-group v-if="curData.role === 'user'" :label="$t('general.Permissions')">
      <div class="merchantperms__wrapper">
        <div v-for="merchant in merchantList" v-bind:key="merchant.id">
          <div class="merchantperms__majorlabels">
            <label>
              {{ merchant.niceName }}
            </label>

            <label v-if="curData.id > 0 && curData.id == merchant.userId">
              {{ $t('permissions.IsAdministrator') }}
            </label>
            <label v-if="curData.id < 1 || curData.id != merchant.userId">
              <input type="radio" value="none" v-model="curData.permsMajor[merchant.id]" v-on:input="v => applyMajor(merchant.id, v.target.value)" :name="'permsMajor[' + merchant.id + ']'" />
              {{ $t('permissions.None') }}
            </label>
            <label v-if="curData.id < 1 || curData.id != merchant.userId">
              <input type="radio" value="all" v-model="curData.permsMajor[merchant.id]" v-on:input="v => applyMajor(merchant.id, v.target.value)" :name="'permsMajor[' + merchant.id + ']'" />
              {{ $t('permissions.All') }}
            </label>
            <label v-if="curData.id < 1 || curData.id != merchant.userId">
              <input type="radio" value="some" v-model="curData.permsMajor[merchant.id]" v-on:input="v => applyMajor(merchant.id, v.target.value)" :name="'permsMajor[' + merchant.id + ']'" />
              {{ $t('permissions.Some') }}
            </label>
            <!-- pre>{{ merchantList[id].perms }}</pre -->
          </div>

          <div v-if="curData.permsMajor[merchant.id] === 'some'" class="merchantperms__minorlabels">
            <label v-for="permName in merchant.perms" v-bind:key="merchant.id + 'pp' + permName">
              <input type="checkbox" :value="permName" v-model="curData.permissions[merchant.id][permName]" :name="'permsMinor[' + merchant.id + ']'" />
              {{ $t('permissions.' + permName) }}
            </label>
          </div>
        </div>
      </div>
    </b-form-group>

    <mc-form-checkboxes
      v-if="curData.role === 'qr' && qrpermoptions.length > 1"
      multiple
      required
      name="qrperms"
      v-model="curData.qrperms"
      v-validate="'required'"
      :data-vv-as="$t('users.ChoiceOfMerchants')"
      :label="$t('users.RedeemPermissionFor')"
      key="userform.qrperms"
      :errorHint="errors.first('qrperms')"
      :state="!errors.has('qrperms')"
      :options="qrpermoptions"
      :icon="['fad', 'shield-check']"
    />
    <mc-form-select
      v-if="curData.role === 'api' && apimerchantoptions.length > 1"
      required
      name="apimerchant"
      v-model="curData.apimerchant"
      v-validate="'required'"
      :data-vv-as="$t('users.ChoiceOfMerchant')"
      :label="$t('users.ChoiceOfMerchant')"
      key="userform.apimerchant"
      :errorHint="errors.first('apimerchant')"
      :state="!errors.has('apimerchant')"
      :options="apimerchantoptions"
      :icon="['fad', 'shield-check']"
    />
    <!--pre>{{ curData }}</pre-->
    <QrScannerNativePlusJsQr fullscreen ref="scanner" @code="handleQr" :error="qrError" />
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import UserService from '@/services/UserService'
import MerchantService from '@/services/MerchantService'
import QrService from '@/services/QrService'
import { isEmptyObject } from '@/helpers/Misc'

const defaultData = {
  id: -1,
  name: '',
  email: '',
  role: 'user',
  permissions: {},
  permsMajor: {},
  qrperms: [],
  apimerchant: null,
  qrIdentifier: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      loadingState: true,
      isEditing: false,
      merchantList: [],
      qrPngUrl: '',
      qrIdentifierMethod: 'auto',
      replaceQrIdentifier: false,
      qrError: '',
      storedErrors: {},
    }
  },
  computed: {
    qrpermoptions() {
      let perms = []
      for (var idx in this.merchantList) {
        const merchant = this.merchantList[idx]
        perms.push({ value: merchant.id, label: this.merchantList[idx].niceName })
      }
      return perms
    },
    apimerchantoptions() {
      let merchants = []
      for (var idx in this.merchantList) {
        const merchant = this.merchantList[idx]
        merchants.push({ value: merchant.id, label: this.merchantList[idx].niceName })
      }
      return merchants
    },
    userRoles() {
      let roles = []
      roles.push({ value: 'user', label: this.$t('users.UserTypeUser') })
      roles.push({ value: 'qr', label: this.$t('users.UserTypeQrOnly') })
      roles.push({ value: 'api', label: this.$t('users.UserTypeAPI') })
      if (this.$perms.isAdministrator()) {
        roles.push({ value: 'administrator', label: this.$t('users.UserTypeAdministrator') })
      }
      return roles
    },
  },
  props: {
    value: Object,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      newVal.permsMajor = {}
      newVal.permissions = {}

      newVal.qrperms = []
      for (var idx in this.merchantList) {
        const merchant = this.merchantList[idx]
        if (typeof val.assignedPermissions !== 'undefined' && typeof val.assignedPermissions[merchant.id] !== 'undefined') {
          if (val.assignedPermissions[merchant.id].markCodes) {
            newVal.qrperms.push(merchant.id)
          }
        }
      }

      if (!newVal.apimerchant || this.apimerchantoptions.length == 1) {
        newVal.apimerchant = this.apimerchantoptions[0].value
      }

      for (var idx in this.merchantList) {
        const merchant = this.merchantList[idx]
        let hasAll = true
        let hasSome = false
        newVal.permissions[merchant.id] = {}
        for (var pId in merchant.perms) {
          let permName = merchant.perms[pId]
          if (typeof val.assignedPermissions !== 'undefined' && typeof val.assignedPermissions[merchant.id] !== 'undefined') {
            newVal.permissions[merchant.id][permName] = val.assignedPermissions[merchant.id][permName]
            //console.log(id, permName, val.assignedPermissions[id][permName])
            if (val.assignedPermissions[merchant.id][permName]) {
              hasSome = true
            } else {
              hasAll = false
            }
          } else {
            hasAll = false
            newVal.permissions[merchant.id][permName] = false
          }
        }
        if (hasAll) {
          newVal.permsMajor[merchant.id] = 'all'
        } else {
          newVal.permsMajor[merchant.id] = hasSome ? 'some' : 'none'
        }
      }

      if (val.qrPng) {
        this.qrPngUrl = val.qrPng
      } else {
        this.qrPngUrl = ''
      }

      this.curData = newVal
    },
  },

  mounted() {
    MerchantService.list()
      .then(data => {
        this.merchantList = data
        defaultData.permissions = {}
        for (var idx in data) {
          const merchant = data[idx]
          defaultData.permsMajor[merchant.id] = 'none'
          defaultData.permissions[merchant.id] = {}
          for (var pId in merchant.perms) {
            let permName = merchant.perms[pId]
            defaultData.permissions[merchant.id][permName] = false
          }
        }
        this.loadingState = false
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    applyMajor(merchantId, newVal) {
      for (var idx in this.merchantList) {
        const merchant = this.merchantList[idx]
        if (merchant.id != merchantId) {
          continue
        }
        for (var pId in merchant.perms) {
          let permName = merchant.perms[pId]
          if (newVal === 'all') {
            this.curData.permissions[merchantId][permName] = true
          } else if (newVal === 'none') {
            this.curData.permissions[merchantId][permName] = false
          }
        }
      }
    },

    handleReset() {
      this.curData = { ...defaultData }
      this.qrIdentifierMethod = 'auto'
      this.replaceQrIdentifier = false
    },

    handleSubmit(e) {
      var action = UserService.create
      if (this.curData.id > 0) {
        action = UserService.update
      }

      let postData = { ...this.curData }
      delete postData['permsMajor']
      if (this.curData.role == 'user') {
        delete postData.qrperms
        postData.permissions = {}
        let hasPermissions = false
        for (let id in this.curData.permissions) {
          for (let permName in this.curData.permissions[id]) {
            if (this.curData.permissions[id][permName]) {
              postData.permissions[id] = postData.permissions[id] ? postData.permissions[id] : []
              postData.permissions[id].push(permName)
              hasPermissions = true
            }
          }
        }
        if (!hasPermissions && !this.$perms.isAdministrator()) {
          return Promise.reject(this.$t(this.isEditing ? 'permissions.PermissionsRequiredWhenEditing' : 'permissions.PermissionsRequired'))
        }
      } else if (this.curData.role == 'qr') {
        delete postData.permissions
        if (this.qrpermoptions.length == 1) {
          postData.qrperms = [this.qrpermoptions[0].value]
        }
        if (!this.replaceQrIdentifier && this.isEditing) {
          delete postData.qrIdentifier
        } else if (this.qrIdentifierMethod == 'auto') {
          postData.qrIdentifier = 'auto'
        }
      } else {
        delete postData.permissions
        delete postData.qrperms
      }

      return action(postData).then(
        data => {
          if (data.message && data.message === 'userExistedPermissionsAssigned') {
            this.toastSuccess(this.$t('users.SuccessUserExistedPermissionsAssigned'))
          } else if (this.curData.id > 0) {
            this.toastSuccess(this.$t('users.SuccessUpdate'))
          } else if (this.curData.role == 'qr') {
            this.toastSuccess(this.$t('users.SuccessCreateQr'))
          } else if (this.curData.role == 'api') {
            this.toastSuccess(this.$t('users.SuccessCreateApi'))
          } else {
            this.toastSuccess(this.$t('users.SuccessCreate'))
          }
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving contact', error)
          return Promise.reject(error)
        },
      )
    },
    startQrScanner() {
      this.qrError = ''
      this.storedErrors = {}
      this.$refs['scanner'].start()
    },
    handleQr(data) {
      if (!data) {
        this.qrError = ''
        return
      }
      let matches = data.match(/(?:d\.gkort\.dk|qr\.danskegavekort\.dk|panel\.danskegavekort\.dk\/qr|192\.168\.0\.[0-9]+.*)\/(l.{5,30})$/)
      if (!matches) {
        this.qrError = this.$t('qrReader.NotValidLoginQR')
        return
      }
      const token = matches[1]
      if (this.storedErrors[token]) {
        if (this.storedErrors[token] == 'notAvailable') {
          this.qrError = this.$t('users.NotAvailableLoginQR')
        } else {
          this.qrError = FormatError(this.storedErrors[token])
        }
        return
      }
      this.qrError = this.$t('general.OneMoment')
      QrService.isAvailable(token).then(
        data => {
          if (data.available) {
            this.curData.qrIdentifier = data.identifier
            this.$refs['scanner'].stop()
            return Promise.resolve(data)
          } else {
            this.qrError = this.$t('users.NotAvailableLoginQR')
            this.storedErrors[token] = 'notAvailable'
            console.log('not')
            return Promise.resolve(data)
          }
        },
        error => {
          this.qrError = FormatError(error)
          this.storedErrors[token] = error
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style lang="scss">
.merchantperms__wrapper {
  border: 1px solid #888;
  border-radius: 3px;
  overflow: auto;
  max-height: 200px;
  > div {
    padding: 0 1em;
    &:nth-child(odd) {
      background: #eee;
    }
  }
}
.merchantperms__majorlabels {
  display: flex;
  label {
    padding: 0 0.5em;
    flex-grow: 0;
    white-space: nowrap;
    &:first-child {
      white-space: normal;
      padding-left: 0;
      flex-grow: 1;
      float: left;
    }
  }
}
.merchantperms__minorlabels {
  width: auto;
  padding: 0 0 1em 4em;
  font-size: 0.9em;
  label {
    display: block;
    padding: 0;
    margin: 0;
  }
}
.userform-qr-example {
  position: relative;
  width: 100%;
  max-width: 400px;
  > div {
    width: 50%;
    max-width: 200px;
  }
  img {
    width: 100%;
  }
}
</style>
