import Vue from 'vue'

const moment = require('moment')
require('moment/locale/da')
require('moment/locale/de')
require('moment/locale/fr')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment,
})
