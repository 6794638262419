import { authorizedApiRequest } from '@/setup/ApiService'

export default {
  get: function (merchant) {
    return authorizedApiRequest(
      '/giftcards/' + merchant, //
      'GET',
    )
  },

  create: function (merchant, giftcard) {
    return authorizedApiRequest(
      '/giftcards/' + merchant, //
      'POST',
      giftcard,
    )
  },

  clone: function (merchant, giftcard) {
    return authorizedApiRequest(
      '/giftcards/' + merchant + '/' + giftcard.id + '/clone', //
      'POST',
    )
  },

  designPreview: function (merchant, giftcard, design) {
    return authorizedApiRequest(
      '/giftcards/' + merchant + '/' + giftcard.id + '/design-preview', //
      'POST',
      {
        design,
      },
    )
  },

  setDefaultDesign: function (merchant, design) {
    return authorizedApiRequest(
      '/giftcards/' + merchant + '/default-design', //
      'POST',
      {
        design,
      },
    )
  },

  update: function (merchant, giftcard) {
    return authorizedApiRequest(
      '/giftcards/' + merchant + '/' + giftcard.id, //
      'PUT',
      giftcard,
    )
  },

  updateMultiple: function (merchant, giftcardIds, design) {
    return authorizedApiRequest(
      '/giftcards/' + merchant + '/update-multiple', //
      'POST',
      {
        giftcardIds,
        design,
      },
    )
  },

  setParent: function (merchant, giftcard, parentId) {
    return authorizedApiRequest(
      '/giftcards/' + merchant + '/' + giftcard.id + '/parent', //
      'PUT',
      { parentId },
    )
  },

  orderChildren: function (merchant, giftcard, order) {
    return authorizedApiRequest(
      '/giftcards/' + merchant + '/' + giftcard.id + '/order-children', //
      'PUT',
      { order },
    )
  },

  delete: function (merchant, giftcard) {
    return authorizedApiRequest(
      '/giftcards/' + merchant + '/' + giftcard.id, //
      'DELETE',
    )
  },
}
