import { authorizedApiRequest, queryParameters } from '@/setup/ApiService'

export default {
  list: function () {
    return authorizedApiRequest(
      '/merchants/list', //
      'GET',
    )
  },

  query: function (params) {
    return authorizedApiRequest(
      '/merchants?' + queryParameters(params), //
      'GET',
    )
  },

  create: function (merchant) {
    return authorizedApiRequest(
      `/merchants`, //
      'POST',
      merchant,
    )
  },

  update: function (merchant) {
    return authorizedApiRequest(
      `/merchants/${merchant.id}`, //
      'PUT',
      merchant,
    )
  },

  delete: function (id) {
    return authorizedApiRequest(
      `/merchants/${id}`, //
      'DELETE',
    )
  },

  addDomain: function (merchantId, domain) {
    return authorizedApiRequest(
      `/merchants/${merchantId}/domains`, //
      'POST',
      {
        domain,
      },
    )
  },

  deleteDomain: function (merchantId, domain) {
    return authorizedApiRequest(
      `/merchants/${merchantId}/domains/${domain}`, //
      'DELETE',
    )
  },

  getDomains: function (id) {
    return authorizedApiRequest(
      `/merchants/${id}/domains`, //
      'GET',
    )
  },

  users: function (id) {
    return authorizedApiRequest(
      `/merchants/${id}/users`, //
      'GET',
    )
  },
}
