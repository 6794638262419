<template>
  <draggable class="gift-card-list-drag-area" tag="div" :list="cards" swapThreshold=".65" fallbackOnBody animation="100" @end="e => $emit('sort-end', e)" :group="{ name: 'g1' }">
    <!-- eslint-disable-next-line -->
    <GiftCardListItem
      v-for="card in cards"
      v-bind:key="card.id"
      :card="card"
      @edit-giftcard="editGiftcard"
      @edit-design="editDesign"
      @refresh-cards="refreshCards"
      @sort-end="e => $emit('sort-end', e)"
      :merchantId="merchantId"
      :merchant="merchant"
    />
  </draggable>
</template>

<script>
export default {
  props: {
    merchantId: Number,
    merchant: Object,
    cards: Array,
  },
  methods: {
    editGiftcard(giftcard) {
      this.$emit('edit-giftcard', giftcard)
    },
    editDesign(giftcard) {
      this.$emit('edit-design', giftcard)
    },
    refreshCards() {
      this.$emit('refresh-cards')
    },
  },
}
</script>

<style lang="scss">
.gift-card-list-drag-area {
  min-height: 20px;
  > div {
    cursor: move;
  }

  .sortable-ghost {
    background: rgba(0, 255, 255, 0.3);
  }
}
</style>
