import { render, staticRenderFns } from "./GiftCardListItemInCreateOrder.vue?vue&type=template&id=6d24d547&"
import script from "./GiftCardListItemInCreateOrder.vue?vue&type=script&lang=js&"
export * from "./GiftCardListItemInCreateOrder.vue?vue&type=script&lang=js&"
import style0 from "./GiftCardListItemInCreateOrder.vue?vue&type=style&index=0&id=6d24d547&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports