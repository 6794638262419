<template>
  <component v-bind="$attrs" :is="isModal ? 'b-modal' : 'div'" ref="formwrap" :title="title">
    <McSpinner v-if="loading === true" large class="my-5" />

    <slot v-if="typeof loading === 'string'" name="errorcontent">
      <McAlert v-if="typeof loading === 'string'">
        {{ loading }}
      </McAlert>
    </slot>

    <b-form v-if="loading === false" @submit.prevent="handleSubmit" novalidate ref="actualform" :key="resetCount">
      <slot></slot>
      <McAlert @dismissed="showformerror = false" v-if="showformerror != false">
        {{ showformerror }}
      </McAlert>
      <McAlert type="success" @dismissed="showformsuccess = false" v-if="typeof successText === 'string' && showformsuccess != false">
        {{ showformsuccess }}
      </McAlert>
      <b-form-group v-if="!isModal" :class="'formButtons' + (buttonsOnRight ? ' buttonsOnRight' : '')">
        <McButton v-if="!buttonsOnRight" type="submit" :icon="submiticon" :active="submitted" :text="submittext || $t('general.Save')" variant="primary" />
        <slot name="secondarybuttons"></slot>
        <McButton v-if="hasReset" @click="forceReset()" :text="$t('general.Reset')" variant="link" />
        <McButton v-if="buttonsOnRight" type="submit" :icon="submiticon" :active="submitted" :text="submittext || $t('general.Save')" variant="primary" />
      </b-form-group>
    </b-form>
    <template v-if="isModal" slot="modal-footer">
      <template v-if="loading === false">
        <slot name="secondarybuttons">
          <b-button v-if="hasCancel" variant="link" @click="hideModal()">{{ $t('general.Cancel') }}</b-button>
        </slot>
        <McButton v-if="hasReset" @click="forceReset()" :text="$t('general.Reset')" variant="link" />
        <McButton @click="handleSubmit" type="button" :icon="submiticon" :active="submitted" :text="submittext || $t('general.Save')" variant="primary" />
      </template>
      <template v-else>
        <b-button variant="primary" @click="hideModal()">{{ $t('general.Cancel') }}</b-button>
      </template>
    </template>
  </component>
</template>

<style lang="scss">
.formButtons.buttonsOnRight {
  text-align: right;
}
.col-form-label {
  font-size: 12px;
  padding-bottom: 0;
}
</style>

<script>
import { FormatError } from '@/helpers/ErrorFormatting'
export default {
  data() {
    return {
      showformerror: false,
      showformsuccess: false,
      submitted: false,
      resetCount: 0,
    }
  },
  props: {
    title: String,
    isModal: Boolean,
    keepOpen: Boolean,
    hasReset: Boolean,
    hasCancel: {
      type: Boolean,
      default: true,
    },
    onSubmit: Function,
    buttonsOnRight: Boolean,
    successText: {
      type: [String, Boolean],
      default: '',
    },
    submittext: {
      type: String,
      default: '',
    },
    submiticon: {
      type: [String, Array],
      default: 'save',
    },
    loading: {
      type: [String, Boolean],
      default: false,
    },
  },
  created() {
    //Don't show validation until first submit
    this.$validator.pause()
  },
  inject: {
    $validator: '$validator',
  },
  methods: {
    forceReset() {
      this.$emit('reset')
      this.$validator.pause()
      this.showformerror = ''
      this.showformsuccess = ''
      this.errors.clear()
    },
    setSubmitted(isSubmitted) {
      this.submitted = isSubmitted
      this.$emit('submitted', isSubmitted)
    },
    hideModal() {
      if (this.isModal) {
        this.$refs.formwrap.hide()
      }
    },
    handleSubmit(e) {
      //Do nothing if already submitting
      if (this.submitted) return
      this.setSubmitted(true)
      this.showformerror = ''
      this.showformsuccess = ''

      //Enable validation
      this.$validator.resume()

      //Validate everything
      this.$validator
        .validateAll()
        .then(result => {
          if (result) {
            //Fire actual form action, making sure a promise is returned
            return Promise.resolve(
              this.onSubmit().then(() => {
                if (!this.keepOpen) {
                  this.hideModal()
                  this.forceReset()
                }
                this.showformsuccess = this.successText ? this.successText : this.$t('general.ChangesSaved')
                this.$emit('completed', true)
              }),
            )
          } else {
            //We have a validation error
            return Promise.reject({ message: 'error.GeneralFormError' })
          }
        })
        .catch(error => {
          if (this.$validator.errors.all().length > 0) {
            this.showformerror = this.$tc('error.GeneralFormError', this.$validator.errors.all().length)
          } else {
            this.showformerror = FormatError(error)
          }
        })
        .then(() => {
          // This will always run
          this.setSubmitted(false)
        })
    },
  },
}
</script>
