import Vue from 'vue'
import Vuex from 'vuex'

import LoginStore from '@/store/LoginStore'
import ToastStore from '@/store/ToastStore'
import LayoutStore from '@/store/LayoutStore'
import LanguageStore from '@/store/LanguageStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    LoginStore,
    ToastStore,
    LayoutStore,
    LanguageStore,
  },
})
