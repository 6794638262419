import { authorizedApiRequest, queryParameters } from '@/setup/ApiService'

export default {
  redeem: function (code, amount) {
    return authorizedApiRequest(
      '/codes/' + code + '/redeem', //
      'POST',
      {
        amount,
      },
    )
  },

  extend: function (code, expiry) {
    return authorizedApiRequest(
      '/codes/' + code + '/extend', //
      'POST',
      {
        expiry,
      },
    )
  },

  search: function (search) {
    return authorizedApiRequest(
      '/codes/search?' + queryParameters({ search }), //
      'GET',
    )
  },

  searchQR: function (token) {
    return authorizedApiRequest(
      '/codes/search-qr?' + queryParameters({ token }), //
      'GET',
    )
  },

  overview: function (merchantId, includeTestMode) {
    let url = '/codes' + (merchantId ? '/' + merchantId : '') + '/overview'
    if (includeTestMode !== null) {
      url += '?' + queryParameters({ includeTestMode: includeTestMode ? 1 : 0 })
    }
    return authorizedApiRequest(
      url, //
      'GET',
    )
  },
}
