<template>
  <McForm
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    :onSubmit="handleSubmit"
    submiticon="unlock"
    :submittext="$route.params.action == 'choose' ? $t('resetform.ChoosePassword') : $t('resetform.ResetPassword')"
  >
    <mc-form-input
      name="password"
      type="password"
      v-model="curData.password"
      v-validate="'required|min:6'"
      :placeholder="$t('general.ChooseNewPassword')"
      key="resetform.password"
      :data-vv-as="$t('general.Password')"
      :errorHint="errors.first('password')"
      :state="!errors.has('password')"
      icon="key"
    />

    <mc-form-input
      name="passwordRepeat"
      type="password"
      v-model="curData.password2"
      v-validate="{ required: true, is: curData.password }"
      :placeholder="$t('general.EnterPasswordAgain')"
      key="resetform.passwordRepeat"
      :data-vv-as="$t('general.PasswordRepeat')"
      :errorHint="errors.first('passwordRepeat')"
      :state="!errors.has('passwordRepeat')"
      icon="key"
    />

    <template v-slot:secondarybuttons>
      <b-button to="/login" variant="link">{{ $t('general.backToLoginform') }}</b-button>
    </template>
    <template v-slot:errorcontent>
      <McAlert>
        {{ loadingState }}
      </McAlert>
      <b-button class="mt-4" to="/login" variant="primary">{{ $t('general.backToLoginform') }}</b-button>
    </template>
  </McForm>
</template>

<script>
import Router from '@/setup/Router'
import { FormatError } from '@/helpers/ErrorFormatting'
import { mapActions } from 'vuex'
import AuthenticationService from '@/services/AuthenticationService'

const defaultData = {
  password: '',
  password2: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      loadingState: true,
    }
  },
  created() {
    // Log out when opening this form
    this.logout()
    this.checkConfirmationToken(this.$route.params.token)
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    ...mapActions('LoginStore', [
      'logout', //
    ]),

    checkConfirmationToken(token) {
      let purpose = 'resetPassword'
      if (this.$route.params.action === 'choose') {
        purpose = 'choosePassword'
      }
      return new Promise((resolve, reject) => {
        AuthenticationService.checkConfirmationToken(token, purpose)
          .then(data => {
            this.loadingState = false
            resolve(data)
          })
          .catch(error => {
            this.loadingState = FormatError(error)
          })
      })
    },

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return AuthenticationService.changePasswordUsingToken(this.$route.params.token, this.curData.password).then(
        data => {
          Router.push('/login')
          this.toastSuccess(this.$t('resetform.Success2'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error resetting password', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
