<template>
  <div v-if="completed">
    <p>
      <strong>{{ $t('createaccountform.Success1', completed) }}</strong>
    </p>
    <p>{{ $t('createaccountform.Success2', completed) }}</p>
  </div>
  <div v-else>
    <McForm v-bind="$attrs" @reset="handleReset" :onSubmit="handleSubmit" submiticon="unlock" :submittext="$t('general.CreateAccount')">
      <mc-form-input
        name="name"
        type="text"
        v-model="curData.name"
        v-validate="'required'"
        :data-vv-as="$t('general.Name')"
        :placeholder="$t('general.EnterYourName')"
        key="createaccountform.name"
        :errorHint="errors.first('name')"
        :state="!errors.has('name')"
        icon="signature"
      />

      <mc-form-input
        name="email"
        type="email"
        v-model="curData.email"
        v-validate="'required|email'"
        :data-vv-as="$t('general.Email')"
        :placeholder="$t('general.EnterEmail')"
        key="createaccountform.email"
        :errorHint="errors.first('email')"
        :state="!errors.has('email')"
        icon="at"
      />

      <mc-form-input
        name="password"
        type="password"
        v-model="curData.password"
        v-validate="'required|min:6'"
        :data-vv-as="$t('general.Password')"
        :placeholder="$t('general.ChooseNewPassword')"
        key="createaccountform.password"
        :errorHint="errors.first('password')"
        :state="!errors.has('password')"
        icon="key"
      />

      <mc-form-input
        name="password2"
        type="password"
        v-model="curData.password2"
        v-validate="{ required: true, is: curData.password }"
        :data-vv-as="$t('general.PasswordRepeat')"
        :placeholder="$t('general.EnterPasswordAgain')"
        key="createaccountform.password2"
        :errorHint="errors.first('password2')"
        :state="!errors.has('password2')"
        icon="key"
      />

      <template v-slot:secondarybuttons>
        <b-button to="/login" variant="link">{{ $t('general.backToLoginform') }}</b-button>
      </template>
    </McForm>
  </div>
</template>

<script>
import ProfileService from '@/services/ProfileService'
import { mapState, mapActions } from 'vuex'

const defaultData = {
  name: '',
  email: '',
  password: '',
  password2: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      completed: false,
    }
  },
  computed: {
    ...mapState({
      LanguageStore: state => state.LanguageStore,
    }),
  },
  created() {
    // Log out when opening this form
    this.logout()
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('LoginStore', [
      'logout', //
    ]),
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return ProfileService.create({
        name: this.curData.name,
        email: this.curData.email,
        password: this.curData.password,
      }).then(
        data => {
          this.completed = { ...this.curData }
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating user', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
