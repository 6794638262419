<template>
  <ul>
    <GiftCardListItemInMultisave v-for="(card, key) in cards" v-bind:key="key" :curcard="curcard" :card="card" v-model="selected[card.id]" />
    <!--li><pre>{{ selected }}</pre></li-->
  </ul>
</template>

<script>
export default {
  data() {
    return {
      selected: {},
    }
  },
  props: {
    cards: Array,
    curcard: Number,
  },
  watch: {
    selected: {
      deep: true,
      handler: function (val) {
        this.$emit('input', this.flattenSelection(val))
      }
    },
  },
  methods: {
    flattenSelection(recursiveArray) {
      let ret = []
      //console.log(recursiveArray)
      for (var i in recursiveArray) {
        if (recursiveArray[i] === true) {
          ret.push(parseInt(i))
        } else if (recursiveArray[i] === false) {
          //ret.push(i)
        } else if (typeof recursiveArray[i] == 'object') {
          let flatChildren = this.flattenSelection(recursiveArray[i])
          for (var n in flatChildren) {
            ret.push(parseInt(flatChildren[n]))
          }
        }
      }
      if (!ret.includes(this.curcard)) {
        ret.push(this.curcard)
      }
      return ret
    },
  }
}
</script>
