<template>
  <b-modal :id="$id('refundModal')" :title="$t('orders.RefundOrder')" @ok="sendRefund">
    <p v-if="currentBalance == 0">{{ $t('orders.CannotRefundFullyUsed') }}</p>
    <div v-else>
      <p>{{ $t('orders.RefundDescription') }}</p>
      <p v-if="originalBalance == currentBalance" v-html="$t('orders.RefundUnused', { originalBalance: displayOriginal })" />
      <p v-else v-html="$t('orders.RefundPartiallyUsed', { originalBalance: displayOriginal, currentBalance: displayCurrent })" />
      <p v-if="orderInfo.testmode">{{ $t('orders.CannotRefundTestMode') }}</p>
      <div v-else-if="orderInfo.method === 'webshop'">
        <mc-form-checkbox v-model="refundPayment">{{ $t('orders.RefundPaymentAmount', { amount: displayCurrent }) }}</mc-form-checkbox>
      </div>
      <p v-else-if="orderInfo.method === 'manual' || orderInfo.method === 'offline'">{{ $t('orders.CannotRefundManual') }}</p>
      <p v-else-if="['integration', 'import'].includes(orderInfo.method)">{{ $t('orders.CannotRefundIntegration') }}</p>
      <p v-html="$t('orders.RefundNoCommissionRefund')" />
    </div>
    <!--pre>{{ orderInfo }}</pre-->
    <template #modal-footer="{ ok, cancel }">
      <b-button @click="cancel()" :variant="currentBalance == 0 ? 'primary' : 'secondary'">{{ $t('general.Cancel') }}</b-button>
      <b-button v-if="currentBalance > 0" @click="ok()" variant="primary">Ok</b-button>
    </template>
  </b-modal>
</template>

<script>
import { FormatError } from '@/helpers/ErrorFormatting'
import OrderService from '@/services/OrderService'
import { niceCurrency } from '@/helpers/Formatting'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      refundPayment: false,
      orderInfo: null,
    }
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
      'toastWarning',
    ]),
    show(orderInfo) {
      this.$bvModal.show(this.$id('refundModal'))
      this.orderInfo = orderInfo
    },
    sendRefund(e) {
      if (!this.$perms.isAdministrator() && !this.$perms.hasPermission('refundOrders', this.orderInfo.merchantId)) {
        this.toastWarning(this.$t('permissions.LackingMarkCodesPermission'))
        return
      }
      OrderService.refund(this.orderInfo.id, this.currentBalance, this.refundPayment).then(
        data => {
          this.toastSuccess(this.$t('orders.OrderRefunded'))
          this.$emit('refunded')
          return Promise.resolve(data)
        },
        error => {
          alert(FormatError(error))
          return Promise.reject(error)
        },
      )
    },
  },
  computed: {
    originalBalance() {
      if (!this.orderInfo) {
        return 0
      }
      return this.orderInfo.amount
    },
    currentBalance() {
      if (!this.orderInfo) {
        return 0
      }
      let balance = 0
      for (var i in this.orderInfo.certificates) {
        balance += this.orderInfo.certificates[i].balance
      }
      return balance
    },
    displayOriginal() {
      return niceCurrency(this.originalBalance)
    },
    displayCurrent() {
      return niceCurrency(this.currentBalance)
    },
  },
  filters: {
    niceCurrency(amountInCents) {
      return niceCurrency(amountInCents)
    },
  },
}
</script>
