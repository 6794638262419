<template>
  <div v-if="element" class="designer-element-editor d-flex flex-column h-100" @click="e => $emit('click', e)">
    <div class="p-2" style="flex-grow: 1">
      <!-- prettier-ignore -->
      <mc-form-input
        type="text"
        v-model="element.label"
        :label="$t('designer.ElementLabel')"
        :placeholder="$t('designer.EnterLabel')"
        icon="signature"
        :description="$t('designer.LabelDescription')"
        ref="label"
      />
      <DesignerEditorShape v-if="element.type === 'shape'" :element="element" :pixelsPerMm="pixelsPerMm" />
      <DesignerEditorQr v-else-if="element.type === 'qr'" :element="element" :pixelsPerMm="pixelsPerMm" />
      <DesignerEditorImage v-else-if="element.type === 'image'" @change-prop="data => $emit('change-prop', data)" :element="element" :pixelsPerMm="pixelsPerMm" />
      <DesignerEditorTextarea v-else-if="['textarea', 'valexp'].includes(element.type)" @moved="position => $emit('moved', position)" :element="element" :pixelsPerMm="pixelsPerMm" />
      <!--pre v-else style="width: 100%;height:100%;overflow:scroll;">{{ element }}</pre-->
      <DesignerEditorPosition :position="element.position" @moved="position => $emit('moved', position)" :pixelsPerMm="pixelsPerMm" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    active: Boolean,
    pixelsPerMm: Number,
  },
  data() {
    return {}
  },
  methods: {
    focusOnLabel() {
      let el = this.$refs['label'].$el.querySelector('input')
      el.focus()
      el.select()
    },
  },
  watch: {},
}
</script>
