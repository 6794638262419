<template>
  <div class="center_checkbalance">
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorInfo">
      <h2>{{ $t('general.Error') }}</h2>
      <p>{{ errorInfo }}</p>
    </div>
    <CheckBalance v-else-if="qrData.type == 'code'" :qrData="qrData"></CheckBalance>
  </div>
</template>

<style scoped>
.center_checkbalance {
  max-width: 620px;
  margin: 20px auto;
  padding: 0 1em;
}
</style>

<script>
import { FormatError } from '@/helpers/ErrorFormatting'
import { mapActions } from 'vuex'
import QrService from '@/services/QrService'
import store from '@/setup/Store'
import Router from '@/setup/Router'

export default {
  data() {
    return {
      loadingState: true,
      errorInfo: null,
      qrData: null,
    }
  },
  created() {
    this.checkToken(this.$route.query.returned)
    this.$store.dispatch('LayoutStore/hideFooter')
    this.$store.dispatch('LayoutStore/hideHeader')
  },
  destroyed() {
    this.$store.dispatch('LayoutStore/showFooter')
    this.$store.dispatch('LayoutStore/showHeader')
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
      'toastDanger',
    ]),
    checkToken(autoContinue) {
      let token = this.$route.params.token
      return new Promise((resolve, reject) => {
        QrService.checkQr(token)
          .then(data => {
            if (data.type == 'login') {
              store.commit('LoginStore/loginSuccess', data.userInfo)
              Router.push('/')
            }
            data.autoContinue = autoContinue
            this.qrData = data
            this.loadingState = false
          })
          .catch(error => {
            if (token.match(/^l.*/)) {
              this.toastDanger(FormatError(error))
              Router.push('/login')
            } else {
              this.errorInfo = FormatError(error)
              this.loadingState = false
            }
          })
      })
    },
  },
}
</script>
