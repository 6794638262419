const apiUrl = 'https://api.danskegavekort.dk'

import VueCookies from 'vue-cookies'
import Store from '@/setup/Store'
import { userTimeZone } from '@/helpers/Time'

function AuthHeader() {
  let token = VueCookies.get('token')

  if (token) {
    return {
      Authorization: 'Bearer ' + token,
    }
  } else {
    return {}
  }
}

function languageAndTimezone() {
  return {
    language: Store.state.LanguageStore.currentLanguage,
    timezone: userTimeZone(),
  }
}

export function apiUpload(endpoint, method, file, additionalHeaders = {}) {
  let requestOptions = {
    method: method,
    headers: { Accept: 'application/json', ...additionalHeaders },
  }
  var body = new FormData()
  body.append('file', file)
  requestOptions.body = body
  return fetch(`${apiUrl}${endpoint}`, requestOptions)
    .then(handleResponse)
    .catch(error => {
      console.log('Error in apiUpload', error)
      throw error
    })
}

export function authorizedApiUpload(endpoint, method, file, additionalHeaders = {}) {
  return apiUpload(endpoint, method, file, { ...AuthHeader(), ...additionalHeaders })
}

export function apiRequest(endpoint, method, body, additionalHeaders = {}) {
  let bodyWithLang = false
  if (['PUT', 'POST'].includes(method)) {
    bodyWithLang = {
      ...languageAndTimezone(),
      ...body,
    }
  }
  let requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...additionalHeaders },
  }
  if (bodyWithLang) {
    requestOptions.body = JSON.stringify(bodyWithLang)
  }
  return fetch(`${apiUrl}${endpoint}`, requestOptions)
    .then(handleResponse)
    .catch(error => {
      console.log('Error in apiRequest', error)
      throw error
    })
}

export function authorizedApiRequest(endpoint, method, body, additionalHeaders = {}) {
  return apiRequest(endpoint, method, body, { ...AuthHeader(), ...additionalHeaders })
}

export function queryParameters(params) {
  return Object.keys(params)
    .filter(k => k !== 'apiUrl' && params[k] !== null)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (response.status === 200) {
      if (data.newToken) {
        //The "Remember" setting is stored in localstorage as
        //we may receive a new token at any point...
        //The token itself must be a cookie to auto-delete
        //on session end. Localstorage persists and
        //sessionstorage does not span different tabs.
        if (parseInt(localStorage.getItem('remember')) == 1) {
          VueCookies.set('token', data.newToken, '30d')
        } else {
          VueCookies.set('token', data.newToken, '0')
        }
      }
    } else {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        Store.dispatch('LoginStore/logout')
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
