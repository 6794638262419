import Vue from 'vue'

//Our own messages
import validationMessagesEn from '@/language/en-validations'
import validationMessagesDa from '@/language/da-validations'

//Import the minimal version without rules and messages
import { Validator, install as VeeValidate } from 'vee-validate/dist/vee-validate.minimal.esm.js'
import { required, email, min, max, is, length, numeric, decimal, confirmed, regex, min_value, max_value, url } from 'vee-validate/dist/rules.esm.js'

// Add the rules we need.
Validator.extend('required', required)
Validator.extend('email', email)
Validator.extend('min', min)
Validator.extend('max', max)
Validator.extend('is', is)
Validator.extend('confirmed', confirmed)
Validator.extend('length', length)
Validator.extend('numeric', numeric)
Validator.extend('decimal', decimal)
Validator.extend('regex', regex)
Validator.extend('min_value', min_value)
Validator.extend('max_value', max_value)
Validator.extend('url', url)

// TODO: Remove custom rule test
const myCustomRuleParamNames = ['first', 'second']
const myCustomRuleValidator = (value, { first, second } = {}) => {
  return first === second ? false : false
}
Validator.extend('truthy', myCustomRuleValidator, { myCustomRuleParamNames })

// Register plugin...
Vue.use(VeeValidate, {
  locale: 'en',
  dictionary: {
    en: validationMessagesEn,
    da: validationMessagesDa,
  },

  // we'll instantiate manually
  inject: false,

  // default conflicts, set non-standard
  fieldsBagName: 'veeFields',
})
