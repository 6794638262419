import { i18n } from '@/setup/i18n'

const inflateMessage = function (message, type) {
  let returnval = {
    type: type,
    body: typeof message === 'string' ? message : message.body,
    timeout: message.timeout ? message.timeout : 15,
    timein: new Date(),
  }
  if (message.timeout === false) {
    returnval.timeout = false
  }
  if (type === 'light') {
    returnval.heading = message.heading ? message.heading : false
  } else {
    returnval.heading = message.heading ? message.heading : i18n.t('toast.' + type)
  }
  if (message.icon) {
    returnval.icon = message.icon
  } else {
    switch (type) {
      case 'warning':
        returnval.icon = 'exclamation-triangle'
        break
      case 'danger':
        returnval.icon = 'radiation'
        break
      case 'info':
        returnval.icon = 'info-circle'
        break
      default:
        returnval.icon = 'check-circle'
        break
    }
  }

  return returnval
}

const state = {
  messages: {},
  messageCounter: 0,
}

const actions = {
  toastSimple({ commit }, message) {
    commit('add', inflateMessage(message, 'light'))
  },
  toastSuccess({ commit }, message) {
    commit('add', inflateMessage(message, 'success'))
  },
  toastDanger({ commit }, message) {
    commit('add', inflateMessage(message, 'danger'))
  },
  toastWarning({ commit }, message) {
    commit('add', inflateMessage(message, 'warning'))
  },
  toastInfo({ commit }, message) {
    commit('add', inflateMessage(message, 'info'))
  },
  toastClear({ commit }, id) {
    commit('clear', id)
  },
}

const mutations = {
  add(state, message) {
    state.messageCounter++
    state.messages = { ...state.messages, [state.messageCounter]: message }
  },
  clear(state, id) {
    let newMessages = { ...state.messages }
    delete newMessages[id]
    state.messages = newMessages
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
