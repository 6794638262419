<template>
  <McForm v-bind="$attrs" @reset="handleReset" :onSubmit="handleSubmit" submiticon="unlock" :submittext="$t('resetform.SendResetLink')">
    <mc-form-input
      name="email"
      type="email"
      v-model="curData.email"
      v-validate="'required|email'"
      :placeholder="$t('general.EnterEmail')"
      key="resetform.email"
      :errorHint="errors.first('email')"
      :state="!errors.has('email')"
      icon="at"
    />

    <template v-slot:secondarybuttons>
      <b-button to="/login" variant="link">{{ $t('general.backToLoginform') }}</b-button>
    </template>
  </McForm>
</template>

<script>
import Router from '@/setup/Router'
import { mapActions } from 'vuex'
import AuthenticationService from '@/services/AuthenticationService'

const defaultData = {
  email: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
    }
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return AuthenticationService.requestPasswordResetMail(this.curData.email).then(
        data => {
          Router.push('/login')
          this.toastSuccess(this.$t('resetform.Success', { email: data.email }))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error resetting password', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
