import Store from '@/setup/Store'
import moment from 'moment-timezone'

/**
 * @param {Number|String} size
 */
export const FormatFileSize = size => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const threshold = 1024
  size = Number(size) * threshold
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold))
  return `${(size / Math.pow(threshold, i)).toFixed(2) * 1} ${units[i]}`
}
export const currencyLikePdf = function (cents) {
  const num = new Intl.NumberFormat('da', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(cents / 100)
    .replace(',00', ',-')
  return 'DKK ' + num
}
export const dateLikePdf = function (date) {
  return moment(date).format("D/M 'YY")
}
export const niceCurrency = function (cents, currency = 'DKK') {
  return new Intl.NumberFormat(Store.state.LanguageStore.currentLanguage, {
    style: 'currency',
    currency: currency,
  }).format(cents / 100)
}
export const niceCurrencyMaybeWithoutCents = function (cents, currency = 'DKK') {
  let ret = new Intl.NumberFormat(Store.state.LanguageStore.currentLanguage, {
    style: 'currency',
    currency: currency,
  }).format(cents / 100)
  ret = ret.replace(/[,.]00\b/, '')
  return ret
}
