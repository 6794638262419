import { authorizedApiRequest, queryParameters } from '@/setup/ApiService'

export default {
  query: function (params, merchantId, includeTestMode) {
    let newParams = { ...params }
    if (includeTestMode !== null) {
      newParams.includeTestMode = includeTestMode ? 1 : 0
    }
    let url = '/orders?' + queryParameters(newParams)
    if (merchantId) {
      url = '/orders/' + merchantId + '?' + queryParameters(newParams)
    }
    return authorizedApiRequest(
      url, //
      'GET',
    )
  },

  getOrderStats: function (merchantId) {
    let url = '/orders/stats'
    if (merchantId) {
      url = '/orders/' + merchantId + '/stats'
    }
    return authorizedApiRequest(
      url, //
      'GET',
    )
  },

  createOrder: function (merchantId, orderData) {
    let url = '/orders/' + merchantId + '/manual-order'
    return authorizedApiRequest(
      url, //
      'POST',
      orderData,
    )
  },

  overview: function () {
    return authorizedApiRequest(
      '/orders/overview', //
      'GET',
    )
  },

  refund: function (orderId, amount, refundPayment) {
    return authorizedApiRequest(
      '/orders/' + orderId + '/refund', //
      'POST',
      {
        amount: amount,
        refundPayment,
      },
    )
  },
}
