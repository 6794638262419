<template>
  <div>
    <!-- eslint-disable-next-line -->
    <GiftCardListItemInCreateOrder
      v-for="card in cards"
      v-bind:key="card.id"
      :card="card"
      :showmoreState="showmoreState"
      @showmore="e => $emit('showmore', e)"
      @selectCard="e => $emit('selectCard', e)"
    />
  </div>
</template>

<script>
export default {
  props: {
    cards: Array,
    showmoreState: Array,
  },
  methods: {},
}
</script>
