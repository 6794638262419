<template>
  <b-card v-if="(withJoke || hasData || loadingState) && ($perms.isAdministrator() || $perms.hasMerchantsWithPermission('seeOrders'))" v-bind="$attrs">
    <McSpinner v-if="loadingState === true" large />
    <div v-else-if="hasData" style="height: 200px">
      <chartLine ref="theChart" @beforeLayout="setCurrencyAxes" :chartData="datacollection" :options="options" style="height: 100%; position: relative"></chartLine>
    </div>
    <div v-else class="text-center" style="position: relative; min-height: 200px">
      <p>
        {{ $t('ordersChart.NoChartData') }}
      </p>
      <div style="height: 100px"></div>
      <p style="position: absolute; bottom: 0; left: 0; right: 0" class="mb-0">
        {{ $t('ordersChart.NoChartData2') }}
      </p>
    </div>
  </b-card>
</template>

<script>
import OrderService from '@/services/OrderService'
import { FormatError } from '@/helpers/ErrorFormatting'
import { niceCurrency } from '@/helpers/Formatting'
import moment from 'moment-timezone'

export default {
  props: {
    withJoke: {
      type: Boolean,
      default: true,
    },
    merchantId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loadingState: true,
      hasData: true,
      datacollection: {},
      certificateCounts: [],
      certificateCountsSpent: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                color: '#eee',
              },
            },
          ],
          yAxes: [
            {
              labels: false,
              id: 'CurrencyStacked',
              type: 'linear',
              position: 'left',
              stacked: true,
              ticks: {
                display: false,
                beginAtZero: true,
                maxTicksLimit: 5,
                userCallback: function (value, index, values) {
                  return niceCurrency(value * 100)
                },
              },
              gridLines: {
                display: false,
                color: '#eee',
              },
            },
            {
              labels: false,
              id: 'CurrencyNotStacked',
              type: 'linear',
              position: 'left',
              stacked: false,
              ticks: {
                display: false,
                beginAtZero: true,
                maxTicksLimit: 5,
                userCallback: function (value, index, values) {
                  return niceCurrency(value * 100)
                },
              },
              gridLines: {
                display: false,
                color: '#eee',
              },
            },
            {
              id: 'Counts',
              type: 'linear',
              position: 'right',
              ticks: {
                display: false,
                beginAtZero: true,
                maxTicksLimit: 5,
              },
              gridLines: {
                display: false,
                color: '#fee',
              },
            },
          ],
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (tooltipItem, chart) => {
              var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || ''
              if (tooltipItem.datasetIndex == 6) {
                //console.log(tooltipItem)
                let numCerts = this.certificateCountsSpent.data[tooltipItem.index]
                return datasetLabel + ': ' + niceCurrency(tooltipItem.yLabel * 100) + ' (' + this.$t('ordersChart.GiftCards') + ': ' + numCerts + ')'
              }
              if (chart.datasets[tooltipItem.datasetIndex].yAxisID == 'CurrencyStacked' || chart.datasets[tooltipItem.datasetIndex].yAxisID == 'CurrencyNotStacked') {
                return datasetLabel + ': ' + niceCurrency(tooltipItem.yLabel * 100)
              }
              if (tooltipItem.datasetIndex == 0) {
                let numCerts = this.certificateCounts.data[tooltipItem.index]
                return datasetLabel + ': ' + tooltipItem.yLabel + ' (' + this.$t('ordersChart.GiftCards') + ': ' + numCerts + ')'
              }
              return datasetLabel + ': ' + tooltipItem.yLabel
            },
          },
        },
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadOrderStats()
    }, 500)
  },
  watch: {
    merchantId() {
      this.loadOrderStats()
    },
  },
  methods: {
    setCurrencyAxes(chart) {
      let maxCurrency = 1
      for (let datasetId in chart.data.datasets) {
        if (!chart.isDatasetVisible(datasetId)) {
          continue
        }
        let dataset = this.datacollection.datasets[datasetId]
        if (['soldAmount', 'createdAmount', 'payoutAmount', 'commissionAmount', 'refundAmount', 'spentAmount'].includes(dataset.name)) {
          maxCurrency = Math.max(maxCurrency, Math.max(...dataset.data))
        }
      }

      if (chart.options.scales.yAxes[0].ticks.max != maxCurrency * 1.3) {
        chart.options.scales.yAxes[0].ticks.max = maxCurrency * 1.3
        chart.options.scales.yAxes[1].ticks.max = maxCurrency * 1.3
      }
    },
    formatMonth(month) {
      let m = moment(month + '-01')
      return m.format("MMM 'YY")
    },
    loadOrderStats() {
      return OrderService.getOrderStats(this.merchantId)
        .then(data => {
          this.loadingState = false
          this.displayStats(data)
        })
        .catch(error => {
          console.log(FormatError(error))
          this.loadingState = false
        })
    },
    displayStats(data) {
      let datacollection = {
        labels: [],
        datasets: [],
      }
      let datasets = {
        numOrders: {
          name: 'numOrders',
          label: this.$t('ordersChart.Orders'),
          fill: false,
          pointRadius: 0,
          backgroundColor: '#c00',
          borderColor: '#c00',
          yAxisID: 'Counts',
          data: [],
        },
        numCertificates: {
          name: 'numCertificates',
          label: this.$t('ordersChart.GiftCards'),
          fill: false,
          pointRadius: 0,
          borderColor: 'rgba(0,0,0,0)',
          yAxisID: 'Counts',
          data: [],
        },
        soldAmount: {
          name: 'soldAmount',
          label: this.$t('ordersChart.Sold'),
          fill: false,
          backgroundColor: '#00a',
          borderColor: '#00a',
          pointRadius: 0,
          yAxisID: 'CurrencyNotStacked',
          data: [],
        },
        createdAmount: {
          name: 'createdAmount',
          label: this.$t('ordersChart.Created'),
          fill: false,
          backgroundColor: '#06d',
          borderColor: '#06d',
          pointRadius: 0,
          yAxisID: 'CurrencyNotStacked',
          data: [],
        },
        commissionAmount: {
          name: 'commissionAmount',
          label: this.$t('ordersChart.Commission'),
          fill: 'origin',
          backgroundColor: '#cfc',
          borderColor: '#cfc',
          pointRadius: 0,
          yAxisID: 'CurrencyStacked',
          data: [],
        },
        refundAmount: {
          name: 'refundAmount',
          label: this.$t('ordersChart.RefundAmount'),
          fill: false,
          backgroundColor: '#dd0',
          borderColor: '#dd0',
          pointRadius: 0,
          yAxisID: 'CurrencyNotStacked',
          data: [],
        },
        payoutAmount: {
          name: 'payoutAmount',
          label: this.$t('ordersChart.PaidOut'),
          fill: 'origin',
          backgroundColor: '#0a0',
          borderColor: '#0a0',
          pointRadius: 0,
          yAxisID: 'CurrencyStacked',
          data: [],
        },
        spentAmount: {
          name: 'spentAmount',
          label: this.$t('ordersChart.Spent'),
          fill: false,
          pointRadius: 0,
          backgroundColor: '#c0c',
          borderColor: '#c0c',
          yAxisID: 'CurrencyNotStacked',
          data: [],
        },
        numSpent: {
          name: 'numSpent',
          label: this.$t('ordersChart.Spent'),
          fill: false,
          pointRadius: 0,
          backgroundColor: '#c00',
          borderColor: '#c00',
          yAxisID: 'Counts',
          data: [],
        },
      }
      this.hasData = false
      let hasRefunds = false
      for (let month in data) {
        let item = data[month]
        datacollection.labels.push(this.formatMonth(item.period))
        for (var series in datasets) {
          if (series == 'month') {
            continue
          }
          if (item[series] > 0) {
            this.hasData = true
            if (series === 'refundAmount') {
              hasRefunds = true
            }
          }
          if (['soldAmount', 'createdAmount', 'payoutAmount', 'refundAmount', 'commissionAmount', 'spentAmount'].includes(series)) {
            datasets[series].data.push(item[series] / 100)
          } else {
            datasets[series].data.push(item[series])
          }
        }
      }

      this.certificateCounts = datasets['numCertificates']
      delete datasets['numCertificates']
      this.certificateCountsSpent = datasets['numSpent']
      delete datasets['numSpent']

      if (!hasRefunds) {
        delete datasets['refundAmount']
      }

      datacollection.datasets = Object.values(datasets)
      this.datacollection = datacollection

      if (!this.hasData) {
        this.$emit('nothingToShow')
      }
    },
  },
}
</script>
