<template>
  <b-form-group class="mc-dual-inputs" :label="label" :description="description" :state="!errorHint">
    <b-form-row>
      <b-col :sm="12 / numInputs" class="mb-2 mb-sm-0">
        <slot name="input1"></slot>
      </b-col>
      <b-col :sm="12 / numInputs">
        <slot name="input2"></slot>
      </b-col>
      <b-col :sm="12 / numInputs">
        <slot name="input3"></slot>
      </b-col>
    </b-form-row>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  props: {
    errorHint: String,
    label: String,
    description: String,
  },
  computed: {
    numInputs() {
      let ret = 0
      if (this.$slots.input1) {
        ret++
      }
      if (this.$slots.input2) {
        ret++
      }
      if (this.$slots.input3) {
        ret++
      }
      return ret
    },
  },
}
</script>

<style>
.mc-dual-inputs .form-group {
  margin-bottom: 0;
}
</style>
