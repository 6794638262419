<template>
  <component :is="fullscreen ? 'fullscreen-editor' : 'div'" :shown="fullscreenShown" :beforeClose="stop" :close-button="true" ref="fullscreenScanner">
    <div id="qr-code-wrap" :class="fullscreen ? 'is-fullscreen' : 'not-fullscreen'">
      <video id="qr-code-reader" :style="cropStyle" playsinline="true"></video>
      <canvas id="qr-code-canvas" :style="cropStyle"></canvas>
      <div v-if="technicalError" id="qr-code-technical-error">
        {{ $t('qrReader.TechnicalError') }}
        <small>{{ technicalError }}</small>
      </div>
      <div v-else-if="error" id="qr-code-error">{{ error }}</div>
      <pre id="qr-code-debug">{{ debug }}</pre>
    </div>
    <!--mc-button :icon="['fas', 'times']" class="closeFullscreenModal" variant="danger" :text="$t('general.Cancel')" @click="$refs['fullscreenScanner'].hide()"></mc-button-->
  </component>
</template>

<script>
import jsQR from 'jsqr'

export default {
  data() {
    return {
      video: null,
      canvas: null,
      srcDim: null,
      readDim: null,
      greyCoords: null,
      debug: 0,
      isRunning: false,
      technicalError: '',
      code: null,
      clearCodeTimeout: null,
      fullscreenShown: false,
    }
  },
  props: {
    error: String,
    fullscreen: Boolean,
  },
  computed: {
    cropStyle() {
      if (!this.srcDim) {
        return {}
      }
      if (this.fullscreen) {
        return {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }
      }
      if (this.srcDim.w > this.srcDim.h) {
        return {
          width: 'auto',
          height: '100%',
        }
      }
      return {
        width: '100%',
        height: 'auto',
      }
    },
  },
  methods: {
    luma(p, at) {
      return p[at] * 0.299 + p[++at] * 0.587 + p[++at] * 0.114
    },

    // adjust image to improve recognition rate in dark place
    enhance(hgram, idata, thold) {
      var { width, data } = idata,
        halfW = width * 0.5,
        min = -1,
        max = -1,
        maxH = 0,
        i,
        len,
        scale

      // get lumas and build histogram
      for (i = 0; i < data.length; i += 4) {
        hgram[Math.trunc(this.luma(data, i))]++ // add to the luma bar (and why we need an integer)
      }

      // find tallest bar so we can use that to scale threshold
      for (i = 0; i < width; i++) {
        if (hgram[i] > maxH) maxH = hgram[i]
      }

      // use that for threshold
      thold *= maxH

      // find min value
      for (i = 0; i < halfW; i++) {
        if (hgram[i] > thold) {
          min = i
          break
        }
      }
      if (min < 0) min = 0

      // find max value
      for (i = width - 1; i > halfW; i--) {
        if (hgram[i] > thold) {
          max = i
          break
        }
      }
      if (max < 0) max = 255

      scale = (255 / (max - min)) * 1.9

      // scale all pixels
      let base = min * scale
      for (i = 0, len = data.length; i < len; i++) {
        data[i] = data[i] > min ? data[i] * scale - base : 0
        data[++i] = data[i] > min ? data[i] * scale - base : 0
        data[++i] = data[i] > min ? data[i] * scale - base : 0
      }

      return idata
    },

    findQR() {
      let { width: w, height: h } = this.readCanvas

      // crop only center part from video feed to speed up processing
      this.readCtx.drawImage(this.video, (this.srcDim.w - w) / 2, (this.srcDim.h - h) / 2, w, h, 0, 0, w, h)
      let imageData = this.readCtx.getImageData(0, 0, w, h)

      let code = jsQR(imageData.data, w, h)
      if (code) {
        code.enhanced = false
      } else {
        imageData = this.enhance(this.hgram, imageData, 1)
        code = jsQR(imageData.data, w, h)
        if (code) {
          code.enhanced = true
        }
      }
      if (code && code.data != '') {
        return code
      }
    },

    transformCoords(coords) {
      return {
        x: coords.x + (this.srcDim.w - this.readDim.w) / 2,
        y: coords.y + (this.srcDim.h - this.readDim.h) / 2,
      }
    },

    transformLocation(location) {
      return {
        topRightCorner: this.transformCoords(location.topRightCorner),
        bottomRightCorner: this.transformCoords(location.bottomRightCorner),
        topLeftCorner: this.transformCoords(location.topLeftCorner),
        bottomLeftCorner: this.transformCoords(location.bottomLeftCorner),
      }
    },

    tick() {
      this.debug = 'Ticking'
      if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
        let result = this.findQR()
        this.writeCtx.clearRect(0, 0, this.writeCanvas.width, this.writeCanvas.height)
        this.writeCtx.beginPath()
        this.writeCtx.moveTo(0, 0)
        this.writeCtx.lineTo(0, this.srcDim.h)
        this.writeCtx.lineTo(this.srcDim.w, this.srcDim.h)
        this.writeCtx.lineTo(this.srcDim.w, 0)
        this.writeCtx.lineTo(this.greyCoords.topLeftCorner.x, 0)
        this.writeCtx.lineTo(this.greyCoords.topLeftCorner.x, this.greyCoords.topLeftCorner.y)
        this.writeCtx.lineTo(this.greyCoords.topRightCorner.x, this.greyCoords.topRightCorner.y)
        this.writeCtx.lineTo(this.greyCoords.bottomRightCorner.x, this.greyCoords.bottomRightCorner.y)
        this.writeCtx.lineTo(this.greyCoords.bottomLeftCorner.x, this.greyCoords.bottomLeftCorner.y)
        this.writeCtx.lineTo(this.greyCoords.topLeftCorner.x, 0)
        this.writeCtx.lineTo(0, 0)
        this.writeCtx.fillStyle = 'rgba(0, 0, 0, 0.5)'
        this.writeCtx.fill()

        if (result != null) {
          let location = this.transformLocation(result.location)
          this.writeCtx.beginPath()
          this.writeCtx.moveTo(location.topRightCorner.x, location.topRightCorner.y)
          this.writeCtx.lineTo(location.topLeftCorner.x, location.topLeftCorner.y)
          this.writeCtx.lineTo(location.bottomLeftCorner.x, location.bottomLeftCorner.y)
          this.writeCtx.lineTo(location.bottomRightCorner.x, location.bottomRightCorner.y)
          this.writeCtx.lineTo(location.topRightCorner.x, location.topRightCorner.y)
          this.writeCtx.lineWidth = 3
          this.writeCtx.strokeStyle = result.enhanced ? '#ff0000' : '#00ff00'
          this.writeCtx.stroke()
          this.debug = 'success: ' + result.data
          this.onScanSuccess(result.data)
        } else {
          this.debug = 'Failing'
          this.onScanFailure()
        }
      }
      setTimeout(() => {
        this.tick()
      }, 50)
    },
    start() {
      if (this.fullscreen && !this.fullscreenShown) {
        this.fullscreenShown = true
        this.$refs['fullscreenScanner'].show()
        setTimeout(() => {
          this.start()
        }, 1000)
        return
      }
      this.video = document.querySelector('#qr-code-reader')
      this.readCanvas = document.createElement('canvas')
      this.readCtx = this.readCanvas.getContext('2d', { alpha: false })
      this.writeCanvas = document.getElementById('qr-code-canvas')
      this.writeCtx = this.writeCanvas.getContext('2d', { alpha: true })
      this.debug = 'Found elements'

      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: 'environment',
          },
        })
        .then(stream => {
          this.video.srcObject = stream
          this.debug = 'Found stream'
          this.technicalError = null
          this.video.play()
          this.video.onloadedmetadata = () => {
            this.srcDim = { w: this.video.videoWidth, h: this.video.videoHeight }
            this.debug = 'Playing: ' + this.srcDim.w + 'x' + this.srcDim.h
            this.isRunning = true

            // Only scan part of the stream
            let readSize = Math.min(this.srcDim.w, this.srcDim.h) * 0.7
            this.readDim = { w: readSize, h: readSize }
            this.readCanvas.width = this.readDim.w
            this.readCanvas.height = this.readDim.h
            this.writeCanvas.width = this.srcDim.w
            this.writeCanvas.height = this.srcDim.h

            let greySize = Math.min(this.srcDim.w, this.srcDim.h) * 0.5
            let padding = (readSize - greySize) / 2
            this.greyCoords = this.transformLocation({
              topLeftCorner: { x: padding, y: padding },
              topRightCorner: { x: readSize - padding, y: padding },
              bottomLeftCorner: { x: padding, y: readSize - padding },
              bottomRightCorner: { x: readSize - padding, y: readSize - padding },
            })
            // initialize a shared hgram buffer
            this.hgram = new Uint32Array(this.readCanvas.width)

            this.tick()
          }
        })
        .catch(err => {
          this.technicalError = err
        })
    },

    stop() {
      if (this.video && this.isRunning) {
        this.video.srcObject.getVideoTracks()[0].stop()
      }
      this.isRunning = false
      if (this.fullscreen) {
        this.fullscreenShown = false
        this.$refs['fullscreenScanner'].hide()
        this.technicalError = null
        return true
      }
    },
    onScanSuccess(code) {
      clearTimeout(this.clearCodeTimeout)
      this.clearCodeTimeout = null
      if (this.code != code) {
        this.$emit('code', code)
        this.code = code
      }
    },
    onScanFailure() {
      if (this.code != null) {
        this.code = null
        clearTimeout(this.clearCodeTimeout)
        this.clearCodeTimeout = setTimeout(() => {
          this.$emit('code', null)
        }, 500)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#qr-code-wrap {
  margin: 0 auto;
  padding-bottom: 100%;
  overflow: hidden;
  background: black;
  position: relative;
  &.is-fullscreen {
    padding-bottom: 0;
    height: 100%;
    width: 100%;
  }
}
.not-fullscreen #qr-code-canvas,
.not-fullscreen #qr-code-reader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.is-fullscreen #qr-code-canvas,
.is-fullscreen #qr-code-reader {
  position: absolute;
  top: 0;
  left: 0;
}
#qr-code-error,
#qr-code-technical-error {
  padding: 1em;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #ff0000;
  font-weight: bold;
  small {
    display: block;
    font-weight: normal;
    text-align: left;
    font-family: monospace;
    line-height: 1;
  }
  position: absolute;
  top: 50%;
  left: 5%;
  right: 5%;
  transform: translateY(-50%);
  text-align: center;
}
#qr-code-debug {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: yellow;
  display: none;
}
</style>
