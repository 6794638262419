<template>
  <li>
    <label v-if="card.type === 'giftcard'">
      <input type="checkbox" :disabled="curcard === card.id" v-model="self_selected" />
      {{ card.webshopName }}
    </label>
    <strong v-else>{{ card.webshopName }}</strong>
    <ul v-if="card.type === 'category'">
      <GiftCardListItemInMultisave v-for="(card, key) in card.children" v-bind:key="key" :curcard="curcard" :card="card" v-model="selected[card.id]" />
      <!--li><pre>{{ selected }}</pre></li-->
    </ul>
  </li>
</template>

<script>
export default {
  data() {
    return {
      self_selected: this.curcard == this.card.id,
      selected: {},
    }
  },
  props: {
    card: Object,
    curcard: Number,
  },
  watch: {
    self_selected() {
      this.$emit('input', this.self_selected)
    },
    selected() {
      this.$emit('input', this.selected)
    },
  },
}
</script>
