<template>
  <div v-if="merchantInfo" class="checkcode-wrap">
    <div v-if="!isIframe">
      <div class="merchantLogo" v-if="merchantInfo.logoDark && merchantInfo.logoDark.url" :style="'background-image: url(' + merchantInfo.logoDark.url + ')'" />
      <p class="noMerchantLogo" v-else>
        {{ $t('checkBalance.GiftCardFor') }}
        <br />
        <strong>{{ merchantInfo.niceName }}</strong>
      </p>
      <hr />
      <div style="text-align: center;" v-html="$t('checkBalance.MerchantContactableByPhone', { merchantName: merchantInfo.niceName, merchantPhone: merchantInfo.phone, merchantNicePhone: merchantInfo.nicePhone })" />
      <hr />
    </div>
    <div class="checkcode-cols">
      <div class="checkcode-col">
        <table class="checkcode-table">
          <tbody>
            <tr>
              <td>{{ $t('checkBalance.Code') }}</td>
              <td>{{ codeInfo.code }}</td>
            </tr>
            <tr>
              <td>{{ $t('checkBalance.Value') }}</td>
              <td>{{ codeInfo.amount | niceCurrency }}</td>
            </tr>
            <tr>
              <td>{{ $t('checkBalance.RemainingValue') }}</td>
              <td>{{ codeInfo.balance | niceCurrency }}</td>
            </tr>
            <tr>
              <td>{{ $t('checkBalance.Expiry') }}</td>
              <td>{{ codeInfo.expires | niceExpiry }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="checkcode-col" style="text-align: center">
        <div v-if="codeInfo.testMode" class="pt-2">
          <b-alert variant="danger" show>{{ $t('checkBalance.TESTcode') }}</b-alert>
        </div>
        <div style="font-size: 1.8em">
          <b-alert show variant="danger" v-if="codeInfo.expired">{{ $t('checkBalance.EXPIRED') }}</b-alert>
          <b-alert show variant="danger" v-else-if="codeInfo.refunded">{{ $t('checkBalance.REFUNDED') }}</b-alert>
          <b-alert show variant="danger" v-else-if="codeInfo.balance <= 0">{{ $t('checkBalance.FULLYREDEEMED') }}</b-alert>
          <b-alert show variant="success" v-else-if="codeInfo.balance < codeInfo.amount">{{ $t('checkBalance.PARTIALLYREDEEMED') }}</b-alert>
          <b-alert show variant="success" v-else>{{ $t('checkBalance.VALID') }}</b-alert>
        </div>
        <div v-if="isRedeemable">
          <b-button v-if="isIframe" target="_blank" :href="iframeRedeemUrl" class="w-100" :variant="isFullyValid ? 'success' : 'secondary'">
            <div v-html="$t('checkBalance.RedeemButton', { merchantName: merchantInfo.niceName })" />
          </b-button>
          <b-button v-else @click="loginOrStartRedeem" class="w-100" :variant="isFullyValid ? 'success' : 'secondary'">
            <div v-html="$t('checkBalance.RedeemButton', { merchantName: merchantInfo.niceName })" />
          </b-button>
        </div>
      </div>
    </div>
    <!--pre>{{ codeInfo }}</pre-->
    <RedeemModal ref="redeemModal" :code-info="codeInfo" @redeemed="checkToken(false)"></RedeemModal>
  </div>
</template>

<script>
import Router from '@/setup/Router'
import { FormatError } from '@/helpers/ErrorFormatting'
import { mapActions } from 'vuex'
import CodesService from '@/services/CodesService'
import { niceCurrency } from '@/helpers/Formatting'
import moment from 'moment-timezone'

export default {
  props: {
    qrData: null,
  },
  data() {
    return {
      spentAmount: -1,
      merchantInfo: null,
      codeInfo: null,
    }
  },
  mounted() {
    console.log(this.qrData)
    this.codeInfo = this.qrData.codeInfo
    this.merchantInfo = this.qrData.merchantInfo
    this.spentAmount = this.codeInfo.balance / 100
    if (this.qrData.autoContinue) {
      setTimeout(() => {
        this.loginOrStartRedeem()
      }, 100)
    }
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    loginOrStartRedeem() {
      if (this.isLoggedInWithValidUserToRedeem) {
        this.$refs['redeemModal'].show()
      } else if (this.$perms.isLoggedIn()) {
        this.$bvModal.msgBoxOk(this.$t('checkBalance.YourCurrentUserDoesNotHavePermissionToRedeem')).then(() => {
          Router.push({ path: '/login', query: { from: 'qr/' + this.$route.params.token + '?returned=1' } })
        })
      } else {
        Router.push({ path: '/login', query: { from: 'qr/' + this.$route.params.token + '?returned=1' } })
      }
    },
    sendRedeem(e) {
      if (this.amountError) {
        alert(this.amountError)
        e.preventDefault()
        return
      }
      let amount = parseInt(this.spentAmount * 100)
      CodesService.redeem(this.certificate.code, amount).then(
        data => {
          switch (data.message) {
            case 'code.redeemed':
              this.toastSuccess(this.$t('general.CodeRedeemed'))
              break
            case 'code.refilled':
              this.toastSuccess(this.$t('general.CodeRefilled'))
              break
            case 'code.partiallyRedeemed':
              this.toastSuccess(this.$t('general.CodePartiallyRedeemed'))
              break
          }
          this.$emit('redeemed')
          return Promise.resolve(data)
        },
        error => {
          alert(FormatError(error))
          return Promise.reject(error)
        },
      )
    },
  },
  computed: {
    isIframe() {
      return this.$route.query.iframe == 1
    },
    iframeRedeemUrl() {
      return (
        'https://' +
        (this.$perms.isDevelopment() ? 'localhost:8080' : 'panel.danskegavekort.dk') +
        '/' +
        (this.isLoggedInWithValidUserToRedeem ? 'qr/' + this.$route.params.token + '?returned=1' : 'login?from=qr%2F' + this.$route.params.token + '%3Freturned%3D1')
      )
    },
    isLoggedInWithValidUserToRedeem() {
      return this.$perms.isLoggedIn() && (this.$perms.isAdministrator() || this.$perms.hasPermission('markCodes', this.codeInfo.merchant))
    },
    isRedeemable() {
      if (!this.codeInfo.refunded && this.codeInfo.balance > 0) {
        return true
      }
      return false
    },
    isFullyValid() {
      if (!this.codeInfo.refunded && !this.codeInfo.expired && this.codeInfo.balance > 0) {
        return true
      }
      return false
    },
  },
  filters: {
    niceCurrency(amountInCents) {
      return niceCurrency(amountInCents)
    },
    niceExpiry(date) {
      return moment(date).format('ll')
    },
  },
}
</script>

<style lang="scss">
.checkcode-wrap {
  a[href*=tel],
  .noMerchantLogo strong {
    font-size: 1.6em;
  }
  .merchantLogo {
    background-size: contain;
    height: 150px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  img {
    width: 100%;
    margin: 1em auto;
  }
}
.checkcode-cols {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.checkcode-col {
  width: calc(50% - 10px);
  min-width: 250px;
  flex-grow: 0;
  flex-shrink: 0;
}
.checkcode-table {
  width: 100%;
  height: auto;
  max-width: 300px;
  margin: 0 auto;
  td {
    padding-bottom: 0.6em;
  }
  td:last-child {
    text-align: right;
  }
}
</style>
