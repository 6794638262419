<template>
  <transition name="toAuto" @enter="enter" @afterEnter="afterEnter" @leave="leave">
    <div v-if="isVisible" class="transitionToAutoV2root">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    afterEnter(element) {
      element.style.height = `auto`
    },
    enter(element) {
      element.style.position = `absolute`
      element.style.visibility = `hidden`
      element.style.height = `auto`

      const size = getComputedStyle(element)['height']

      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element)['height']

      setTimeout(() => {
        element.style.height = size
      })
    },
    leave(element) {
      const size = getComputedStyle(element)['height']

      element.style.height = size

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element)['height']

      setTimeout(() => {
        element.style.height = 0
      })
    },
  },
}
</script>

<style scoped>
.transitionToAutoV2root {
  will-change: height;
  transition: height 0.2s ease-in-out;
  overflow: hidden;
}
.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
}
</style>
