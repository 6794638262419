<template>
  <transition v-if="shown" name="fsetrans" v-on:after-enter="onAfterEnter" v-on:after-leave="enablescroll">
    <div @click.stop="" class="fullscreen-wrap">
      <div v-if="closeButton" @click="hideByButton" class="closebutton" />
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      shown: false,
      onEnterPromise: null,
    }
  },
  props: {
    closeButton: {
      type: Boolean,
      default: true,
    },
    beforeClose: {
      type: Function,
      default: () => true,
    },
  },
  methods: {
    enablescroll() {
      document.documentElement.classList.remove('has-fullscreen-editor')
    },
    hideByButton() {
      if (this.beforeClose()) {
        this.hide()
      }
    },
    hide() {
      this.shown = false
    },
    show() {
      document.documentElement.classList.add('has-fullscreen-editor')
      this.shown = true
      return new Promise(resolve => {
        this.onEnterPromise = resolve
      })
    },
    onAfterEnter: function (el) {
      this.onEnterPromise()
    },
  },
}
</script>

<style lang="scss">
.fullscreen-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1040;
  .closebutton {
    z-index: 9;
    position: absolute;
    right: 1em;
    top: 1em;
    cursor: pointer;
    transition: all 0.2s ease-out;
    border-radius: 0.3em;
    &:hover {
      transform: translateY(-2px);
      &:before {
        box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
      }
      &:after {
        box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.3);
      }
    }
    width: 3em;
    height: 3em;
    &:after,
    &:before {
      transition: box-shadow 0.2s ease-out;
      content: '';
      width: 0.3em;
      height: 2em;
      background: #888;
      position: absolute;
      top: 0.5em;
      left: 1.35em;
      transform: rotate(45deg);
      transform-origin: 50% 50%;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
html.has-fullscreen-editor {
  body {
    padding-right: 17px;
    overflow: hidden;
    height: 100vh;
  }
  .pushContentBelowFixedHeader > nav {
    margin-right: 17px;
  }
  #toast-outer-container > div {
    margin-right: 17px;
  }
}

.fsetrans-enter-active {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
.fsetrans-leave-active {
  transition: transform 0.2s ease-in, opacity 0.2s ease-in;
}

.fsetrans-enter,
.fsetrans-leave-to {
  transform: scale(0.2) translateY(500px);
  opacity: 0;
}
</style>
