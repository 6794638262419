<template>
  <b-form-group :label="label" :description="description" :state="!uploadError">
    <b-input-group class="mc-form-upload">
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <!-- prettier-ignore -->
      <div class="custom-file">
        <div class="mc-form-upload-wrapper" :style="getBackgroundForPreview()" @click="showModal">
          <div v-if="shownImage" :style="'background-image: url(' + shownImage + ')'" class="imagepreview">
            <img :src="shownImage" />
          </div>
          <mc-button icon="trash" v-if="shownImage" variant="danger" @click.stop.prevent="$emit('input', null)" />
          <label class="placeholder">
            <div v-if="!shownImage" class="placeholder__text">{{ placeholder }}</div>
            <!--input class="fileupload" type="file" name="fileUpload" @change="onFileChange" /-->
          </label>
        </div>
      </div>
    </b-input-group>
    <b-modal ref="modal" scrollable :id="$id('imageModal')" size="xl" hide-header body-class="p-0" modal-class="image_modal">
      <template #modal-header>
        <b-nav card-header tabs>
          <b-nav-item active>Active</b-nav-item>
          <b-nav-item>Inactive</b-nav-item>
          <b-nav-item disabled>Disabled</b-nav-item>
        </b-nav>
      </template>
      <b-tabs ref="tabs" card class="h-100 flex-column">
        <b-tab @click="scrollTabToTop" key="mine" :title="$t('general.MyOwnImages')" active>
          <b-card-text>
            <div :class="'image-upload-droparea' + (isDropping ? ' is_dropping' : '')" @dragenter="handledrag" @dragover="handledrag" @dragleave="handledrag" @drop="handledrag">
              <label class="placeholder">
                <div class="placeholder__text">{{ $t('general.ClickOrDropToUpload') }}</div>
                <input class="fileupload" type="file" name="fileUpload" @change="onFileChange" />
              </label>
            </div>
            <div class="image-uploads-list">
              <div v-if="!myImages" class="text-center w-100"><mc-spinner large /></div>
              <template v-else>
                <div class="imagePreview" :style="getBackgroundForImageColor(image.color)" v-for="(image, key) in myImages" v-bind:key="key" @click="selectImage(image)">
                  <div :style="image.url_thumb ? 'background-image: url(' + image.url_thumb + ')' : ''" />
                  <p class="image-meta stock" v-if="image.meta && image.meta.user">
                    <img :src="image.meta.user.image" height="32" width="32" />
                    <span>
                      <small>{{ $t('general.PhotoBy') }}</small>
                      <span class="author-name"><a :href="image.meta.user.link" target="_blank">{{ image.meta.user.name }}</a></span>
                      <small>
                        {{ $t('general.creditOn') }} <a :href="image.meta.provider.link" target="_blank">{{ image.meta.provider.name }}</a>
                      </small>
                    </span>
                  </p>
                  <p class="image-meta self-upload" v-else>
                    <span>
                      <span class="filename">{{ image.nicename }}</span>
                      <small class="date">{{ image.date | formatFiledate }}</small>
                      <small class="size">{{ image.width }}x{{ image.height }}</small>
                    </span>
                  </p>
                </div>
              </template>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab v-if="!onlyMyOwn && includeUnsplash" @click="scrollTabToTop" key="unsplash" :title="$t('general.OnlineImages')">
          <b-card-text>
            <div class="'unsplash-search">
              <mc-form-input ref="unsplashSearchField" :placeholder="$t('general.TypeToSearch')" :label="$t('general.UnsplashQuery')" v-model="unsplashQuery" @input="unsplashSearch" />
            </div>
            <div class="image-uploads-list">
              <template v-if="unsplashImages && !loadingStockPhoto">
                <div class="imagePreview" :style="'background:' + image.color" v-for="(image, key) in unsplashImages" v-bind:key="key" @click="e => selectUnsplashImage(image.id)">
                  <div :style="'background-image: url(' + image.url + ')'" />
                  <p class="image-meta stock">
                    <img :src="image.user.image" height="32" width="32" />
                    <span>
                      <small>{{ $t('general.PhotoBy') }}</small>
                      <span class="author-name"><a :href="image.user.link" target="_blank">{{ image.user.name }}</a></span>
                      <small>
                        {{ $t('general.creditOn') }} <a :href="image.provider.link" target="_blank">{{ image.provider.name }}</a>
                      </small>
                    </span>
                  </p>
                </div>
              </template>
              <div v-if="unsplashLoading || loadingStockPhoto" class="text-center w-100"><mc-spinner large /></div>
              <div v-else-if="unsplashImages" class="text-center w-100">
                <mc-button variant="outline-info" @click="resetUnsplash" class="mr-2" :text="$t('general.ClearSearch')" />
                <span style="font-size: 0.8em; margin: 10px">{{ unsplashPage }} / {{ unsplashPages }}</span>
                <mc-button variant="outline-success" :disabled="unsplashPage >= unsplashPages" @click="getNextUnsplashPage" :text="$t('general.LoadMore')" />
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button variant="link" @click="hideModal()">{{ $t('general.Cancel') }}</b-button>
        <McButton @click="hideModal()" type="button" :text="$t('general.Use')" variant="primary" />
      </template>
    </b-modal>
    <TransitionToAuto v-if="uploadError">
      <div class="invalid-feedback d-block">{{ uploadError }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script type="text/babel">
import moment from 'moment-timezone'
import debounce from 'lodash/debounce'
import UploadService from '@/services/UploadService'
import { FormatError } from '@/helpers/ErrorFormatting'

export default {
  name: 'fileupload',
  props: {
    icon: [String, Array],
    onlyMyOwn: Boolean,
    includeUnsplash: Boolean,
    errorHint: String,
    label: String,
    description: String,
    placeholder: {
      type: String,
      default: 'Click to choose',
    },
    value: {
      type: Object,
      default: null,
    },
    variant: String,
  },
  data() {
    return {
      file: null,
      uploadError: '',
      uploading: false,
      imagePreview: false,
      isDropping: 0,
      myImages: false,
      loadingStockPhoto: false,
      unsplashLoading: false,
      unsplashQuery: '',
      unsplashPage: 0,
      unsplashPages: 0,
      unsplashImages: false,
    }
  },
  computed: {
    shownImage() {
      if (this.imagePreview) {
        return this.imagePreview
      } else if (this.value && this.value.url_preview) {
        return this.value.url_preview
      }
      return false
    },
  },
  methods: {
    scrollTabToTop() {
      let el = this.$refs['tabs'].$el.querySelector('.tab-content')
      el.scrollTop = 0
    },
    resetUnsplash() {
      this.unsplashQuery = ''
      this.unsplashImages = false
      let el = this.$refs['unsplashSearchField'].$el.querySelector('input')
      el.focus()
      el.select()
    },
    unsplashSearch() {
      this.unsplashLoading = true
      this.debouncedUnsplashSearch()
    },
    debouncedUnsplashSearch: debounce(
      function () {
        this.unsplashPage = 0
        this.getNextUnsplashPage()
        this.unsplashImages = []
      },
      2000,
      {
        leading: false,
      },
    ),
    getNextUnsplashPage() {
      this.unsplashLoading = true
      this.unsplashPage++
      UploadService.stockImages(this.unsplashQuery, this.unsplashPage)
        .then(data => {
          if (!this.unsplashImages) {
            this.unsplashImages = []
          }
          for (var i in data.images) {
            this.unsplashImages.push(data.images[i])
          }
          this.unsplashPages = data.pages
        })
        .catch(error => {
          console.log('Error in apiRequest', error)
        })
        .finally(data => {
          this.unsplashLoading = false
        })
    },
    showModal() {
      this.loadImages()
      this.$bvModal.show(this.$id('imageModal'))
    },
    loadImages() {
      UploadService.myImages().then(data => {
        this.myImages = data
      })
    },
    selectImage(image) {
      this.$emit('input', image)
      this.imagePreview = false
      this.$bvModal.hide(this.$id('imageModal'))
    },
    handledrag(e) {
      if (e.dataTransfer.types.includes('Files')) {
        e.dataTransfer.dropEffect = 'copy'
        if (e.type == 'dragenter') {
          this.isDropping = 1
        } else if (e.type == 'dragover') {
          this.isDropping = 1
        } else if (e.type == 'dragleave') {
          this.isDropping = 0
        } else if (e.type == 'drop') {
          this.isDropping = 0
        }
      }
    },
    hideModal() {
      this.$refs.modal.hide()
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        this.imagePreview = false
        this.$emit('input', null)
        return
      }

      //console.log(files)

      var reader = new FileReader()
      reader.onload = e => {
        this.imagePreview = e.target.result
      }
      reader.readAsDataURL(files[0])

      this.$emit('beginUpload', files[0])
      this.startUpload(files[0])
    },

    startUpload(file) {
      this.uploading = true
      UploadService.image(file)
        .then(data => {
          this.uploadError = ''
          this.$emit('input', data.image)
          //console.log('upload done', data)
          this.imagePreview = false
          this.$bvModal.hide(this.$id('imageModal'))
        })
        .catch(data => {
          this.uploadError = FormatError(data)
          this.$emit('input', null)
          this.$emit('error', this.uploadError)
          console.log('upload failed', data)
        })
        .finally(() => {
          console.log(file)
          this.$emit('finishUpload', file)
          this.uploading = false
        })
    },
    selectUnsplashImage(imageId) {
      this.loadingStockPhoto = true
      UploadService.downloadStockImage('unsplash', imageId)
        .then(data => {
          this.uploadError = ''
          this.$emit('input', data.image)
          //console.log('upload done', data)
        })
        .catch(data => {
          this.uploadError = FormatError(data)
          this.$emit('input', null)
          this.$emit('error', this.uploadError)
          console.log('upload failed', data)
        })
        .finally(() => {
          this.$bvModal.hide(this.$id('imageModal'))
          this.imagePreview = false
          this.loadingStockPhoto = false
        })
    },
    getBackgroundForImageColor(color) {
      if (color == 'dark') {
        return 'background-image: repeating-linear-gradient(45deg, #fff, #fff 10px, #ddd 10px, #ddd 20px);'
      } else if (color == 'light') {
        return 'background-image: repeating-linear-gradient(45deg, #000, #000 10px, #222 10px, #222 20px);'
      } else {
        return 'background: ' + color + ';'
      }
    },
    getBackgroundForPreview() {
      let color = 'dark'
      if (this.variant == 'dark') {
        // Forced dark, so pretend we have a light image
        color = 'light'
      } else if (this.variant == 'light') {
        // Forced light, so pretend we have a dark image
        color = 'dark'
      } else if (this.imagePreview) {
        // During upload
        color = 'dark'
      } else if (this.value && this.value.color) {
        color = this.value.color
      }
      return this.getBackgroundForImageColor(color)
    }
  },
  filters: {
    formatFiledate(date) {
      return moment(date).format('lll')
    },
  },
}
</script>

<style lang="scss">
.mc-form-upload .custom-file {
  height: auto;
}
.mc-form-upload-wrapper,
.image_modal {
  .placeholder,
  .fileupload {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .placeholder__text {
    background: rgba(255, 255, 255, 0.4);
    color: #555;
    padding: 0.2em 1em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .fileupload {
    opacity: 0;
  }
}
.mc-form-upload-wrapper {
  position: relative;
  min-height: 40px;
  width: 100%;

  border: 1px solid #666;
  border-left: 0;
  border-radius: 0 3px 3px 0;

  &.light {
    background: repeating-linear-gradient(45deg, #fff, #fff 10px, #ddd 10px, #ddd 20px);
  }
  &.dark {
    background: repeating-linear-gradient(45deg, #000, #000 10px, #222 10px, #222 20px);
  }

  .btn {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    opacity: 0;
    z-index: 99;
  }
  &:hover .btn {
    opacity: 1;
  }

  .imagepreview {
    background-size: contain;
    max-height: 200px;
    width: auto;
    margin: 10px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      opacity: 0;
      max-height: 200px;
      max-width: 400px;
    }
  }
  &.dark .placeholder__text {
    background: rgba(0, 0, 0, 0.4);
    color: #aaa;
  }
  &:hover .placeholder__text {
    background: rgba(255, 255, 255, 0.6);
    color: #000;
  }
  &.dark:hover .placeholder__text {
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
  }
}

.image_modal .modal-dialog {
  height: 100vh;
  .tab-content {
    max-height: calc(100% - 3.32rem);
    overflow-y: auto;
  }
  .modal-content {
    max-height: calc(100% - 1rem);
  }
}
.image-upload-droparea {
  border: 4px dashed #ddd;
  border-radius: 1rem;
  height: 5rem;
  text-align: center;
  position: relative;
  color: #666;
  &.is_dropping,
  &:hover {
    border-color: #aaa;
    color: #000;
  }
}
.image-uploads-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  .imagePreview {
    width: 12.5em;
    height: 15em;
    margin: 0.5em;
    position: relative;
    background: repeating-linear-gradient(45deg, #787878, #707070 10px, #888888 10px, #999 20px);
    border: 1px solid var(--info);
    cursor: pointer;
    transition: box-shadow 0.2s, transform 0.2s ease-out;
    transform: translateY(-0.01px);
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.9);
    }
    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 2.5em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    .image-meta {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2.5em;
      background: #333;
      color: #888;
      overflow: hidden;
      margin: 0;
      display: flex;
      > span {
        font-size: 0.9em;
        line-height: 0.9em;
        flex-grow: 1;
      }
      small {
        display: block;
        font-size: 0.7em;
      }
    }
    .image-meta.self-upload {
      .filename {
        padding: 0.2em;
        color: #fff;
        text-align: left;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 13em;
      }
      .date {
        position: absolute;
        bottom: 0.2em;
        right: 0.2em;
      }
      .size {
        position: absolute;
        bottom: 0.2em;
        left: 0.2em;
      }
    }
    .image-meta.stock {
      img {
        width: 1.8em;
        height: 1.8em;
        margin: 0.35em 0.5em 0.35em 0.35em;
        border-radius: 1em;
      }
      .author-name {
        font-style: italic;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 10em;
        display: block;
      }
      small {
        margin-left: -0.4em;
        &:last-child {
          margin-left: 0.4em;
        }
      }
      a {
        color: #ddd;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
}
</style>
