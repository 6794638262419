<template>
  <div class="billing-overview-wrap">
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="overviewData">
      <!--pre>{{ overviewData }}</pre-->
      <i18n :path="overviewData.current.numOrders > 0 ? 'billing.InMonthXSold' : 'billing.NothingSoldYetInMonthX'" tag="p">
        <template v-slot:month>
          {{ overviewData.current.period | formatMonth }}
        </template>
        <template v-slot:numCertificates>
          <strong>{{ overviewData.current.numCertificates }}</strong>
        </template>
        <template v-slot:numOrders>
          <strong>{{ overviewData.current.numOrders }}</strong>
        </template>
        <template v-slot:amount>
          <strong>{{ overviewData.current.soldAmount + overviewData.current.createdAmount | niceCurrency }}</strong>
        </template>
        <template v-slot:spec>
          <i18n v-if="overviewData.current.soldAmount > 0 && overviewData.current.createdAmount > 0" path="billing.InMonthXSoldSpec" tag="span">
            <template v-slot:createdAmount>
              <strong>{{ overviewData.current.createdAmount | niceCurrency }}</strong>
            </template>
            <template v-slot:soldAmount>
              <strong>{{ overviewData.current.soldAmount | niceCurrency }}</strong>
            </template>
          </i18n>
          <i18n v-else-if="overviewData.current.createdAmount > 0" path="billing.InMonthXAllCreated" tag="span" />
        </template>
      </i18n>
      <i18n v-if="overviewData.current.refundAmount > 0" path="billing.XamountWasRefunded" tag="p">
        <template v-slot:amount>
          {{ overviewData.current.refundAmount | niceCurrency }}
        </template>
      </i18n>
      <table v-if="$perms.isAdministrator()">
        <tr>
          <td>{{ $t('billing.CommissionForX', { month: $options.filters.formatMonth(overviewData.current.period) }) }}</td>
          <td>
            <strong>{{ overviewData.current.commissionAmount | niceCurrency }}</strong>
          </td>
        </tr>
        <tr>
          <td>{{ $t('billing.CommissionForX', { month: $options.filters.formatMonth(overviewData.last.period) }) }}</td>
          <td>{{ overviewData.last.commissionAmount | niceCurrency }}</td>
        </tr>
        <tr>
          <td>{{ $t('billing.CommissionForX', { month: overviewData.currentYear.period }) }}</td>
          <td>{{ overviewData.currentYear.commissionAmount | niceCurrency }}</td>
        </tr>
        <tr>
          <td>{{ $t('billing.CommissionForX', { month: overviewData.lastYear.period }) }}</td>
          <td>{{ overviewData.lastYear.commissionAmount | niceCurrency }}</td>
        </tr>
        <tr>
          <td>{{ $t('billing.CommissionForAllTime') }}</td>
          <td>{{ overviewData.allTime.commissionAmount | niceCurrency }}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>
            <small>{{ $t('billing.ParensIncVat') }}</small>
          </td>
        </tr>
      </table>

      <table v-else>
        <tr>
          <td>{{ $t('billing.WebshopSales') }}</td>
          <td>
            {{ overviewData.current.soldAmount | niceCurrency }}
          </td>
        </tr>
        <tr>
          <td>{{ $t('billing.MinusCommission') }}</td>
          <td>
            {{ overviewData.current.commissionAmount | niceCurrency }}
          </td>
        </tr>
        <tr>
          <td>{{ $t(overviewData.current.payoutAmount >= 0 ? 'billing.ToBePaidOutColon' : 'billing.ToBePaidColon') }}</td>
          <td>
            <strong :style="overviewData.current.payoutAmount < 0 ? 'color:#e00' : ''">{{ Math.abs(overviewData.current.payoutAmount) | niceCurrency }}</strong>
          </td>
        </tr>
        <tr>
          <td>{{ $t('billing.PaidOutForXColon', { month: $options.filters.formatMonth(overviewData.last.period) }) }}</td>
          <td>{{ overviewData.last.payoutAmount | niceCurrency }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import OrderService from '@/services/OrderService'
import { FormatError } from '@/helpers/ErrorFormatting'
import { niceCurrency } from '@/helpers/Formatting'

export default {
  data() {
    return {
      loadingState: true,
      overviewData: null,
    }
  },
  created() {
    this.getOverview()
  },
  methods: {
    getOverview() {
      return new Promise((resolve, reject) => {
        OrderService.overview()
          .then(data => {
            this.loadingState = false
            this.overviewData = data
          })
          .catch(error => {
            console.log(FormatError(error))
            this.loadingState = false
          })
      })
    },
  },
  filters: {
    niceCurrency(amountInCents) {
      return niceCurrency(amountInCents)
    },
    formatMonth(month) {
      let m = moment(month + '-01')
      return m.format('MMMM')
    },
  },
}
</script>

<style lang="scss">
.billing-overview-wrap {
  table {
    margin: 0 auto;
  }
  td {
    padding: 0.3em 1em;
    border-bottom: 1px solid #ddd;
    text-align: right;
    white-space: nowrap;
    @media screen and (max-width: 500px) {
      padding: 0.3em;
    }
  }
  tr:last-child td {
    border: none;
  }
}
</style>
