<template>
  <div :style="divStyle">
    <div :style="innerDivStyle" />
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    pixelsPerMm: Number,
  },
  computed: {
    backgroundSizing() {
      if (this.element.sizing === 'stretch') {
        return '100% 100%'
      }
      return this.element.sizing
    },
    divStyle() {
      let style = {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      }
      if (this.element.useBackground) {
        style.backgroundColor = this.element.backcolor
      }
      return style
    },
    innerDivStyle() {
      let padding = this.element.padding * this.pixelsPerMm
      if (!this.element.useBackground) {
        padding = 0
      }
      let style = {
        height: 'calc(100% - ' + 2 * padding + 'px)',
        width: 'calc(100% - ' + 2 * padding + 'px)',
        position: 'absolute',
        top: padding + 'px',
        left: padding + 'px',
        backgroundPosition: '50% 50%',
        backgroundSize: this.backgroundSizing,
        backgroundRepeat: 'no-repeat',
      }
      if (this.element.image && this.element.image.url) {
        style.backgroundImage = 'url(' + this.element.image.url + ')'
      } else {
        style.backgroundColor = '#888'
      }
      return style
    },
  },
}
</script>
