<template>
  <McLayoutForm>
    <h2>{{ $t('general.LogIn') }}</h2>
    <b-tabs ref="tabs">
      <b-tab class="p-3 border-right border-left border-bottom rounded-bottom" @click="stopQr" key="email" active>
        <template #title><mc-icon icon="envelope" />&nbsp;{{ $t('general.Email') }}</template>
        <b-card-text>
          <LoginForm />
        </b-card-text>
      </b-tab>
      <b-tab class="p-3 border-right border-left border-bottom rounded-bottom" @click="startQr" key="qr">
        <template #title><mc-icon :icon="['far', 'qrcode']" />&nbsp;{{ $t('general.QR') }}</template>
        <b-card-text>
          <QrScannerNativePlusJsQr ref="scanner" @code="handleQr" :error="qrError" />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </McLayoutForm>
</template>

<script>
import { FormatError } from '@/helpers/ErrorFormatting'
import store from '@/setup/Store'
import QrService from '@/services/QrService'
import Router from '@/setup/Router'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      qrError: '',
      storedErrors: {},
    }
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    startQr() {
      this.$refs['scanner'].start()
    },
    stopQr() {
      this.$refs['scanner'].stop()
    },
    handleQr(data) {
      if (!data) {
        this.qrError = ''
        return
      }
      let matches = data.match(/(?:d\.gkort\.dk|qr\.danskegavekort\.dk|panel\.danskegavekort\.dk\/qr|192\.168\.0\.[0-9]+.*)\/(l.{8,})$/)
      if (!matches) {
        this.qrError = this.$t('qrReader.NotValidLoginQR')
        return
      }
      const token = matches[1]
      if (this.storedErrors[token]) {
        this.qrError = FormatError(this.storedErrors[token])
        return
      }
      this.qrError = this.$t('general.OneMoment')
      QrService.checkQr(token)
        .then(data => {
          this.stopQr()
          store.commit('LoginStore/loginSuccess', data.userInfo)
          if (data.userInfo.user.role !== 'qr') {
            this.toastSuccess(this.$t('loginform.Success', { name: data.userInfo.user.name }))
          }
          if (this.$route.query.from) {
            Router.push('/' + this.$route.query.from)
          } else {
            Router.push('/')
          }
        })
        .catch(error => {
          this.storedErrors[token] = error
          this.qrError = FormatError(error)
        })
    },
  },
}
</script>
