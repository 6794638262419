<template>
  <div class="d-flex flex-column h-100">
    <div class="webshopdesigner-overview-and-details" :style="'margin-left: ' + (currentSection ? '-100%' : '0')">
      <div class="webshopdesigner-overview p-2">
        <b-button block @click="currentSection = 'background'" variant="info" class="mb-2">{{ $t('webshop.BackgroundSettings') }}</b-button>
        <b-button block @click="currentSection = 'fonts'" variant="info" class="mb-2">{{ $t('webshop.FontSettings') }}</b-button>
        <b-button block @click="currentSection = 'colors'" variant="info" class="mb-2">{{ $t('webshop.ColorSettings') }}</b-button>
        <b-button block @click="currentSection = 'header'" variant="info" class="mb-2">{{ $t('webshop.HeaderSettings') }}</b-button>
        <b-button block @click="currentSection = 'shop'" variant="info" class="mb-2">{{ $t('webshop.ShopAreaSettings') }}</b-button>
        <b-button block @click="currentSection = 'seo'" variant="info" class="mb-2">{{ $t('webshop.SeoSettings') }}</b-button>
      </div>
      <div class="webshopdesigner-details p-2">
        <div key="background" v-if="currentSection == 'background'">
          <McFormImage
            name="file"
            :label="$t('webshop.BackgroundImage')"
            :placeholder="$t('webshop.PickBackgroundImage')"
            icon="image"
            v-model="curData.backgroundImage"
            :description="$t('webshop.BackgroundImageDescription')"
          />

          <mc-form-input name="backgroundBlur" type="range" :label="$t('webshop.BackgroundBlur')" min="0" max="50" step="1" v-model="curData.backgroundBlur" />

          <mc-form-color-input :label="$t('webshop.BackgroundOverlay')" :description="$t('webshop.BackgroundOverlayDescription')" v-model="curData.backgroundColor" opacity format="hex" />
        </div>

        <div key="fonts" v-if="currentSection == 'fonts'">
          <!-- eslint-disable-next-line -->
          <mc-form-input
            name="fontHeadings"
            type="font"
            :label="$t('webshop.HeadingsFont')"
            v-model="curData.fontHeadings"
          />
          <mc-form-input :icon="['fad', 'text-size']" type="number" :label="$t('designer.FontSize')" min="5" max="300" v-model="curData.fontSizeHeadings" />

          <!-- eslint-disable-next-line -->
          <mc-form-input
            name="fontDefault"
            type="font"
            :label="$t('webshop.DefaultFont')"
            v-model="curData.fontDefault"
          />
          <mc-form-input :icon="['fad', 'text-size']" type="number" :label="$t('designer.FontSize')" min="5" max="300" v-model="curData.fontSizeDefault" />

          <!-- eslint-disable-next-line -->
          <mc-form-input
            name="fontButtons"
            type="font"
            :label="$t('webshop.ButtonFont')"
            v-model="curData.fontButtons"
          />
          <mc-form-input :icon="['fad', 'text-size']" type="number" :label="$t('designer.FontSize')" min="5" max="300" v-model="curData.fontSizeButtons" />
        </div>

        <div key="colors" v-if="currentSection == 'colors'">
          <mc-form-color-input :label="$t('webshop.ThemeColor')" v-model="curData.themeColor" format="hex" />
          <mc-form-color-input :label="$t('webshop.ThemeColorActive')" v-model="curData.themeColorActive" format="hex" />
          <mc-form-color-input :label="$t('webshop.InactiveColor')" v-model="curData.inactiveColor" format="hex" />
          <mc-form-color-input :label="$t('webshop.DarkTextColor')" v-model="curData.darkTextColor" format="hex" />
          <mc-form-color-input :label="$t('webshop.LightTextColor')" v-model="curData.lightTextColor" format="hex" />
          <mc-form-color-input :label="$t('webshop.DarkBackColor')" v-model="curData.darkBackColor" format="hex" opacity />
          <mc-form-color-input :label="$t('webshop.LightBackColor')" v-model="curData.lightBackColor" format="hex" opacity />
        </div>

        <div key="header" v-if="currentSection == 'header'">
          <mc-form-checkboxes :label="$t('webshop.HeaderStyle')" v-model="curData.headerStyle" :icon="['fad', 'eye-dropper']" :options="headerStyleOptions" />

          <mc-form-checkbox :description="$t('webshop.BorderDescription')" v-model="curData.headerBorder" :unchecked-value="false">{{ $t('webshop.HeaderBorder') }}</mc-form-checkbox>
          <mc-form-input name="headerShadowSize" type="range" :label="$t('webshop.HeaderShadowSize')" min="0" max="150" step="1" v-model="curData.headerShadowSize" />
          <mc-form-input name="headerShadowOpacity" type="range" :label="$t('webshop.HeaderShadowOpacity')" min="0" max="100" step="1" v-model="curData.headerShadowOpacity" />

          <mc-form-input name="heading" type="text" :label="$t('webshop.Heading')" v-model="curData.heading" :placeholder="$t('webshop.EnterHeading')" :description="$t('webshop.HeadingDescription')" />
          <mc-form-checkboxes :label="$t('webshop.HeadingColor')" v-model="curData.headingColor" :icon="['fad', 'eye-dropper']" :options="headingColorOptions" />

          <mc-form-checkbox :description="$t('webshop.ShowLogoInHeadingDescription')" v-model="curData.logoInHeading" :unchecked-value="false">{{ $t('webshop.ShowLogoInHeading') }}</mc-form-checkbox>

          <div class="d-flex">
            <McFormInput type="number" unit="px" :min="30" :max="300" :step="1" :label="$t('webshop.MaxWidth')" :value="curData.logoMaxWidth" @input="v => (curData.logoMaxWidth = parseInt(v))" />
            <McFormInput type="number" unit="px" :min="30" :max="300" :step="1" :label="$t('webshop.MaxHeight')" :value="curData.logoMaxHeight" @input="v => (curData.logoMaxHeight = parseInt(v))" />
          </div>
        </div>

        <div key="shop" v-if="currentSection == 'shop'">
          <mc-form-checkboxes :label="$t('webshop.ShopStyle')" v-model="curData.shopStyle" :icon="['fad', 'eye-dropper']" :options="headerStyleOptions" />

          <mc-form-checkbox :description="$t('webshop.BorderDescription')" v-model="curData.shopBorder" :unchecked-value="false">{{ $t('webshop.ShopBorder') }}</mc-form-checkbox>
          <mc-form-input name="shopShadowSize" type="range" :label="$t('webshop.ShopShadowSize')" min="0" max="150" step="1" v-model="curData.shopShadowSize" />
          <mc-form-input name="shopShadowOpacity" type="range" :label="$t('webshop.ShopShadowOpacity')" min="0" max="100" step="1" v-model="curData.shopShadowOpacity" />

          <mc-form-input name="shopTitle" type="text" :label="$t('webshop.ShopTitle')" v-model="curData.shopTitle" :placeholder="$t('webshop.EnterTitle')" />

          <mc-form-input name="shopText" type="wysiwyg" :label="$t('webshop.ShopText')" v-model="curData.shopText" :placeholder="$t('webshop.EnterShopText')" buttons="most" />

          <mc-form-input name="buttonRounding" type="range" :label="$t('webshop.ButtonRounding')" min="0" max="100" step="1" v-model="curData.buttonRounding" />

          <mc-form-checkbox :description="$t('webshop.ShowMoreForCategoriesDescription')" v-model="curData.showMoreCats" :unchecked-value="false">{{ $t('webshop.EnableShowMoreForCategories') }}</mc-form-checkbox>

          <mc-form-checkbox :description="$t('webshop.ShowMoreForGiftcardsDescription')" v-model="curData.showMoreGiftcards" :unchecked-value="false">{{ $t('webshop.EnableShowMoreForGiftcards') }}</mc-form-checkbox>

          <b-form-group :label="$t('webshop.ButtonAlignment')" v-if="curData.showMoreCats || curData.showMoreGiftcards">
            <b-button-group size="sm" class="mx-auto">
              <McButton :icon="['far', 'align-left']" @click="curData.buttonAlign = 'left'" :variant="curData.buttonAlign === 'left' ? 'primary' : 'outline-secondary'" />
              <McButton :icon="['far', 'align-center']" @click="curData.buttonAlign = 'center'" :variant="curData.buttonAlign === 'center' ? 'primary' : 'outline-secondary'" />
              <McButton :icon="['far', 'align-right']" @click="curData.buttonAlign = 'right'" :variant="curData.buttonAlign === 'right' ? 'primary' : 'outline-secondary'" />
              <McButton :icon="['far', 'align-justify']" @click="curData.buttonAlign = 'wide'" :variant="curData.buttonAlign === 'wide' ? 'primary' : 'outline-secondary'" />
            </b-button-group>
          </b-form-group>
        </div>

        <div key="seo" v-if="currentSection == 'seo'">
          <mc-form-checkboxes :label="$t('webshop.SEOStyle')" v-model="curData.seoStyle" :icon="['fad', 'eye-dropper']" :options="headerStyleOptions" />

          <mc-form-checkbox :description="$t('webshop.BorderDescription')" v-model="curData.seoBorder" :unchecked-value="false">{{ $t('webshop.SEOBorder') }}</mc-form-checkbox>
          <mc-form-input name="seoShadowSize" type="range" :label="$t('webshop.SEOShadowSize')" min="0" max="150" step="1" v-model="curData.seoShadowSize" />
          <mc-form-input name="seoShadowOpacity" type="range" :label="$t('webshop.SEOShadowOpacity')" min="0" max="100" step="1" v-model="curData.seoShadowOpacity" />

          <mc-form-input name="title" type="text" :label="$t('webshop.Title')" v-model="curData.title" :placeholder="$t('webshop.EnterTitle')" :description="$t('webshop.TitleDescription')" />

          <mc-form-input
            name="description"
            type="wysiwyg"
            :label="$t('webshop.SeoText')"
            v-model="curData.seoText"
            :placeholder="$t('webshop.EnterSeoText')"
            :description="$t('webshop.SeoTextDescription')"
            buttons="most"
          />
        </div>
      </div>
    </div>
    <div class="buttons-for-webshop-designer text-center border-top bg-light">
      <transition name="transition-for-webshop-designer-buttons">
        <div v-if="!currentSection" class="p-2">
          <mc-button variant="outline-secondary" @click="closeIfUnchanged" class="mr-2" :text="$t('general.Close')" />
          <mc-button @click="saveDesignChanges" variant="success" icon="save" :title="$t('designer.SaveDesign')" :text="$t('designer.SaveDesign')" />
        </div>
        <div v-else key="c" class="p-2">
          <div class="float-left">
            <mc-button @click="currentSection = false" variant="primary" :icon="['far', 'arrow-left']" :text="$t('designer.Back')" />
          </div>
        </div>
      </transition>
    </div>

    <b-modal id="save-design-modal" :title="$t('webshop.SaveDesign')">
      {{ $t('webshop.DoYouWantToSaveTheDesign') }}
      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="discardDesignChanges">{{ $t('webshop.DiscardChanges') }}</b-button>
        <b-button size="sm" variant="secondary" @click="$bvModal.hide('save-design-modal')">{{ $t('webshop.Cancel') }}</b-button>
        <b-button size="sm" variant="success" @click="saveDesignChangesAndClose">{{ $t('webshop.SaveChanges') }}</b-button>
      </template>
    </b-modal>
    <!--pre>{{ curData }}</pre-->
  </div>
</template>

<script>
import { FormatError } from '@/helpers/ErrorFormatting'
import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import WebshopDesignsService from '@/services/WebshopDesignsService'

export default {
  data() {
    return {
      origData: null,
      curData: {},
      loadingState: true,
      currentSection: false,
    }
  },
  props: {
    merchantId: Number,
  },
  computed: {
    headerStyleOptions() {
      return [
        { value: 'lightOnDark', label: this.$t('webshop.LightOnDark') },
        { value: 'darkOnLight', label: this.$t('webshop.DarkOnLight') },
      ]
    },
    headingColorOptions() {
      return [
        { value: 'textColor', label: this.$t('webshop.TextColor') },
        { value: 'themeColor', label: this.$t('webshop.ThemeColor') },
      ]
    },
    seoStyleOptions() {
      return [
        { value: 'lightOnDark', label: this.$t('webshop.LightOnDark') },
        { value: 'darkOnLight', label: this.$t('webshop.DarkOnLight') },
      ]
    },
  },
  watch: {
    curData: {
      deep: true,
      handler: function (val) {
        this.handleChange(val)
      },
    },
  },

  mounted() {
    WebshopDesignsService.getDefault(this.merchantId)
      .then(data => {
        this.curData = data.design
        this.origData = JSON.stringify(data.design)
        this.loadingState = false
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
    window.addEventListener('beforeunload', this.warnWhenLeaving)
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    warnWhenLeaving(e) {
      let nowData = JSON.stringify(this.curData)
      if (this.origData !== nowData) {
        var confirmationMessage = this.$t('general.IfYouLeaveYourChangesWillBeLost')
        ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
        return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
      }
    },

    closeIfUnchanged() {
      let nowData = JSON.stringify(this.curData)
      if (this.origData === nowData) {
        this.discardDesignChanges()
      }

      this.$bvModal.show('save-design-modal')
    },

    discardDesignChanges() {
      window.removeEventListener('beforeunload', this.warnWhenLeaving)
      this.$emit('cancel')
    },

    saveDesignChangesAndClose() {
      this.saveDesignChanges().then(() => {
        this.discardDesignChanges()
      })
    },

    handleChange: debounce(function (val) {
      this.$emit('setDesign', val)
    }, 500),

    saveDesignChanges(e) {
      return WebshopDesignsService.setDefault(this.merchantId, this.curData).then(
        data => {
          this.toastSuccess(this.$t('general.ChangesSaved'))
          this.origData = JSON.stringify(this.curData)
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving design', error)
          if (error == 'authorization.notAuthorized') {
            return Promise.reject(this.$t('permissions.LackingDesignWebshopPermission'))
          }
          return Promise.reject(FormatError(error))
        },
      )
    },
  },
}
</script>

<style lang="scss">
.webshopdesigner-overview-and-details {
  width: 200%;
  position: relative;
  display: flex;
  transition: margin 0.2s;
  flex-grow: 1;
  overflow: hidden;
}
.webshopdesigner-overview,
.webshopdesigner-details {
  position: absolute;
  width: 50%;
  top: 0;
  height: 100%;
  left: 0;
  overflow-y: scroll;
}
.webshopdesigner-details {
  left: 50%;
}
.buttons-for-webshop-designer {
  position: relative;
  height: 3.4em;
  flex-shrink: 0;
}
.buttons-for-webshop-designer > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.transition-for-webshop-designer-buttons-leave-active,
.transition-for-webshop-designer-buttons-enter-active {
  transition: all 0.2s ease;
}

.transition-for-webshop-designer-buttons-enter {
  transform: translateY(-20px);
  opacity: 0;
}

.transition-for-webshop-designer-buttons-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
