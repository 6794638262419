<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group v-if="!isSmall">
      <b-input-group-addon is-text>{{ $t('general.From') }}</b-input-group-addon>
      <!-- prettier-ignore -->
      <date-picker v-if="!nativeSupport"
        :config="internalConfigFirst"
        v-model="fromDate"
        readonly="readonly"
      />
      <b-form-input v-else type="datetime-local" :min="minDateForNative" :max="maxDateForNative" v-model="fromDate" />
      <b-input-group-addon is-text>{{ $t('general.To') }}</b-input-group-addon>
      <!-- prettier-ignore -->
      <date-picker v-if="!nativeSupport"
        :config="internalConfig"
        v-model="toDate"
        readonly="readonly"
      />
      <b-form-input v-else type="datetime-local" :min="minDateForNative" :max="maxDateForNative" v-model="toDate" />
    </b-input-group>

    <b-input-group v-if="isSmall">
      <b-input-group-addon is-text class="fixed-width-addon">{{ $t('general.From') }}</b-input-group-addon>
      <!-- prettier-ignore -->
      <date-picker v-if="!nativeSupport"
        :config="internalConfig"
        v-model="fromDate"
        readonly="readonly"
      />
      <b-form-input v-else type="datetime-local" :min="minDateForNative" :max="maxDateForNative" v-model="fromDate" />
    </b-input-group>
    <b-input-group v-if="isSmall">
      <b-input-group-addon is-text class="fixed-width-addon">{{ $t('general.To') }}</b-input-group-addon>
      <!-- prettier-ignore -->
      <date-picker v-if="!nativeSupport"
        :config="internalConfig"
        v-model="toDate"
        readonly="readonly"
      />
      <b-form-input v-else type="datetime-local" :min="minDateForNative" :max="maxDateForNative" v-model="toDate" />
    </b-input-group>

    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<style lang="scss" scoped>
.fixed-width-addon .input-group-text {
  min-width: 80px;
  text-align: right;
  display: inline;
}
input:read-only {
  background: #fff;
}
</style>

<script>
import { mapState } from 'vuex'
import { dateTodatetimeLocalString, datetimeLocalStringToDate } from '../helpers/Time'
import moment from 'moment'

export default {
  data() {
    return {
      componentWidth: 0,
      nativeSupport: false,
    }
  },
  methods: {
    handleResize() {
      const target = this.$el
      this.componentWidth = target.getBoundingClientRect().width
      if (this.componentWidth === 0) {
        this.componentWidth = 2000
        setTimeout(this.handleResize, 500)
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    //Use native input on touch devices that support it
    if (this.isTouchDevice) {
      var i = document.createElement('input')
      i.setAttribute('type', 'datetime-local')
      this.nativeSupport = i.type !== 'text'
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    internalConfigFirst() {
      return {
        ...this.internalConfig,
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'auto',
        },
      }
    },
    internalConfig() {
      return Object.assign(
        {},
        {
          useCurrent: false,
          inline: false,
          locale: this.LanguageStore.currentLanguage,
          ignoreReadonly: true,
          showClose: true,
          widgetPositioning: {
            horizontal: 'right',
            vertical: 'auto',
          },
          icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'fas fa-calendar-check',
            clear: 'far fa-trash-alt',
            close: 'far fa-check-circle',
          },
        },
        this.min ? { minDate: moment(this.min) } : {},
        this.max ? { maxDate: moment(this.max) } : {},
        this.config,
      )
    },
    minDateForNative() {
      if (!this.min) {
        return null
      }
      return dateTodatetimeLocalString(this.min)
    },
    maxDateForNative() {
      if (!this.max) {
        return null
      }
      return dateTodatetimeLocalString(this.max)
    },
    fromDate: {
      set: function (val) {
        this.value.from = datetimeLocalStringToDate(val).toDate()
        if (this.value.to < this.value.from) {
          this.value.to = moment(moment(this.value.from).format('YYYY-MM-DD') + 'T' + moment(this.value.to).format('HH:mm'), 'YYYY-MM-DDTHH:mm')
          if (this.value.to < this.value.from) {
            this.value.to = moment(this.value.to).add(1, 'days').toDate()
          }
        }
      },
      get: function () {
        if (this.nativeSupport) {
          return dateTodatetimeLocalString(this.value.from)
        }
        return this.value.from
      },
    },
    toDate: {
      set: function (val) {
        this.value.to = datetimeLocalStringToDate(val).toDate()
        if (this.value.to < this.value.from) {
          this.value.from = moment(moment(this.value.to).format('YYYY-MM-DD') + 'T' + moment(this.value.from).format('HH:mm'), 'YYYY-MM-DDTHH:mm')
          if (this.value.to < this.value.from) {
            this.value.from = moment(this.value.from).subtract(1, 'days').toDate()
          }
        }
      },
      get: function () {
        if (this.nativeSupport) {
          return dateTodatetimeLocalString(this.value.to)
        }
        return this.value.to
      },
    },
    isSmall() {
      return this.componentWidth < 420
    },
    isTouchDevice() {
      return 'ontouchstart' in window /* works on most browsers */ || navigator.maxTouchPoints /* works on IE10/11 and Surface */
    },
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    ...mapState({
      LanguageStore: state => state.LanguageStore,
    }),
  },
  props: {
    errorHint: String,
    label: String,
    description: String,
    min: Date,
    max: Date,
    config: {
      type: Object,
      default() {
        return {}
      },
    },
    value: {
      type: Object,
      default() {
        return {
          from: new Date(),
          to: new Date(),
        }
      },
    },
  },
}
</script>
