<template>
  <McLayoutDefault :heading="$t('headings.Orders')">
    <!--pre>{{ LoginStore.merchantInfo }}</pre-->
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.hasMerchantsWithPermission('seeOrders')">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <div v-else>
      <b-row>
        <b-col cols="12" lg="6" class="d-flex">
          <b-card class="mb-2 flex-fill">
            <p>{{ $t('orders.Description1') }}</p>
            <p v-if="$perms.hasPermission('createOrders') || $perms.isAdministrator()">{{ $t('orders.DescriptionManualOrders') }}</p>
            <i18n path="orders.Description2" tag="p">
              <template v-slot:link>
                <router-link to="/billing">{{ $t('headings.Billing') }}</router-link>
              </template>
            </i18n>
            <MerchantSelector allowAll permission="seeOrders" v-model="merchantId" />
            <label v-if="$perms.isAdministrator()">
              <input type="checkbox" v-model="includeTestMode" />
              {{ $t('general.IncludeTestOrders') }}
            </label>
          </b-card>
        </b-col>
        <b-col cols="12" lg="6" class="d-flex">
          <ChartCodes class="mb-2 flex-fill" :merchantId="merchantId" :includeTestMode="includeTestMode" />
          <!--chartOrders class="mb-2 flex-fill" :merchantId="merchantId" /-->
        </b-col>
      </b-row>

      <CreateOrderButtonAndForm :merchantId="merchantId" @orderCreated="refreshTable" class="mb-2 float-right" />
      <div style="clear: both"></div>
      <!-- prettier-ignore -->
      <McTable
        class="orders-table"
        ref="ordersTable"
        :emptyText="$t('orders.YouHaveNoOrders')"
        :sortBy="sortBy"
        sortDesc
        :primaryKey="primaryKey"
        :fields="fields"
        :provider="dataProvider"
      >
        <b-button-group>
          <McButton v-if="$perms.isAdministrator()" @click="viewType = 'admin'" :variant="viewType == 'admin' ? 'primary' : 'secondary'" size="sm" :icon="['far', 'search-dollar']" />
          <McButton @click="viewType = 'compact'" :variant="viewType == 'compact' ? 'primary' : 'secondary'" size="sm" :icon="['far', 'th-list']" />
          <!--McButton @click="viewType = 'detailed'" :variant="viewType == 'detailed' ? 'primary' : 'secondary'" size="sm" :icon="['far', 'th-large']" /-->
        </b-button-group>

        <template v-slot:cell(certificateIds)="data">
          <ul class="flat_list m-0 certificate_list">
            <li v-for="(certificate, code) in data.item.certificates" v-bind:key="code">
              <Certificate @redeemed="refreshTable" @extended="refreshTable" :certificate="certificate" />
            </li>
          </ul>
        </template>

        <template v-slot:cell(paymentDetails)="data">
          <pre style="width: 750px; height: 120px; overflow: auto; background: #ddd">{{ data.item.paymentDetails }}</pre>
        </template>
        <template v-slot:cell(dateCreated)="data">
          {{ data.item.dateCreated | formatDate }}
          <br>
          <b-badge v-if="data.item.method === 'webshop'" variant="success">{{ data.item.method }}</b-badge>
          <b-badge v-else-if="data.item.method === 'integration'" variant="info">{{ data.item.method }}</b-badge>
          <b-badge v-else-if="data.item.method === 'manual'" variant="primary">{{ data.item.method }}</b-badge>
          <b-badge v-else-if="data.item.method === 'import'" variant="warning">{{ data.item.method }}</b-badge>
          <b-badge v-else variant="dark">{{ data.item.method }}</b-badge>
        </template>
        <template v-slot:cell(orderId)="data">
          <span>{{ data.item.orderId }}</span>
          <div v-if="data.item.meta.orderNum">
            <small>
              {{ $t('orders.ExternalColon') }}
              <a v-if="data.item.meta.orderLink" :href="data.item.meta.orderLink" target="_blank">{{ data.item.meta.orderNum }}</a>
              <span v-else>{{ data.item.meta.orderNum }}</span>
            </small>
          </div>
        </template>
        <template v-slot:cell(invoiceId)="data">
          <a v-if="data.item.invoiceId" :href="data.item.invoiceLink" target="_blank">{{ data.item.invoiceId }}</a>
        </template>
        <template v-slot:cell(amount)="data">
          {{ data.item.amount | niceCurrency }}
        </template>
        <template v-slot:cell(refund)="data">
          <div v-if="!data.item.refunded">
            <McButton
              v-if="$perms.isAdministrator() || $perms.hasPermission('refundOrders', data.item.merchantId)"
              size="sm"
              :icon="['fad', 'undo']"
              :text="$t('orders.Refund')"
              @click="refundOrder(data.item)"
            />
            <span v-else :title="$t('permissions.LackingPermissions')">-</span>
          </div>
          <div v-else>
            {{ $t('orders.RefundedX', { date: $options.filters.formatDate(data.item.dateRefunded) }) }}
          </div>
        </template>
        <template v-slot:cell(invoiceEtc)="data">
          <label class="flat_list_label">{{ $t('orders.OrderIdColon') }}</label>
          <ul class="flat_list">
            <li v-if="data.item.meta.orderLink">
              <a :href="data.item.meta.orderLink" target="_blank">{{ data.item.meta.orderNum }}</a>
            </li>
            <li v-else>{{ data.item.orderId }}</li>
          </ul>
          <template v-if="data.item.invoiceId">
            <label class="flat_list_label">{{ $t('orders.InvoiceIdColon') }}</label>
            <ul class="flat_list">
              <li>
                <a :href="data.item.invoiceLink" target="_blank">{{ data.item.invoiceId }}</a>
              </li>
            </ul>
          </template>
          <label class="flat_list_label">{{ $tc('orders.CertificateIdsColon', Object.keys(data.item.certificates).length) }}</label>
          <ul class="flat_list">
            <li v-for="(certificate, code) in data.item.certificates" v-bind:key="code">
              <Certificate @redeemed="refreshTable" :certificate="certificate" />
            </li>
          </ul>
        </template>
        <template v-slot:cell(email)="data">
          <a :href="'mailto:' + data.item.customer.email">{{ data.item.customer.email }}</a>
        </template>
        <template v-slot:cell(phone)="data">
          <a :href="'tel:' + data.item.customer.phone">{{ data.item.customer.phone }}</a>
        </template>
        <template v-slot:cell(address)="data">
          <McAddress v-if="data.item.invoiceType == 'full' || ['integration','import','offline'].includes(data.item.method)" :address="data.item.customer" />
          <span v-else>-</span>
        </template>
        <template v-slot:cell(delivery)="data">
          <div v-if="data.item.delivery">
            <strong>{{ data.item.delivery.headline }}</strong>
            <McAddress v-if="data.item.delivery.address" :address="data.item.delivery.address" />
          </div>
          <span v-else>-</span>
        </template>
        <template v-slot:cell(buyer)="data">
          <label class="flat_list_label">{{ $t('general.EmailColon') }}</label>
          <ul class="flat_list">
            <li>
              <a :href="'mailto:' + data.item.customer.email">{{ data.item.customer.email }}</a>
            </li>
          </ul>
          <label class="flat_list_label">{{ $t('general.PhoneColon') }}</label>
          <ul class="flat_list">
            <li>
              <a :href="'tel:' + data.item.customer.phone">{{ data.item.customer.phone }}</a>
            </li>
          </ul>
          <label class="flat_list_label">{{ $t('general.AddressColon') }}</label>
          <ul class="flat_list">
            <li v-if="data.item.invoiceType == 'full' || ['integration','import'].includes(data.item.method)">
              <a href="#" @click.prevent="$set(shownAddresses, data.item.id, !shownAddresses[data.item.id])">
                {{ $t(shownAddresses[data.item.id] ? 'orders.HideAddress' : 'orders.ShowAddress') }}
              </a>
              <McAddress v-if="shownAddresses[data.item.id]" :address="data.item.customer" />
            </li>
            <li v-else>-</li>
          </ul>
        </template>
        <template v-slot:cell(meta)="data">
          <table class="meta_table">
            <tbody>
              <tr v-for="(v, k) in data.item.filteredMeta" v-bind:key="k">
                <td>{{ k }}</td>
                <td>{{ v }}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </McTable>
    </div>
    <RefundModal ref="refundModal" :orderInfo="this.refundOrder" @refunded="refreshTable" />
  </McLayoutDefault>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import OrderService from '@/services/OrderService'
import { niceCurrency } from '@/helpers/Formatting'

export default {
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'dateCreated',
      shownAddresses: {},
      viewType: 'compact',
      merchantId: null,
      includeTestMode: false,
    }
  },
  watch: {
    merchantId() {
      this.refreshTable()
    },
    includeTestMode() {
      this.refreshTable()
    },
  },
  computed: {
    fields() {
      let retval = []
      retval.push({ key: 'dateCreated', priority: 0, mw: 150, sortable: true, label: this.$t('general.Date') })
      if (this.viewType == 'admin') {
        retval.push({ key: 'orderId', priority: 1, mw: 200, sortable: true, label: this.$t('orders.OrderId') })
        retval.push({ key: 'invoiceId', priority: 3, mw: 200, sortable: true, label: this.$t('orders.InvoiceId') })
        retval.push({ key: 'paymentCardBrand', priority: 1, mw: 150, sortable: true, label: this.$t('orders.CardBrand') })
        retval.push({ key: 'paymentCardCountry', priority: 1, mw: 150, sortable: true, label: this.$t('orders.CardCountry') })
        retval.push({ key: 'paymentAcquirer', priority: 1, mw: 150, sortable: true, label: this.$t('orders.Acquirer') })
        retval.push({ key: 'paymentFacilitator', priority: 2, mw: 150, sortable: true, label: this.$t('orders.Facilitator') })
        retval.push({ key: 'paymentDetails', priority: 2, mw: 300, sortable: true, label: this.$t('orders.PaymentDetails') })
        retval.push({ key: 'certificateIds', priority: 9, mw: 200, sortable: false, label: this.$t('orders.Certificates') })
        retval.push({ key: 'email', priority: 4, mw: 200, sortable: false, label: this.$t('general.Email') })
        retval.push({ key: 'phone', priority: 4, mw: 200, sortable: false, label: this.$t('general.Phone') })
        retval.push({ key: 'address', priority: 4, mw: 200, sortable: false, label: this.$t('general.Address') })
      } else if (this.viewType == 'compact') {
        retval.push({ key: 'orderId', priority: 1, mw: 200, sortable: true, label: this.$t('orders.OrderId') })
        retval.push({ key: 'invoiceId', priority: 1, mw: 200, sortable: true, label: this.$t('orders.InvoiceId') })
        retval.push({ key: 'certificateIds', priority: 0, mw: 240, sortable: false, label: this.$t('orders.Certificates') })
        retval.push({ key: 'email', priority: 2, mw: 200, sortable: false, label: this.$t('general.Email') })
        retval.push({ key: 'phone', priority: 2, mw: 200, sortable: false, label: this.$t('general.Phone') })
        retval.push({ key: 'address', priority: 3, mw: 200, sortable: false, label: this.$t('general.Address') })
        retval.push({ key: 'delivery', priority: 3, mw: 200, sortable: false, label: this.$t('general.Delivery') })
      } else {
        retval.push({ key: 'invoiceEtc', priority: 0, mw: 200, sortable: false, label: this.$t('orders.OrderIdEtc') })
        retval.push({ key: 'buyer', priority: 1, mw: 200, sortable: false, label: this.$t('orders.Buyer') })
      }
      retval.push({ key: 'amount', priority: 0, mw: 150, sortable: true, label: this.$t('orders.OrderAmount'), class: 'text-right' })
      if (this.$perms.isAdministrator() || this.$perms.hasMerchantsWithPermission('refundOrders')) {
        retval.push({ key: 'refund', priority: 9, mw: 200, sortable: false, label: this.$t('orders.Refund') })
      }
      retval.push({ key: 'meta', priority: 9, mw: 200, showInDetailsIf: 'showMeta', sortable: false, label: this.$t('orders.Meta') })

      return retval
    },
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
  },
  methods: {
    dataProvider(ctx) {
      return OrderService.query(ctx, this.merchantId, this.$perms.isAdministrator() ? this.includeTestMode : null).then(e => {
        for (var i in e.items) {
          var filteredMeta = {}
          for (const [k, v] of Object.entries(e.items[i].meta)) {
            if (['orderNum', 'orderLink'].includes(k)) {
              continue
            }
            filteredMeta[k] = v
          }
          e.items[i].filteredMeta = filteredMeta
          e.items[i].showMeta = Object.keys(e.items[i].filteredMeta).length > 0
        }
        return e
      })
    },
    refreshTable() {
      if (this.$refs.ordersTable) {
        this.$refs.ordersTable.refresh()
      }
    },
    refundOrder(order) {
      this.$refs['refundModal'].show(order)
    },
  },
  filters: {
    niceCurrency(amountInCents) {
      return niceCurrency(amountInCents)
    },
    formatDate(date) {
      return moment(date).format('ll')
    },
  },
}
</script>

<style lang="scss">
.flat_list_label {
  font-size: 0.6em;
  margin: 0;
  text-transform: uppercase;
}
.flat_list {
  font-size: 0.9em;
  margin: 0 0 0 0.4em;
  padding: 0;
  list-style: none;
}
.ordercreatedtable {
  margin: 0 auto;
  td {
    padding: 0.2em 0.6em;
  }
}
.certificate_list {
  max-height: 400px;
  overflow-y: auto;
}
.meta_table {
  margin: 0;
  width: 100%;
  font-size: .8em;
  td {
    padding: 0.2em 0.6em;
  }
}
</style>
