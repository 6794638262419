import { apiRequest, authorizedApiRequest } from '@/setup/ApiService'

export default {
  checkQr: function (token) {
    return apiRequest(
      '/qr/' + token, //
      'GET',
    )
  },

  isAvailable: function (token) {
    return authorizedApiRequest(
      `/qr/${token}/is-available`, //
      'GET',
    )
  },
}
