<template>
  <div>
    {{ merchantName }}
    <b-badge v-if="hasAll" variant="success">{{ $t('permissions.All') }}</b-badge>
    <b-badge v-if="!hasAll" variant="warning">{{ $t('permissions.Some') }}</b-badge>
    <ul v-if="!hasAll" class="permission_list">
      <li v-for="(permGranted, permId) in grantedPerms" v-bind:key="permId">{{ $t('permissions.' + permId) }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    merchantName: String,
    permissions: Object,
  },
  computed: {
    hasAll() {
      let hasAll = true
      for (let permId in this.permissions) {
        if (!this.permissions[permId]) {
          hasAll = false
        }
      }
      return hasAll
    },
    grantedPerms() {
      let ret = {}
      for (let permId in this.permissions) {
        if (this.permissions[permId]) {
          ret[permId] = true
        }
      }
      return ret
    },
  },
}
</script>

<style lang="scss">
.permission_list {
  font-size: 0.9em;
  margin: 0;
  padding: 0 0 0 1em;
  li {
    padding: 0;
    margin: 0;
  }
}
</style>
