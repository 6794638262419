<template>
  <McLayoutDefault :heading="$tc('headings.Merchants', this.$perms.hasSeveralMerchants() ? 2 : 1)">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="$perms.isQrUser()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <MerchantsAdmin v-else-if="$perms.isAdministrator()" />
    <MerchantsNonAdmin v-else />
  </McLayoutDefault>
</template>

<script>
import MerchantsAdmin from '@/views/protected/MerchantsAdmin'
import MerchantsNonAdmin from '@/views/protected/MerchantsNonAdmin'

export default {
  components: {
    MerchantsAdmin,
    MerchantsNonAdmin,
  },
}
</script>
