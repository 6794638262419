<template>
  <div :style="divStyle" class="designer-view-qr-wrap"></div>
</template>

<script>
export default {
  props: {
    giftcard: Object,
    merchant: Object,
    element: Object,
    pixelsPerMm: Number,
  },
  computed: {
    divStyle() {
      let style = {
        backgroundColor: this.element.backcolor,
        backgroundImage: 'url(https://api.danskegavekort.dk/qr/example/' + this.merchant.id + '/' + this.giftcard.id + '?forecolor=' + this.element.forecolor.substring(1) + ')',
      }
      return style
    },
  },
}
</script>
<style lang="scss" scoped>
.designer-view-qr-wrap {
  height: 100%;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
</style>
