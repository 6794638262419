<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    options: null,
  },
  mounted() {
    this.addPlugin({
      id: 'my-plugin',
      beforeLayout: (chart, options) => {
        this.$emit('beforeLayout', chart, options)
      },
    })
    this.renderChart(this.chartData, this.options)
  },
}
</script>
