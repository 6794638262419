<template>
  <address>
    <div v-if="address.type === 'I'">
      <strong>{{ $t('general.ContactTypeIndividual') }}</strong>
    </div>
    <div v-if="address.type === 'C'">
      <strong>{{ $t('general.ContactTypeCompany') }}</strong>
    </div>
    <div v-if="address.type === 'A'">
      <strong>{{ $t('general.ContactTypeAssociation') }}</strong>
    </div>
    <div v-if="address.type === 'P'">
      <strong>{{ $t('general.ContactTypePublic') }}</strong>
    </div>
    <div v-if="address.name && (address.type === 'I' || !address.type)">{{ address.name }}</div>
    <div v-if="address.company">{{ address.company }}</div>
    <div v-if="address.name && ['C', 'A', 'P'].includes(address.type)">att: {{ address.name }}</div>
    <div v-if="address.address1">{{ address.address1 }}</div>
    <div v-if="address.address2">{{ address.address2 }}</div>
    <div>{{ address.zip }} {{ address.city }}</div>
    <div v-if="address.country && !['danmark', 'denmark'].includes(address.country.toLowerCase())">{{ address.country }}</div>
    <div v-if="address.cvr">
      <small>{{ $t('general.CvrColon', { cvr: address.cvr }) }}</small>
    </div>
    <div v-if="address.ean">
      <small>{{ $t('general.EanColon', { ean: address.ean }) }}</small>
    </div>
    <div v-if="address.reference">
      <small>{{ $t('general.ReferenceColon', { reference: address.reference }) }}</small>
    </div>
    <div v-if="address.rekvisition">
      <small>{{ $t('general.RekvisitionColon', { rekvisition: address.rekvisition }) }}</small>
    </div>
  </address>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
