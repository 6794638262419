<template>
  <div @click.stop="toggleDetails" :style="forceDetailsOut ? 'cursor: default' : ''" :class="'mb-2 certificate-code-wrap' + (detailsOut ? ' details-out' : '')">
    <!--pre>{{ certificate }}</pre-->
    <div class="certificate__badges">
      <b-badge class="certificate__code" variant="light">{{ certificate.displayCode }}</b-badge>
      <span class="certificate__amountanddate">
        <b-badge :variant="variantAmount">{{ displayAmount }}</b-badge>
        <b-badge v-if="certificate.refunded" variant="danger">{{ $t('general.Refunded') }}</b-badge>
        <b-badge v-else :variant="certificate.expired || certificate.balance == 0 ? 'danger' : 'success'">{{
          certificate.balance == 0 ? $t('general.Spent') : $t('general.ExpiryColon') + ' ' + displayExpiry
        }}</b-badge>
      </span>
    </div>
    <div v-if="detailsOut || forceDetailsOut" class="text-center certificate__details">
      <McButton v-if="certificate.link" @click.stop="" :href="certificate.link" target="_blank" class="m-2" size="sm" :text="$t('general.ShowCertificate')" />
      <McButton @click.stop="showRedeemModalFocused" class="m-2" size="sm" :text="$t('general.Redeem')" />
      <McButton
        v-if="!certificate.refunded && certificate.expired && certificate.balance > 0 && ($perms.isAdministrator() || $perms.hasPermission('extendCodes', certificate.merchant))"
        @click.stop="$refs['extendModal'].show()"
        class="m-2"
        size="sm"
        :text="$t('general.Extend')"
      />
      <div class="text-left small p-2 certificate__events" v-if="shownEvents.length > 0">
        <p class="m-0" v-for="(event, key) in shownEvents" v-bind:key="key">
          <strong>{{ event.date | formatDateTime }}</strong>
          <span> - </span>
          <em>{{ event.userName }}</em>
          <br />
          <span class="certificate__eventsarrow">&rarr;</span><span>{{ formatEvent(event) }}</span>
          <span v-if="event.meta"> ({{ event.meta }})</span>
        </p>
      </div>
      <div class="text-left small p-2 certificate__metas" v-if="false && shownMetas.length > 0">
        <p class="m-0" v-for="(meta, key) in shownMetas" v-bind:key="key">
          <strong>{{ meta.key }}</strong>
          :
          <br />
          <span> {{ meta.value }}</span>
        </p>
      </div>
    </div>
    <RedeemModal ref="redeemModal" :codeInfo="this.certificate" @redeemed="$emit('redeemed')" />
    <ExtendModal ref="extendModal" :codeInfo="this.certificate" @extended="$emit('extended')" />
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { niceCurrency } from '@/helpers/Formatting'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      detailsOut: false,
      spentAmount: -1,
    }
  },
  props: {
    certificate: Object,
    forceDetailsOut: Boolean,
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
      'toastWarning',
    ]),
    formatEvent(event) {
      switch (event.event) {
        case 'purchase':
          if (event.userId > 0) {
            return this.$t('events.CreatedManually', { amount: niceCurrency(event.amount) })
          } else {
            return this.$t('events.Sold', { amount: niceCurrency(event.amount) })
          }
        case 'spend':
          if (event.amount < 0) {
            return this.$t('events.Redeemed', { amount: niceCurrency(-event.amount) })
          } else {
            return this.$t('events.Refilled', { amount: niceCurrency(event.amount) })
          }
        case 'refund':
          return this.$t('events.Refunded', { amount: niceCurrency(-event.amount) })
        case 'expire':
          return this.$t('events.Expired', { amount: niceCurrency(-event.amount) })
        case 'unexpire':
          return this.$t('events.Unexpired', { amount: niceCurrency(event.amount) })
      }
      return string
    },
    toggleDetails() {
      this.detailsOut = !this.detailsOut
    },
    showRedeemModalFocused() {
      this.$refs['redeemModal'].show()
    },
  },
  computed: {
    shownEvents() {
      let shown = []
      for (var i in this.certificate.events) {
        let e = this.certificate.events[i]
        if (['spend', 'refund'].includes(e.event)) {
          shown.push(e)
        } else if (['expire', 'unexpire'].includes(e.event) && e.amount != 0) {
          shown.push(e)
        }
      }
      return shown
    },
    shownMetas() {
      let shown = []
      for (var i in this.certificate.meta) {
        let e = this.certificate.meta[i]
        if (typeof e == 'string' && e == '') {
          continue
        }
        shown.push({ key: i, value: e })
      }
      return shown
    },
    displayExpiry() {
      return moment(this.certificate.expires).format('ll')
    },
    displayAmount() {
      if (this.certificate.balance == 0) {
        //Show original amount if balance is used up
        return niceCurrency(this.certificate.amount)
      }
      return niceCurrency(this.certificate.balance)
    },
    variantAmount() {
      if (this.certificate.balance == 0) {
        return 'danger'
      } else if (this.certificate.balance < this.certificate.amount) {
        return 'warning'
      }
      return 'success'
    },
  },
  filters: {
    formatDateTime(date) {
      return moment(date).format('lll')
    },
  },
}
</script>

<style lang="scss">
.certificate-code-wrap {
  cursor: pointer;
  border-radius: 0.25rem;
  border: 1px solid #888;
  overflow: hidden;
  background: #fff;
  &.details-out {
    border: 1px solid #333;
  }
  &:hover {
    border: 1px solid #333;
  }
}
.certificate__badges {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.certificate__badges .badge {
  border-radius: 0;
}
.certificate__code {
  font-size: 1em;
  line-height: 1.8em;
  padding-top: 0.2em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex-basis: 1;
  flex-grow: 1;
  min-width: 50%;
}
.certificate__amountanddate {
  display: flex;
  flex-direction: column;
  flex-basis: 1;
  flex-grow: 1;
  min-width: 50%;
}
.certificate__amountanddate span {
  display: block;
  flex-grow: 1;
}
.certificate__amountanddate .badge:first-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.certificate__amountanddate .badge:last-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.certificate__details {
  border-top: 1px solid #333;
}
.certificate__events {
  border-top: 1px solid #333;
  background: rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 600px) {
  .only-on-mobile {
    display: none;
  }
}
.certificate__eventsarrow {
  font-family: 'Times New Roman', Times, serif;
  margin-right: 1em;
}
</style>
