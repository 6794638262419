<template>
  <div>
    <!--pre>QR: {{ element }}</pre-->
    <mc-form-color-input :description="$t('designer.ChooseColorAndOpacity')" :label="$t('designer.BackColor')" v-model="element.backcolor" format="hex" opacity />
    <mc-form-color-input :description="$t('designer.ChooseColorAndOpacity')" :label="$t('designer.ForeColor')" v-model="element.forecolor" format="hex" opacity />
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    pixelsPerMm: Number,
  },
}
</script>
