import Vue from 'vue'
import App from '@/App.vue'

console.clear()

import './setup/index'
import { i18n } from '@/setup/i18n'

import router from '@/setup/Router'
import store from '@/setup/Store'
import perms from '@/setup/Permissions'
import merchantInfo from '@/setup/MerchantInfo'

import AuthenticationService from '@/services/AuthenticationService'
import TranslationService from '@/services/TranslationService'

import './registerServiceWorker'

i18n._missing = (locale, path, Vue) => {
  TranslationService.reportMissing(locale, path)
}

//Refresh user and permissions
if (store.state.LoginStore.token) {
  AuthenticationService.checkLoginToken().then(data => {
    store.commit('LoginStore/loginSuccess', data)
  })
}

let useLocale = localStorage.getItem('currentLanguage') || (navigator.language || navigator.userLanguage).substring(0, 2)
if (useLocale && (useLocale === 'en' || useLocale === 'da')) {
  setTimeout(() => {
    store.dispatch('LanguageStore/setLang', useLocale)
  })
}

Vue.config.productionTip = true

console.log(process.env.NODE_ENV)

export default new Vue({
  router,
  store,
  i18n,
  perms,
  merchantInfo,
  render: h => h(App),
}).$mount('#app')
