<template>
  <div id="app">
    <TheHeader v-if="LayoutStore.header" />
    <div class="mainContent">
      <PageTransition>
        <router-view></router-view>
      </PageTransition>
      <b-modal id="termsConsent" :title="$t('general.TermsAndConditions')" no-close-on-esc no-close-on-backdrop hide-header-close>
        <div v-if="needsTermsConsent.length > 0">
          <p>Dette er blot en venlig påmindelse om at bekræfte vores handelsbetingelser.</p>
          <p>Brug af systemet udgør en implicit godkendelse, men vi vil meget gerne have at du også giver en eksplicit godkendelse ved at klikke på knappen :)</p>
        </div>
        <div v-else-if="needsTermsConsentUpdate.length > 0">
          <p>Vi har opdateret vore handelsbetingelser. Der er følgende ændringer ift. de betingelser du allerede har godkendt:</p>
          <ol>
            <li>Vi har tilføjet et afsnit der beskriver at vi kan opsige samarbejdet med 30 dage til d. 1. - og at hvis vi gør det, skal vi give dig de oplysninger du har brug for, så du stadig kan indløse dine udestående gavekort, lave dit regnskab osv.</li>
            <li>Vi har tilføjet en præcisering af hvordan provisionen udregnes. Der er ikke sket nogen ændring i hvordan vi beregner den, vi har bare skrevet det tydeligere.</li>
          </ol>
        </div>
        <p>De fulde betingelser kan <a href="https://partner.danskegavekort.dk/wp-content/uploads/2023/12/Handelsbetingelser_v2.pdf" target="_blank">hentes her</a>.</p>
        <template slot="modal-footer">
          <McButton @click="postponeTerms" :text="$t('general.RemindMeAgain')" variant="secondary" />
          <McButton @click="consentTerms" :text="$t('general.Consent')" variant="primary" />
        </template>
      </b-modal>
    </div>
    <TheFooter class="footerwrap" />
    <div id="toast-outer-container"><McToast /></div>
  </div>
</template>

<style>
html,
body {
  height: 100%;
}
body {
  overflow-y: scroll;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}
.mainContent {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: auto;
}
#toast-outer-container {
  z-index: 1060;
}
</style>

<script>
import { mapState } from 'vuex'
import './assets/globalStyles.scss'
import ProfileService from '@/services/ProfileService'

export default {
  computed: {
    ...mapState({
      LayoutStore: state => state.LayoutStore,
      LoginStore: state => state.LoginStore,
    }),
    needsTermsConsent() {
      if (!this.LoginStore.user) {
        return []
      }
      let ret = []
      for (var i in this.LoginStore.merchantInfo) {
        var m = this.LoginStore.merchantInfo[i]
        if (m.userId == this.LoginStore.user.id && m.termsConsentVersion == 0) {
          ret.push(m.id)
        }
      }
      return ret
    },
    needsTermsConsentUpdate() {
      if (!this.LoginStore.user) {
        return []
      }
      let ret = []
      for (var i in this.LoginStore.merchantInfo) {
        var m = this.LoginStore.merchantInfo[i]
        if (m.userId == this.LoginStore.user.id && m.termsConsentVersion < 2) {
          ret.push(m.id)
        }
      }
      return ret
    },
  },
  watch: {
    needsTermsConsent(v) {
      this.maybeShowTermsModal()
    },
    needsTermsConsentUpdate(v) {
      this.maybeShowTermsModal()
    },
  },
  methods: {
    maybeShowTermsModal() {
      if (parseInt(localStorage.getItem('termConsentPostponed')) > (Date.now() / 1000) - 20 * 60 * 60) {
        return
      }
      if (this.needsTermsConsent.length > 0 || this.needsTermsConsentUpdate.length > 0) {
        this.$bvModal.show('termsConsent')
      }
    },
    consentTerms() {
      ProfileService.consentTerms().then(() => this.$bvModal.hide('termsConsent'))
    },
    postponeTerms() {
      localStorage.setItem('termConsentPostponed', Date.now() / 1000)
      this.$bvModal.hide('termsConsent')
    },
  },
}
</script>
