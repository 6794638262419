import { authorizedApiRequest, apiRequest } from '@/setup/ApiService'

export default {
  setLanguage: function (newLanguage) {
    return authorizedApiRequest(
      '/profile/set-language', //
      'POST',
      {
        language: newLanguage,
      },
    )
  },

  create: function (params) {
    return apiRequest(
      '/profile/create', //
      'POST',
      params,
    )
  },

  update: function (user) {
    return authorizedApiRequest(
      `/profile/update`, //
      'POST',
      user,
    )
  },

  consentTerms: function () {
    return authorizedApiRequest(
      `/profile/consent-terms`, //
      'POST',
    )
  },
}
